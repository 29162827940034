/* eslint-disable @typescript-eslint/no-explicit-any */
import { ICow, ICowHealthRecord } from "../types/cow";
import backendApi from "./backendApi";
import { showSnackbar } from "./snackbar.service";

const routePrefix = "cow";

export default {
  async fetchCowsByFarmIdPenIds(
    farmId: string,
    penIds: string[]
  ): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/all`, {
        farmId,
        penIds,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async addNewCow(farmId: string, cowInfo: ICow): Promise<any> {
    try {
      const cowPayload: any = { ...cowInfo };
      cowPayload.farm = farmId;
      cowPayload.barn = cowPayload.barn?._id;
      cowPayload.pen = cowPayload.pen?._id;
      cowPayload.breed = cowPayload.breed?._id;
      const result = await backendApi()?.post(`${routePrefix}/create`, {
        ...cowPayload,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "New farm successfully created"
      );
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },

  async updateCow(companyId: string, farmId: string, cow: ICow): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/update-cow`, {
        companyId,
        farmId,
        cow,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "Cow successfully updated"
      );
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },

  async deleteCows(companyId: string, cowIds: string[]): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/delete-cows`, {
        companyId,
        cowIds,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "Successfully deleted"
      );
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },

  async addHealthRecord(
    companyId: string,
    farmId: string,
    record: ICowHealthRecord
  ): Promise<any> {
    try {
      const result = await backendApi()?.post(
        `${routePrefix}/add-health-record`,
        {
          farmId,
          companyId,
          record,
        }
      );
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "Successfully Added"
      );
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
};
