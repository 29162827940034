import WidgetsIcon from "@mui/icons-material/Widgets";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
// import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import RuleIcon from "@mui/icons-material/Rule";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import GroupIcon from "@mui/icons-material/Group";
import LocationCityIcon from "@mui/icons-material/LocationCity";

const NavLinks = [
  {
    menu_title: "Dashboard",
    menu_icon: (props = {}) => <WidgetsIcon {...props} />,
    new_item: false,
    path: "/dashboard",
  },
  {
    menu_title: "Companies",
    menu_icon: (props = {}) => <LocationCityIcon {...props} />,
    new_item: false,
    path: "/companies",
    onlyAdmin: true,
  },
  {
    menu_title: "Manage Users",
    menu_icon: (props = {}) => <GroupIcon {...props} />,
    new_item: false,
    path: "/users",
    onlyAdmin: true,
  },
  {
    menu_title: "Manage Farms",
    menu_icon: (props = {}) => <AccountTreeIcon {...props} />,
    new_item: false,
    path: "/farms",
  },
  {
    menu_title: "Manage Diets",
    menu_icon: (props = {}) => <MenuBookIcon {...props} />,
    new_item: false,
    path: "/diets",
  },
  {
    menu_title: "Settings",
    menu_icon: (props = {}) => <RuleIcon {...props} />,
    new_item: false,
    path: "/settings",
  },
];

export default NavLinks;
