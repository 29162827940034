/* eslint-disable @typescript-eslint/no-explicit-any */
import { IFarm } from "../types/farm";
import backendApi from "./backendApi";
import { showSnackbar } from "./snackbar.service";

const routePrefix = "farm";

export default {
  async farchAllCompanyFarms(companyId: string): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/all`, { companyId });
      if (result?.status != 200) {
        showSnackbar("danger", "Error", result?.data.message || "API error response");
        return null;
      }
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar("danger", "Error", error?.response?.data?.message || "API error response");
        return null;
      }
    }
  },
  async addNewFarm(farmInfo: IFarm, companyId?: string): Promise<any> {
    if (!companyId) return;
    try {
      const result = await backendApi()?.post(`${routePrefix}/create`, { ...farmInfo, companyId });
      if (result?.status != 200) {
        showSnackbar("danger", "Error", result?.data.message || "API error response");
        return null;
      }
      showSnackbar("success", "Success", result?.data.message || "New farm successfully created");
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar("danger", "Error", error?.response?.data?.message || "API error response");
        return null;
      }
    }
  },
};
