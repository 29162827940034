/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import {
  DataGridPro,
  DataGridProProps,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridSortModel,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { CustomGridViewState } from "../../../types/gridViewState";
import datagridViewService from "../../../service/datagridView.service";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

export enum TableIds {
  DashboardTable = "DashboardTable",
  OtherTable = "OtherTable",
}

export interface CustomMuiDataGridProps extends DataGridProProps {
  tableId?: TableIds;
  fixedHeight?: number;
}

export default function CustomMuiDatagrid(props: CustomMuiDataGridProps) {
  const { tableId, columns, fixedHeight, ...otherProps } = props;
  const dataGridApiRef = useGridApiRef();
  const [savingGridView, setSavingGridView] = React.useState(false);
  const [datagridState, setDatagridState] =
    React.useState<CustomGridViewState | null>();
  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>();
  const [gridViewChanged, setGridViewChanged] = React.useState(false);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
  const [filterModel, setFilterModel] = React.useState<GridFilterModel>();

  const handleSaveGridView = React.useCallback(async () => {
    if (!tableId) return;
    setSavingGridView(true);
    const gridState: CustomGridViewState = dataGridApiRef.current.exportState();
    gridState.columnVisibilityModel = columnVisibilityModel;
    await datagridViewService.save(tableId, gridState);
    setSavingGridView(false);
    setGridViewChanged(false);
  }, [dataGridApiRef, columnVisibilityModel]);

  const orderedColsInGridView = React.useMemo(() => {
    return datagridState?.columns?.orderedFields?.reduce((acc, col, index) => {
      acc[col] = index;
      return acc;
    }, {} as { [key: string]: number });
  }, [datagridState]);

  const columnsRespectingGridViewOrder = React.useMemo(() => {
    // we need to reorder columns based on grid view because order is reset on every render
    // to avoid that columns provided via props would have to be memoized, but they are not
    // and it would be big effort to make them memoized
    // because they are inlined in components with a lot of custom code in renderCell
    // Important: we need to sort it with sort function which keeps same array reference
    // otherwise, if new array will be created, infinity re-rendering will be happening
    return orderedColsInGridView
      ? columns.sort(
          (a, b) =>
            orderedColsInGridView[a.field] - orderedColsInGridView[b.field]
        )
      : columns;
  }, [columns, orderedColsInGridView]);

  const fetchGridView = async () => {
    if (!tableId) return;
    const result = await datagridViewService.get(tableId);
    if (result?.columnVisibilityModel) {
      setColumnVisibilityModel(result.columnVisibilityModel);
    }
    setSortModel(result?.sorting?.sortModel || []);
    setFilterModel(result?.filter?.filterModel);
    setDatagridState(result);
  };

  React.useEffect(() => {
    if (datagridState) {
      setTimeout(() => {
        dataGridApiRef.current.restoreState(datagridState);
        dataGridApiRef.current.forceUpdate();
      }, 100);
    }
  }, [datagridState]);

  React.useEffect(() => {
    fetchGridView();
  }, []);

  return (
    <Box
      sx={{
        height: fixedHeight ? `${fixedHeight}px` : undefined,
        position: "relative",
        "&:hover .save-view-button": {
          display: gridViewChanged ? "flex" : "none",
        },
        marginTop: "15px",
      }}
    >
      <DataGridPro
        {...otherProps}
        columns={columnsRespectingGridViewOrder}
        apiRef={dataGridApiRef}
        onColumnOrderChange={(params, event, details) => {
          setGridViewChanged(true);
          otherProps.onColumnOrderChange &&
            otherProps.onColumnOrderChange(params, event, details);
        }}
        onSortModelChange={(model, details) => {
          setSortModel(model);
          otherProps.onSortModelChange &&
            otherProps.onSortModelChange(model, details);
          if (JSON.stringify(model || []) != JSON.stringify(sortModel || [])) {
            setGridViewChanged(true);
          }
        }}
        onFilterModelChange={(model, details) => {
          otherProps.onFilterModelChange &&
            otherProps.onFilterModelChange(model, details);
          if (
            JSON.stringify(model || {}) != JSON.stringify(filterModel || {})
          ) {
            setGridViewChanged(true);
          }
        }}
        columnVisibilityModel={columnVisibilityModel || {}}
        onColumnVisibilityModelChange={(model) => {
          setColumnVisibilityModel(model);
          if (
            JSON.stringify(model || {}) !=
            JSON.stringify(columnVisibilityModel || {})
          ) {
            setGridViewChanged(true);
          }
        }}
      />
      {tableId ? (
        <Box
          className="save-view-button"
          sx={{
            position: "absolute",
            top: "0px",
            width: "100%",
            borderTop: "solid 1px #ddd",
            height: "0px",
            display: "none",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => handleSaveGridView()}
            disabled={savingGridView}
            variant="outlined"
            size="small"
            disableElevation
            sx={{
              height: "17px",
              borderRadius: "100px",
              backgroundColor: "#fff",
              transform: "scale(1)",
              transition: "0.1s",
              fontWeight: "400",
              fontSize: "12px",
              opacity: 0.4,
              "&:hover": {
                backgroundColor: "primary.main",
                transform: "scale(1.1)",
                color: "white",
                opacity: 1,
              },
              "&:active": { transform: "scale(0.95)", opacity: 1 },
            }}
            color="inherit"
          >
            {savingGridView ? "Saving..." : "Save View"}
          </Button>
        </Box>
      ) : null}
    </Box>
  );
}
