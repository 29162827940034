import { IBarn } from "./barn";
import { IBreed } from "./breed";
import { IDiet } from "./diet";
import { IFarm } from "./farm";
import { IPen } from "./pen";

export interface ICowHealthRecord {
  _id?: string;
  cow: ICow;
  startDate: string;
  endDate?: string;
  healthIssue: string;
  description?: string;
}

export enum GeneticProductionPotential {
  Low = "Low",
  Medium = "Medium",
  High = "High",
}

export enum LactationState {
  Early = "Early",
  Mid = "Mid",
  Late = "Late",
  Dry = "Dry",
}

export interface ICow {
  _id?: string;
  farm?: IFarm;
  barn?: IBarn;
  pen?: IPen;
  name?: string;
  breed?: IBreed;
  productionPotential?: GeneticProductionPotential;
  numberOfLactations?: number;
  milkProduction?: number;
  lactationState?: LactationState;
  healthIssues?: ICowHealthRecord[];
}

export interface ICowFeed {
  timestamp: Date;
  diet: string;
  fedAmount: number;
  refusedAmount: number;
  durationSeconds: number;
}

export interface ICowIntakeRecordModel {
  _id: string;
  cow: ICow;
  company: string;
  date: string;
  diet: IDiet;
  fedAmount?: number;
  refusedAmount?: number;
  durationSeconds: number;
  feeds: ICowFeed[];
  dmPercentage?: number;
}
