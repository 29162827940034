/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import React from "react";
import ReactApexChart from "react-apexcharts";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {
  IPenIntakeRecord,
  IPenLooseFeedRecord,
  IPushUp,
} from "../../types/dailyIntake";
import { IDiet } from "../../types/diet";
import DietsListPopover from "./Common/DietsListPopover";
import { cloneDeep, uniqBy } from "lodash";
import { fTime } from "../../helpers/formatTime";
import { useAppContext } from "../../contexts/useStorageContext";

function getAverageArray(arr1: number[], arr2: number[]) {
  return arr1.map((num, index) => (num + arr2[index] || 0) / 2);
}

export enum ChartTargetType {
  Barn = "barn",
  Pen = "pen",
  Cow = "cow",
}

export enum ChartNames {
  AsFed = "As Fed",
  Temperature = "Temperature",
  // DryMatter = "Dry Matter",
  // WaterPercentage = "Water Percentage",
  // Ingredients = "Ingredients",
}

export enum AnnotationNames {
  RecipeAnnotation = "Mark Recipe",
  AsFed = "As Fed",
  DryMatter = "Dry Matter",
}

const PushUpAnnotationColor = "#775DD0";

const PenIntakesChart = ({
  intakeRecords,
  looseFeeds,
  isDM,
}: {
  intakeRecords: IPenIntakeRecord[];
  looseFeeds: IPenLooseFeedRecord[];
  isDM?: boolean;
}) => {
  const { state } = useAppContext();
  const { currentCompany } = state;
  const maxAmount = Math.max(
    ...[
      ...intakeRecords
        .filter((r) => r.fedAmount)
        .map((r) => r.fedAmount as number),
      ...looseFeeds
        .filter((f) => f.totalAmount)
        .map((f) => f.totalAmount as number),
      ...(intakeRecords?.[0]?.pen?.expectedAsFedAmount
        ? [intakeRecords?.[0]?.pen?.expectedAsFedAmount]
        : []),
    ]
  );
  const minAmount = Math.min(
    ...[
      ...intakeRecords
        .filter((r) => (r.fedAmount || 0) - (r.refusedAmount || 0))
        .map((r) => ((r.fedAmount || 0) - (r.refusedAmount || 0)) as number),
      ...looseFeeds
        .filter((f) => f.totalAmount)
        .map((f) => f.totalAmount as number),
      ...(intakeRecords?.[0]?.pen?.expectedAsFedAmount
        ? [intakeRecords?.[0]?.pen?.expectedAsFedAmount]
        : []),
    ]
  );
  const [chartRefreshKey, setChartRefreshKey] = React.useState(0);
  const [visibleChartNames, setVisibleChartNames] = React.useState<
    ChartNames[]
  >([ChartNames.AsFed, ChartNames.Temperature]);

  const dietChangePoints: { date?: string; dietName?: string; diet: IDiet }[] =
    [];
  const pushUpTimes = ([] as Date[]).concat(
    ...intakeRecords
      .filter((r) => r.pushUps?.length)
      .map((r) =>
        (r.pushUps as IPushUp[]).map((p: IPushUp) => p.timestamp as Date)
      )
  );

  const renderPushUpAnnotation = (ptime: Date) => ({
    x: new Date(ptime).getTime(),
    borderColor: "#775dd080",
    fillColor: PushUpAnnotationColor,
    opacity: 0.2,
    label: {
      offsetY: -57,
      offsetX: 7,
      orientation: "vertical",
      borderColor: "#fff",
      style: {
        color: "#fff",
        background: "#775DD0",
        borderRadius: "100px",
        width: "8px",
        height: "8px",
        fontSize: "10px",
        lineHeight: "0.7",
      },
      text: `${fTime(new Date(ptime))}`,
    },
  });

  let currentDietId: any = "";
  intakeRecords.map((r, i) => {
    if (r.diet?._id && r.diet?._id != currentDietId) {
      dietChangePoints.push({
        date: r.date,
        diet: r.diet,
        dietName: r.diet?.name,
      });
      currentDietId = r.diet?._id;
    } else {
      const recordDiet = r.diet;
      const dietChangeLog = recordDiet?.changeLogs?.find(
        (log) => log.date == r.date
      );
      if (recordDiet && dietChangeLog) {
        dietChangePoints.push({
          date: r.date,
          diet: recordDiet,
          dietName: "...",
        });
      }
    }
  });
  const [fedChartOptions, setFedChartOptions] =
    React.useState<ApexCharts.ApexOptions>({
      tooltip: {
        enabled: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const targetXaxis = w.config?.xaxis?.categories?.[dataPointIndex];
          const matchedRecord = intakeRecords.find(
            (r) => targetXaxis && r.date == targetXaxis
          );
          const matchedLooseFeed = looseFeeds.find(
            (f) => targetXaxis && f.startDate == targetXaxis
          );

          const note = matchedRecord?.note || "";
          const matchedDietLog = matchedRecord?.diet?.changeLogs?.find(
            (log) =>
              log.date && (log.date == targetXaxis || log.date < targetXaxis)
          );
          const exactMatchedDietLog = matchedRecord?.diet?.changeLogs?.find(
            (log) => log.date && log.date == targetXaxis
          );
          return (
            '<div class="apex-custom-tooltip">' +
            '<div class="title">' +
            `${w.config?.xaxis?.categories?.[dataPointIndex]}` +
            "</div>" +
            '<div class="content">' +
            series
              .map((eachSeries: any[], i: number) => {
                return (
                  "<div class='series-item'>" +
                  "<div class='color-mark' style='background:" +
                  `${w.config?.series?.[i]?.color}` +
                  "' ></div>" +
                  "<div class='series-name' >" +
                  `${w.config?.series?.[i]?.name}: ` +
                  "</div>" +
                  "<div class='series-value'>" +
                  series[i][dataPointIndex] +
                  "</div></div>"
                );
              })
              .join("") +
            (matchedLooseFeed
              ? "<div class='series-item'>" +
                "<div class='color-mark' style='border: solid 3px #19daa3; width: 5px; height: 5px;' ></div>" +
                "<div class='series-name' >" +
                `Free Choice: ` +
                "</div>" +
                "<div class='series-value'>" +
                matchedLooseFeed.totalAmount +
                "</div></div>"
              : "") +
            (note ? `<div class='note'>${note}</div>` : "") +
            (matchedDietLog
              ? `<div class='diet'>
              <div class='diet-title'>Diet ${
                exactMatchedDietLog ? "Changed" : ""
              }</div>
              <div class='diet-description'>
              ${matchedDietLog.description || "Diet"}
              </div>
              </div>`
              : "") +
            "</div>" +
            "</div>"
          );
        },
      },
      title: {
        text: `${isDM ? "DM" : "As Fed"} (${
          currentCompany?.weightUnit || "lbs"
        })`,
        align: "left",
      },
      chart: {
        id: "fed",
        group: "1",
        type: "line",
      },
      series: [
        {
          name: "Fed",
          data: intakeRecords.map(
            (r) => Number(r.fedAmount?.toFixed(1)) || null
          ),
          color: "#05dd9f",
        },
        {
          name: "Actual Intake (fed - refused)",
          data: intakeRecords.map(
            (r) =>
              Number(
                ((r.fedAmount || 0) - (r.refusedAmount || 0)).toFixed(1)
              ) || null
          ),
          color: "#dd2505",
        },
      ],
      xaxis: {
        categories: intakeRecords.map((r) => r.date || ""),
        type: "datetime",
      },
      yaxis: {
        min: minAmount,
        max: maxAmount,
      },
      stroke: {
        width: 2, // Adjust the width of the line here
        curve: "smooth",
      },
      dataLabels: {
        enabled: true,
      },
      legend: {
        position: "bottom",
      },
      annotations: {
        xaxis: [
          ...dietChangePoints
            .filter((d) => d.date)
            .map((dietChange) => ({
              x: new Date(dietChange.date as string).getTime(),
              borderColor: "#000",
              fillColor: "#FEB019",
              opacity: 0.2,
              label: {
                offsetY: 0,
                orientation: "horizontal",
                borderColor: "#333",
                style: {
                  fontSize: "12px",
                  color: "#333",
                  background: "#FEB019",
                },
                text: dietChange.dietName,
              },
            })),
          ...(pushUpTimes || []).map((ptime) => renderPushUpAnnotation(ptime)),
        ],
        yaxis: intakeRecords?.[0]?.pen?.expectedAsFedAmount
          ? [
              {
                y: intakeRecords?.[0]?.pen?.expectedAsFedAmount,
                borderColor: "#000",
                strokeDashArray: 5,
                label: {
                  borderColor: "#188fe7",
                  style: {
                    color: "#fff",
                    background: "#188fe7",
                  },
                  text: "Expected",
                },
              },
            ]
          : [],
        points: [
          ...intakeRecords
            .filter((r) => r.date && r.note)
            .map((r) => {
              return {
                x: new Date(r.date as string).getTime(),
                marker: {
                  size: 7,
                  strokeColor: "#de633a",
                },
              };
            }),
          ...looseFeeds
            .filter((f) => f.startDate && f.totalAmount)
            .map((f) => ({
              x: new Date(f.startDate as string).getTime(),
              y: f.totalAmount,
              marker: {
                size: 4,
                radius: 0,
                fillColor: "#fff",
                strokeColor: "#19daa3",
                strokeWidth: 3,
              },
            })),
        ],
      },
    });

  const [tempChartOptions, setTempChartOptions] =
    React.useState<ApexCharts.ApexOptions>({
      title: {
        text: "Temperature (°C)",
        align: "left",
      },
      chart: {
        id: "temp",
        group: "1",
        type: "line",
      },
      series: [
        {
          name: "Day Inside",
          data: intakeRecords.map((r) =>
            r.dayInTemp == undefined ? null : r.dayInTemp
          ),
          color: "#fb5f00",
        },
        {
          name: "Day Outside",
          data: intakeRecords.map((r) =>
            r.dayOutTemp == undefined ? null : r.dayOutTemp
          ),
          color: "#f7b187",
        },
        {
          name: "Night Inside",
          data: intakeRecords.map((r) =>
            r.nightInTemp == undefined ? null : r.nightInTemp
          ),
          color: "#009fed",
        },
        {
          name: "Night Outside",
          data: intakeRecords.map((r) =>
            r.nightOutTemp == undefined ? null : r.nightOutTemp
          ),
          color: "#7bc8ed",
        },
      ],
      xaxis: {
        categories: intakeRecords.map((r) => r.date || ""),
        type: "datetime",
      },
      stroke: {
        width: 2, // Adjust the width of the line here
        curve: "smooth",
      },
      dataLabels: {
        enabled: true,
      },
      legend: {
        position: "top",
      },
    });

  const handleClickViewItem = (val: ChartNames) => {
    if (visibleChartNames.includes(val)) {
      setVisibleChartNames(visibleChartNames.filter((n) => n != val));
    } else {
      setVisibleChartNames([...visibleChartNames, val]);
    }
  };

  return (
    <Box>
      <Box
        mb={2}
        sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
      >
        <ButtonGroup size="small" aria-label="button group" sx={{ mr: 2 }}>
          {Object.values(ChartNames).map((val) => (
            <Button
              key={`chart-item-${val}`}
              onClick={() => handleClickViewItem(val)}
              color={visibleChartNames.includes(val) ? "secondary" : "inherit"}
              sx={{
                color: visibleChartNames.includes(val)
                  ? "secondary.main"
                  : "#bbb",
                background: visibleChartNames.includes(val)
                  ? "#ffe7db"
                  : "#fff",
              }}
            >
              {val}
            </Button>
          ))}
        </ButtonGroup>
        {dietChangePoints?.length ? (
          <DietsListPopover
            triggerElement={
              <Button
                endIcon={<KeyboardArrowDownIcon />}
                size="small"
                variant="outlined"
                sx={{ mr: 2 }}
              >
                {`${
                  uniqBy(
                    dietChangePoints.filter((d) => d.dietName != "..."),
                    (val) => val.dietName
                  ).length
                } Diets applied`}
              </Button>
            }
            diets={uniqBy(
              dietChangePoints.filter((d) => d.dietName != "..."),
              (val) => val.dietName
            ).map((d) => d.diet)}
          />
        ) : null}
        <FormControlLabel
          control={
            <Switch
              checked={fedChartOptions?.dataLabels?.enabled}
              onChange={(e, checked) => {
                const tempFedChartOptions = cloneDeep(fedChartOptions);
                tempFedChartOptions.dataLabels = {
                  ...(tempFedChartOptions.dataLabels || {}),
                  enabled: checked,
                };
                const tempTempChartOptions = cloneDeep(tempChartOptions);
                tempTempChartOptions.dataLabels = {
                  ...(tempTempChartOptions.dataLabels || {}),
                  enabled: checked,
                };
                setFedChartOptions(tempFedChartOptions);
                setTempChartOptions(tempTempChartOptions);
                setChartRefreshKey(chartRefreshKey + 1);
              }}
            />
          }
          label="Data Labels"
        />
        <FormControlLabel
          control={
            <Switch
              checked={fedChartOptions?.annotations?.xaxis?.some(
                (anno) => anno.fillColor == PushUpAnnotationColor
              )}
              onChange={(e, checked) => {
                const tempOptions = cloneDeep(fedChartOptions);
                tempOptions.annotations = {
                  ...(tempOptions.annotations || {}),
                  xaxis: checked
                    ? [
                        ...(tempOptions.annotations?.xaxis || []),
                        ...pushUpTimes.map((ptime) =>
                          renderPushUpAnnotation(ptime)
                        ),
                      ]
                    : (tempOptions.annotations?.xaxis || []).filter(
                        (anno) => anno.fillColor != PushUpAnnotationColor
                      ),
                };
                setFedChartOptions(tempOptions);
                setChartRefreshKey(chartRefreshKey + 1);
              }}
            />
          }
          label="Pushup Times"
        />
        {/* <Box>
          <RadioGroup row value={isCompare} onChange={(e, val) => changeIsCompare && changeIsCompare(!!val)}>
            <FormControlLabel value="true" control={<Radio size="small" />} label="Compare" />
            <FormControlLabel value="" control={<Radio size="small" />} label="Average" />
          </RadioGroup>
        </Box> */}
        <Box sx={{ flex: 1 }} />
      </Box>
      {visibleChartNames.includes(ChartNames.AsFed) && (
        <ReactApexChart
          key={`FedChart-${chartRefreshKey}`}
          options={fedChartOptions}
          series={fedChartOptions.series}
          type="line"
          height={250}
        />
      )}
      {visibleChartNames.includes(ChartNames.Temperature) && (
        <ReactApexChart
          key={`Temp Chart-${chartRefreshKey}`}
          options={tempChartOptions}
          series={tempChartOptions.series}
          type="line"
          height={250}
        />
      )}
    </Box>
  );
};

export default PenIntakesChart;
