import * as React from "react";
import GeneralPopoverWrapper from "../../Common/GeneralPopoverWrapper";
import Box from "@mui/material/Box";
import TuneIcon from "@mui/icons-material/Tune";
import IconButton from "@mui/material/IconButton";
import { useAppContext } from "../../../../contexts/useStorageContext";
import { Button, InputAdornment, TextField } from "@mui/material";
import { ICompanySettings } from "../../../../types/companySettings";
import ProgressButton from "../../Common/ProgressButton";
import companyService from "../../../../service/company.service";
import { actionTypes } from "../../../../types/state";

export default function DashboardTableSettingsPopover() {
  const { state, dispatch } = useAppContext();
  const { myCompanySettings } = state;
  const [editingSettings, setEditingSettings] = React.useState<
    ICompanySettings | undefined
  >(myCompanySettings);
  const [processing, setProcessing] = React.useState(false);

  const handleUpdateSettings = async () => {
    if (!editingSettings) return;
    setProcessing(true);
    await companyService.updateMyCompanySettings(editingSettings);
    dispatch!({
      type: actionTypes.setMyCompanySettings,
      value: editingSettings,
    });
    setProcessing(false);
  };

  React.useEffect(() => {
    setEditingSettings(myCompanySettings);
  }, [myCompanySettings]);
  return (
    <GeneralPopoverWrapper
      triggerElement={
        <IconButton size="small">
          <TuneIcon />
        </IconButton>
      }
      popoverContent={
        <Box p={2} sx={{ minWidth: "250px" }}>
          <Box
            sx={{
              color: "info.main",
              fontSize: "14px",
              mb: 1,
              fontWeight: "500",
            }}
          >
            Intake Change Alert Threshold
          </Box>
          <Box>
            <TextField
              fullWidth
              value={editingSettings?.intakeAlertThreshold || ""}
              onChange={(e) =>
                setEditingSettings({
                  ...(editingSettings || {}),
                  intakeAlertThreshold: Number(e.target.value) || undefined,
                })
              }
              size="small"
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{"%"}</InputAdornment>
                ),
              }}
            />
          </Box>
          {JSON.stringify(myCompanySettings || {}) !=
          JSON.stringify(editingSettings || {}) ? (
            <Box
              sx={{
                mt: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                onClick={() => {
                  setEditingSettings(myCompanySettings);
                }}
                variant="outlined"
                size="small"
                color="inherit"
                sx={{ mr: 1 }}
              >
                Reset
              </Button>
              <ProgressButton
                onClick={() => handleUpdateSettings()}
                inProgress={processing}
                size="small"
                disabled={!editingSettings?.intakeAlertThreshold}
                title="Save Changes"
              />
            </Box>
          ) : null}
        </Box>
      }
    />
  );
}
