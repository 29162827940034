/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from "@mui/material/Box";
import React from "react";
import Dialog from "@mui/material/Dialog";
import ReportsPanel from "./ReportsPanel";
import { IFarm } from "../../../types/farm";
import { IBarn } from "../../../types/barn";
import { IPen } from "../../../types/pen";
import { ICow } from "../../../types/cow";

export default function ReportsDialog({
  farm,
  barn,
  pen,
  open,
  onClose,
  cow,
}: {
  farm?: IFarm;
  barn?: IBarn;
  pen?: IPen;
  cow?: ICow;
  open: boolean;
  onClose: () => void;
}): React.ReactElement {
  return (
    <>
      <Dialog open={open} maxWidth="xs">
        <Box sx={{ background: "white", width: "500px", maxWidth: "100%" }}>
          <ReportsPanel
            cow={cow}
            farm={farm}
            barn={barn}
            pen={pen}
            onClose={onClose}
          />
        </Box>
      </Dialog>
    </>
  );
}
