import { Grid } from "@mui/material";
import PensIntakeTrendsOverviewTable from "../../components/dashboard/PensIntakeTrendsOverviewTable";

export default function Index() {
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <PensIntakeTrendsOverviewTable />
        </Grid>
      </Grid>
    </>
  );
}
