/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { useAppContext } from "../../../../contexts/useStorageContext";
import { IPenIntakeRecord } from "../../../../types/dailyIntake";
import { DataGridPro } from "@mui/x-data-grid-pro";

export enum PenIntakeTableHeaderTitles {
  Date = "Date",
  Diet = "Diet",
  FedAmount = "Fed",
  RefusedAmount = "Refused",
  CowAverageFedAmount = "Cow Avg Fed",
  CowAverageRefusedAmount = "Cow Avg Refused",
  DM = "DM (%)",
}

export default function PenIntakeTable({
  loading,
  rows,
}: {
  loading?: boolean;
  rows: IPenIntakeRecord[];
}): React.ReactElement {
  const { state } = useAppContext();

  const columns = [
    {
      field: "date",
      headerName: PenIntakeTableHeaderTitles.Date,
      width: 150,
      editable: false,
    },
    {
      field: "diet",
      headerName: PenIntakeTableHeaderTitles.Diet,
      width: 150,
      editable: false,
      valueGetter: (params: any) => {
        return params.row.diet?.name;
      },
    },
    {
      field: "fedAmount",
      headerName: PenIntakeTableHeaderTitles.FedAmount,
      width: 130,
      editable: false,
    },
    {
      field: "refusedAmount",
      headerName: PenIntakeTableHeaderTitles.RefusedAmount,
      width: 130,
      editable: false,
    },
    {
      field: "cowAverageFedAmount",
      headerName: PenIntakeTableHeaderTitles.CowAverageFedAmount,
      width: 130,
      editable: false,
      valueGetter: (params: any) =>
        params.value ? Number(Number(params.value)?.toFixed(1)) : "",
    },
    {
      field: "cowAverageRefusedAmount",
      headerName: PenIntakeTableHeaderTitles.CowAverageRefusedAmount,
      width: 140,
      editable: false,
      valueGetter: (params: any) =>
        params.value ? Number(Number(params.value)?.toFixed(1)) : "",
    },
    {
      field: "dmPercentage",
      headerName: PenIntakeTableHeaderTitles.DM,
      width: 90,
      editable: false,
      valueGetter: (params: any) =>
        params.value ? Number(Number(params.value)?.toFixed(1)) : "",
    },
  ];

  return (
    <>
      <DataGridPro
        loading={loading}
        getRowId={(row) => row._id || ""}
        autoHeight
        rows={rows}
        columns={columns}
        disableSelectionOnClick
        rowHeight={40}
      />
    </>
  );
}
