import { IUserShallow } from "./auth";

export enum WeightUnit {
  lbs = "lbs",
  kg = "kg",
}

export interface ICompany {
  _id?: string;
  name?: string;
  address?: string;
  logoUrl?: string;
  email?: string;
  phone?: string;
  weightUnit?: string;
}

export interface ICompanyWithUsers extends ICompany {
  users?: IUserShallow[];
}
