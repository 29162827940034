/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from "@mui/material/Box";
import React from "react";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { IBreed } from "../../../types/breed";
import breedService from "../../../service/breed.service";
import { useAppContext } from "../../../contexts/useStorageContext";
import ProgressButton from "../Common/ProgressButton";

export default function BreedFormDialog({
  initialName,
  breed,
  open,
  onClose,
  onUpdated,
  onNewCreated,
}: {
  initialName?: string;
  breed?: IBreed;
  open: boolean;
  onClose: () => void;
  onUpdated?: (updateInfo: IBreed) => void;
  onNewCreated?: (created: IBreed) => void;
}): React.ReactElement {
  const { state } = useAppContext();
  const { currentCompany } = state;
  const [editingBreed, setEditingBreed] = React.useState<IBreed>(breed || { name: initialName });
  const [processing, setProcessing] = React.useState(false);

  const handleCreate = async () => {
    if (!editingBreed.name) return;
    setProcessing(true);
    const result = await breedService.addNewBreed(editingBreed.name, editingBreed.description, currentCompany?._id);
    if (result?.data) {
      onNewCreated && onNewCreated(result.data);
    }
    setProcessing(false);
  };

  return (
    <>
      <Dialog open={open} maxWidth="xs">
        <Box sx={{ background: "white", width: "1000px", maxWidth: "100%" }}>
          <Box p={2} pb={0} sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">{breed ? "Breed Details" : "New Breed"}</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box p={2}>
            <Box>
              <Stack spacing={2}>
                <TextField
                  value={editingBreed.name || ""}
                  onChange={(e) => setEditingBreed({ ...editingBreed, name: e.target.value })}
                  placeholder="Breed Name"
                  fullWidth
                  size="small"
                />
                <TextField
                  value={editingBreed.description}
                  onChange={(e) => setEditingBreed({ ...editingBreed, description: e.target.value || undefined })}
                  placeholder="Description"
                  multiline
                  maxRows={4}
                />
              </Stack>
              <Box mt={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
                {breed ? (
                  <ProgressButton
                    // onClick={() => !processing && handleUpdate()}
                    title="Save Changes"
                    inProgress={processing}
                    size="small"
                  />
                ) : (
                  <ProgressButton
                    disabled={!editingBreed.name}
                    onClick={() => !processing && handleCreate()}
                    title="Create Breed"
                    inProgress={processing}
                    size="small"
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
