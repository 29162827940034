/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { styled } from "@mui/material/styles";
import AddIconButton from "../Common/AddIconButton";
import {
  Chip,
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
} from "@mui/material";
import { fDate, getIntakeRecordDateString } from "../../../helpers/formatTime";
import { useAppContext } from "../../../contexts/useStorageContext";
import { IFarm } from "../../../types/farm";
import { IBarn } from "../../../types/barn";
import { IPen } from "../../../types/pen";
import { IReportPopulated } from "../../../types/report";
import ReportFormDialog from "./ReportFormDialog";
import reportService from "../../../service/report.service";
import GeneralPopoverWrapper from "../Common/GeneralPopoverWrapper";
import ConfirmDialog from "../Common/ConfirmDialog";
import { getFileNameFromAssetUrl } from "../../../helpers/utils";
import { ICow } from "../../../types/cow";

const InfoTag = styled(Box)(() => ({
  background: "#e9e9e9",
  padding: "2px 5px",
  borderRadius: "5px",
  marginRight: "5px",
  display: "flex",
  alignItems: "center",
  fontSize: "13px",
}));

export default function ReportsPanel({
  farm,
  barn,
  pen,
  onClose,
  cow,
}: {
  farm?: IFarm;
  barn?: IBarn;
  pen?: IPen;
  onClose?: () => void;
  cow?: ICow;
}) {
  const { state } = useAppContext();
  const { currentCompany } = state;
  const [targetReport, setTargetReport] = React.useState<IReportPopulated>();
  const [reports, setReports] = React.useState<IReportPopulated[]>([]);
  const [openReportFormDialog, setOpenReportFormDialog] = React.useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] =
    React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const fetchReports = async () => {
    if (!currentCompany?._id) return;
    setLoading(true);
    const result = await reportService.getReports({
      company: currentCompany?._id,
      farm: farm?._id,
      barn: barn?._id,
      pen: pen?._id,
      cow: cow?._id,
    });
    setReports(result);
    setLoading(false);
  };

  const handleDeleteReport = async () => {
    if (!targetReport?._id || !currentCompany?._id) return;
    setOpenDeleteConfirmDialog(false);
    setLoading(true);
    await reportService.deleteReport(currentCompany._id, targetReport._id);
    await fetchReports();
    setLoading(false);
  };

  React.useEffect(() => {
    fetchReports();
  }, []);

  return (
    <Box p={2} sx={{ minHeight: "340px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          borderBottom: "solid 1px #ddd",
          pb: "4px",
        }}
      >
        <AddIconButton
          onClick={() => {
            setTargetReport(undefined);
            setOpenReportFormDialog(true);
          }}
        />
        <Box>
          Reports
          <Chip
            label={reports?.length || 0}
            size="small"
            sx={{ height: "20px", ml: 1 }}
          />
        </Box>
        <div style={{ flex: 1 }} />
        {onClose ? (
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </Box>
      <Box>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "300px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : reports?.length ? (
          <Box>
            {reports.map((report, i) => (
              <Box key={i} py={1} sx={{ borderBottom: "solid 1px #ddd" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ flex: 1 }}>
                    <Box
                      sx={{
                        fontWeight: "500",
                        color: "#007ad0",
                        marginBottom: "3px",
                      }}
                    >
                      {report.reportTitle || ""}
                    </Box>
                    <Box
                      sx={{
                        fontSize: "14px",
                        opacity: "0.6",
                        marginBottom: "6px",
                      }}
                    >
                      {report.description || ""}
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {report.type ? (
                        <InfoTag>
                          <div className="exact-date">{report.type}</div>
                        </InfoTag>
                      ) : null}
                      {report.date ? (
                        <InfoTag>
                          <AccessTimeIcon
                            sx={{ fontSize: "14px", mr: "2px" }}
                          />
                          <div className="exact-date">{fDate(report.date)}</div>
                        </InfoTag>
                      ) : null}
                      {report.docUrls?.length ? (
                        <GeneralPopoverWrapper
                          closeOnClickContent
                          triggerElement={
                            <InfoTag sx={{ color: "warning.main" }}>
                              <UploadFileIcon
                                sx={{ fontSize: "14px", mr: "2px" }}
                              />
                              <div className="exact-date">
                                {report.docUrls.length}
                              </div>
                            </InfoTag>
                          }
                          popoverContent={
                            <Box sx={{ minWidth: "250px" }}>
                              {report.docUrls?.map((docUrl) => (
                                <Box
                                  sx={{
                                    px: 1,
                                    color: "primary.main",
                                    display: "flex",
                                    alignItems: "center",
                                    borderBottom: "solid 1px #eee",
                                    py: "4px",
                                    "&:hover": {
                                      textDecoration: "underline",
                                    },
                                  }}
                                >
                                  <UploadFileIcon sx={{ mr: 1 }} />
                                  <a href={docUrl} target="_blank">
                                    {getFileNameFromAssetUrl(docUrl)}
                                  </a>
                                </Box>
                              ))}
                            </Box>
                          }
                        />
                      ) : null}
                    </Box>
                  </Box>
                  <GeneralPopoverWrapper
                    closeOnClickContent
                    triggerElement={
                      <IconButton sx={{ border: "solid 1px #ddd" }}>
                        <MoreVertIcon />
                      </IconButton>
                    }
                    popoverContent={
                      <MenuList>
                        <MenuItem
                          onClick={() => {
                            setTargetReport(report);
                            setOpenReportFormDialog(true);
                          }}
                        >
                          <ListItemIcon>
                            <EditIcon />
                          </ListItemIcon>
                          <ListItemText>Edit Report</ListItemText>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setTargetReport(report);
                            setOpenDeleteConfirmDialog(true);
                          }}
                          sx={{ color: "error.main" }}
                        >
                          <ListItemIcon>
                            <DeleteIcon />
                          </ListItemIcon>
                          <ListItemText>Delete Report</ListItemText>
                        </MenuItem>
                      </MenuList>
                    }
                  />
                </Box>
              </Box>
            ))}
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "300px",
            }}
          >
            No reports...
          </Box>
        )}
      </Box>
      {openReportFormDialog && (
        <ReportFormDialog
          farm={farm}
          barn={barn}
          pen={pen}
          cow={cow}
          open
          report={targetReport}
          onClose={() => setOpenReportFormDialog(false)}
          onCreated={(created) => {
            setReports([created, ...reports]);
            setOpenReportFormDialog(false);
          }}
          onUpdated={(updated) => {
            const tempReports = [...reports];
            const targetIndex = tempReports.findIndex(
              (r) => r._id == updated?._id
            );
            if (targetIndex < 0) return;
            tempReports.splice(targetIndex, 1, updated);
            setReports(tempReports);
            setOpenReportFormDialog(false);
          }}
        />
      )}
      {openDeleteConfirmDialog && targetReport ? (
        <ConfirmDialog
          title={<>Delete Report</>}
          content={<>Are you sure you want to delete this report?</>}
          onConfirmText={<>Delete</>}
          onConfirm={() => handleDeleteReport()}
          onCancel={() => setOpenDeleteConfirmDialog(false)}
        />
      ) : null}
    </Box>
  );
}
