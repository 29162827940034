import { InputAdornment, TextField } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";

interface SimpleSearchInputProps {
  height?: number;
  size?: "small" | "medium";
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  placeholder?: string;
  variant?: "standard" | "filled" | "outlined" | undefined;
  focused?: boolean | undefined;
  onClear?: () => void;
}
export default function SimpleSearchInput({
  height,
  size,
  value,
  onChange,
  placeholder,
  variant,
  focused,
  onClear,
}: SimpleSearchInputProps) {
  return (
    <>
      <TextField
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        variant={variant}
        focused={focused}
        size={size}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {value ? (
                <ClearIcon onClick={onClear} color={value ? "primary" : "inherit"} sx={{ cursor: "pointer" }} />
              ) : (
                <SearchIcon color={value ? "primary" : "inherit"} />
              )}
            </InputAdornment>
          ),
        }}
        sx={{
          height: height ? `${height}px` : "unset",
          "& .MuiOutlinedInput-root": { height: height ? `${height}px` : "unset" },
        }}
      />
    </>
  );
}
