import { format, getTime, formatDistanceToNow } from "date-fns";

// ----------------------------------------------------------------------
export function changeOnlyDate(targetDate: Date, changingDate: Date) {
  const changedDate = new Date(targetDate); // Your existing Date object
  changedDate.setFullYear(
    changingDate.getFullYear(),
    changingDate.getMonth(),
    changingDate.getDate()
  );
  return changedDate;
}
export function excelTimeFractionToDate(timeFraction: number) {
  return new Date(timeFraction * 24 * 60 * 60 * 1000);
}
export function excelSerialNumberToDate(serialNumber: number) {
  return new Date((serialNumber - 25569) * 86400 * 1000);
}

export function getIntakeRecordDateString(dateParam?: Date) {
  const date = dateParam || new Date();
  const year = date.getFullYear();

  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;

  let day = date.getDate().toString();
  day = day.length > 1 ? day : "0" + day;

  return year + "-" + month + "-" + day;
}

export function fDate(date: string | number | Date, newFormat?: string) {
  const fm = newFormat || "dd MMM yyyy";

  return date ? format(new Date(date), fm) : "";
}

export function fDateTime(date: string | number | Date, newFormat?: string) {
  const fm = newFormat || "dd MMM yyyy p";

  return date ? format(new Date(date), fm) : "";
}

export function fTimestamp(date?: string | number | Date) {
  return date ? getTime(new Date(date)) : "";
}

export function fTime(dateParam?: string | number | Date) {
  const date = dateParam ? new Date(dateParam) : new Date();

  // get hours
  let hours = date.getHours();
  if (isNaN(hours)) return "";

  // determine whether it's AM or PM
  const period = hours >= 12 ? "PM" : "AM";

  // convert hours from 24 hour format to 12 hour format
  hours = hours % 12;

  // 12 AM should be 12, not 0
  hours = hours ? hours : 12;

  // get minutes
  const minutesNumber = date.getMinutes();
  if (isNaN(minutesNumber)) return "";

  // if minutes is less than 10, prepend a '0' in front
  const minutes = minutesNumber < 10 ? "0" + minutesNumber : minutesNumber;

  // format the time
  return hours + ":" + minutes + " " + period;
}

export function fToNow(date: string | number | Date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : "";
}
