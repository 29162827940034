/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";
import { useDropzone } from "react-dropzone";
import CropIcon from "@mui/icons-material/Crop";
import ImageIcon from "@mui/icons-material/Image";
import PreviewIcon from "@mui/icons-material/Preview";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import * as React from "react";
import ReactCrop, { centerCrop, Crop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import ProgressButton from "./ProgressButton";
import { Button } from "@mui/material";
import { cropImage } from "./FreeRatioCropperUtils";
import assetsService from "../../../service/assets.service";

const Default_Ratio = 1;
export interface FreeRatioCropperProps {
  open: boolean;
  onClose: () => void;
  onSave: (url: string) => void;
  title?: string;
  ratio?: number;
}

export default function FreeRatioCropper({
  open,
  onClose,
  onSave,
  title,
  ratio,
}: FreeRatioCropperProps) {
  const [cropChanged, setCropChanged] = React.useState(true);
  const [originalSize, setOriginalSize] = React.useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });
  const [imageLoadedScale, setImageLoadedScale] = React.useState(1);
  const [currentFileId, setCurrentFileId] = React.useState("");
  const [invalidFile, setInvalidFile] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);

  const [activeStep, setActiveStep] = React.useState(0);
  const imgRef = React.useRef<HTMLImageElement>(null);
  const [imgSrc, setImgSrc] = React.useState("");
  const [crop, setCrop] = React.useState<Crop>();
  const [completedCrop, setCompletedCrop] = React.useState<Crop>();
  const [previewUrl, setPreviewUrl] = React.useState<string>();
  const [uploading, setUploading] = React.useState(false);
  const [uploadingFileName, setUploadingFileName] = React.useState("");

  const onFilesLoad = React.useCallback((acceptedFiles: any[]) => {
    try {
      acceptedFiles.forEach((file) => {
        const fileName = file?.name || "";
        if (!fileName.match(/\.(jpg|jpeg|png|gif)$/i)) {
          setInvalidFile(true);
          return;
        }
        setUploadingFileName(fileName);
        const reader = new FileReader();
        reader.onload = () => {
          try {
            setInvalidFile(false);
            if (!reader.result) {
              setInvalidFile(true);
              return;
            }
            setCrop(undefined);
            setImgSrc(reader.result?.toString() || "");
            setActiveStep(1);
          } catch (error) {
            setInvalidFile(true);
          }
        };
        reader.readAsDataURL(file);
      });
    } catch (error) {
      setInvalidFile(true);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onFilesLoad,
    multiple: false,
  });

  function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          height: 70,
        },
        aspect,
        mediaWidth / 2,
        mediaHeight / 2
      ),
      mediaWidth,
      mediaHeight
    );
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (crop) {
      return;
    }
    const { width, height, naturalWidth, naturalHeight } = e.currentTarget;
    setImageLoadedScale(naturalWidth / width);
    setOriginalSize({ width: naturalWidth, height: naturalHeight });
    setCrop(centerAspectCrop(width, height, Default_Ratio));
  }

  const uploadCrop = async () => {
    setUploading(true);
    const croppedImageFile: any = await cropImage(
      imgSrc,
      completedCrop,
      imageLoadedScale,
      {
        originalWidth: originalSize.width,
        originalHeight: originalSize.height,
        maxWidth: 300,
        maxHeight: 300,
      }
    );
    const URL = await assetsService.uploadFile(
      croppedImageFile,
      uploadingFileName
    );
    if (URL) {
      onSave(URL);
    }
    setUploading(false);
  };

  React.useEffect(() => {
    setCropChanged(true);
  }, [completedCrop]);

  return (
    <Dialog open={open} maxWidth={false}>
      <Box
        p={2}
        pb={3}
        sx={{ background: "white", maxWidth: "100%", minWidth: "450px" }}
      >
        <Box pb={1} sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6">{title || "Upload Image"}</Typography>
          <IconButton onClick={onClose} sx={{ marginTop: "-10px" }}>
            <CloseIcon />
          </IconButton>
        </Box>
        {!imgSrc ? (
          <Box>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Box
                sx={{
                  border: "dashed 2px #ddd",
                  borderRadius: "5px",
                  height: "300px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  opacity: "0.8",
                  background: "#f3f3f3",
                }}
              >
                Click here to select local file
              </Box>
            </div>
          </Box>
        ) : (
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                maxWidth: "500px",
              }}
            >
              <ReactCrop
                disabled={isProcessing}
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={ratio}
              >
                <img
                  alt="Crop me"
                  ref={imgRef}
                  src={imgSrc}
                  style={{ maxWidth: "100%" }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <Button
                onClick={() => setImgSrc("")}
                variant="outlined"
                size="small"
                sx={{ mr: 1 }}
              >
                Cancel this file
              </Button>
              <ProgressButton
                disabled={uploading || !imgSrc}
                inProgress={uploading}
                onClick={() => uploadCrop()}
                title="Upload Crop"
              />
            </Box>
          </Box>
        )}
      </Box>
    </Dialog>
  );
}
