/* eslint-disable @typescript-eslint/no-explicit-any */
import { MenuItem, TextField } from "@mui/material";
import * as React from "react";
import { UserRoles } from "../../../types/auth";

interface IUserRoleSelectProps {
  label?: string;
  placeholder?: string;
  value?: UserRoles;
  onChange: (newVal?: UserRoles) => void;
  error?: boolean;
  helperText?: string;
}
export default function UserRoleSelect(props: IUserRoleSelectProps) {
  const { value, onChange, helperText, error } = props;
  return (
    <TextField
      select
      fullWidth
      placeholder="Select User Role"
      helperText={helperText}
      error={error}
      value={value || ""}
      size="small"
      onChange={(e) => {
        onChange(e.target.value as unknown as UserRoles);
      }}
    >
      {Object.values(UserRoles).map((role, i) => (
        <MenuItem key={`comp-type-${i}`} value={role as UserRoles}>
          {role}
        </MenuItem>
      ))}
    </TextField>
  );
}
