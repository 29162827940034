import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { ICompany } from "../../../types/company";
import companyService from "../../../service/company.service";
import TextField from "@mui/material/TextField";
import { useAppContext } from "../../../contexts/useStorageContext";
import { UserRoles } from "../../../types/auth";

export default function CompanyAutoComplete({
  value,
  onChange,
  fullWidth = false,
}: {
  value?: ICompany;
  onChange?: (selected: ICompany | null) => void;
  fullWidth?: boolean;
}) {
  const { state } = useAppContext();
  const { loggedInUserInfo } = state;
  const [options, setOptions] = React.useState<ICompany[]>([]);
  const fetchCompanyShallowList = async () => {
    const result = await companyService.fetchCompanyShallowList();
    setOptions(result?.data || []);
  };

  React.useEffect(() => {
    if (loggedInUserInfo?.role == UserRoles.superadmin) fetchCompanyShallowList();
  }, []);
  return (
    <Autocomplete
      disabled={loggedInUserInfo?.role != UserRoles.superadmin}
      value={value || null}
      onChange={(event, newValue) => {
        onChange && onChange(newValue);
      }}
      getOptionLabel={(option) => option.name || ""}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth={fullWidth}
          placeholder="Select Company"
          size="small"
          sx={{ minWidth: "200px" }}
        />
      )}
    />
  );
}
