/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
// import Box from "@mui/material/Box";
import * as React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import LinkIcon from "@mui/icons-material/Link";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import WhitePanel from "../../components/Common/WhitePanel";
import Box from "@mui/material/Box";
import SimpleSearchInput from "../../components/Common/SimpleSearchInput";
import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { IUser } from "../../../types/user";
import userService from "../../../service/user.service";
import DietFormDialog from "../../components/diets/DietFormDialog";
import GeneralPopoverWrapper from "../../components/Common/GeneralPopoverWrapper";
import { IDiet } from "../../../types/diet";
import dietService from "../../../service/diet.service";
import { useAppContext } from "../../../contexts/useStorageContext";
import CheckCircle from "@mui/icons-material/CheckCircle";
import ConfirmDialog from "../../components/Common/ConfirmDialog";

export default function Index() {
  const { state } = useAppContext();
  const { myCompanyProfile } = state;
  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);
  const [diets, setDiets] = React.useState<IDiet[]>([]);
  const [tableLoading, setTableLoading] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [openDietFormDialog, setOpenDietFormDialog] = React.useState(false);
  const [targetDiet, setTargetDiet] = React.useState<IDiet>();
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] =
    React.useState(false);

  const columns = [
    {
      field: "name",
      headerName: "Diet Name",
      width: 150,
      editable: true,
    },
    {
      field: "type",
      headerName: "Diet Type",
      width: 150,
      editable: true,
    },
    {
      field: "description",
      headerName: "Description",
      sortable: false,
      width: 250,
    },
    {
      field: "docUrl",
      headerName: "Document",
      sortable: false,
      width: 90,
      renderCell: (params: any) => {
        return params.value ? (
          <a href={params.value} target="_blank">
            <LinkIcon />
          </a>
        ) : null;
      },
    },
    {
      field: "actions",
      headerName: "Action",
      width: 80,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
      renderCell: (params: any) => {
        return (
          <Box>
            <GeneralPopoverWrapper
              closeOnClickContent
              triggerElement={
                <IconButton size="small">
                  <MoreVertIcon />
                </IconButton>
              }
              popoverContent={
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      setTargetDiet(params.row);
                      setOpenDietFormDialog(true);
                    }}
                  >
                    <ListItemIcon>
                      <EditIcon />
                    </ListItemIcon>
                    <ListItemText>View Diet Info</ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setTargetDiet(params.row);
                      setOpenDeleteConfirmDialog(true);
                    }}
                    sx={{ color: "error.main" }}
                  >
                    <ListItemIcon>
                      <DeleteIcon />
                    </ListItemIcon>
                    <ListItemText>Delete Diet</ListItemText>
                  </MenuItem>
                </MenuList>
              }
            />
          </Box>
        );
      },
    },
  ];

  const fetchDiets = async () => {
    setTableLoading(true);
    const result = await dietService.fetchDiets(myCompanyProfile?._id);
    if (result) {
      setDiets(result.data || []);
    }
    setTableLoading(false);
  };

  const handleRemoveDiet = async () => {
    if (!targetDiet?._id) return;
    setOpenDeleteConfirmDialog(false);
    await dietService.deleteDiet(targetDiet?._id);
    await fetchDiets();
  };

  const getFilteredUsers = React.useCallback(() => {
    return diets.filter(
      (d) =>
        d.name?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
        d.type?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
        d.description?.toLowerCase()?.includes(searchTerm.toLowerCase())
    );
  }, [diets, searchTerm]);

  React.useEffect(() => {
    if (myCompanyProfile?._id) fetchDiets();
  }, [myCompanyProfile]);

  return (
    <>
      <Box mb={1}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "24px",
            fontWeight: "500",
            opacity: 0.8,
          }}
        >
          Manage Diets
          <Box
            sx={{ ml: 1, fontSize: "24px", fontWeight: "500", opacity: "0.6" }}
          >{`(${diets.length})`}</Box>
        </Box>
      </Box>
      <WhitePanel>
        <Box
          pb={2}
          sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
        >
          <Box>
            <SimpleSearchInput
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onClear={() => setSearchTerm("")}
              size="small"
              placeholder="Search"
            />
          </Box>

          {selectedIds?.length ? (
            <Button
              startIcon={<DeleteIcon />}
              disableElevation
              variant="contained"
              sx={{ ml: 2 }}
              color="error"
            >
              Delete
            </Button>
          ) : null}
          <Box sx={{ flex: 1 }} />

          <Button
            onClick={() => {
              setTargetDiet(undefined);
              setOpenDietFormDialog(true);
            }}
            startIcon={<AddCircleOutlineIcon />}
            disableElevation
            variant="contained"
            sx={{ ml: 2 }}
          >
            Add New Diet
          </Button>
        </Box>
        <DataGridPro
          loading={tableLoading}
          getRowId={(row) => row._id as any}
          autoHeight
          // selectionModel={selectedIds}
          // onSelectionModelChange={(changedIds: any) => {
          //   setSelectedIds(changedIds);
          // }}
          rows={getFilteredUsers()}
          columns={columns}
          // checkboxSelection
          disableSelectionOnClick
        />
        {openDietFormDialog && (
          <DietFormDialog
            open
            diet={targetDiet}
            onClose={() => setOpenDietFormDialog(false)}
            onCreated={(created) => {
              setDiets([created, ...diets]);
              setOpenDietFormDialog(false);
            }}
            onUpdated={(updated) => {
              const tempDiets = [...diets];
              const targetIndex = tempDiets.findIndex(
                (p) => p._id == updated._id
              );
              if (targetIndex > -1) {
                tempDiets.splice(targetIndex, 1, updated);
                setDiets(tempDiets);
                setOpenDietFormDialog(false);
              }
            }}
          />
        )}
      </WhitePanel>
      {openDeleteConfirmDialog && targetDiet ? (
        <ConfirmDialog
          title={<>{`Remove Diet: ${targetDiet.name}`}</>}
          content={
            <>{`Are you sure you want to remove "${targetDiet.name}"?`}</>
          }
          onCancel={() => setOpenDeleteConfirmDialog(false)}
          onConfirm={() => handleRemoveDiet()}
          onConfirmText={<>Delete</>}
        />
      ) : null}
    </>
  );
}
