import Chip from "@mui/material/Chip";
import GeneralPopoverWrapper from "../../Common/GeneralPopoverWrapper";
import Box from "@mui/material/Box";
import DownloadIcon from "@mui/icons-material/Download";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { IDiet } from "../../../../types/diet";
import { InfoTag } from "../../Common/ListItemInfoTag";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { fDate } from "../../../../helpers/formatTime";

export default function DietLogsPopover({ diet }: { diet: IDiet }) {
  return (
    <GeneralPopoverWrapper
      triggerElement={
        <Chip
          variant="outlined"
          label={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <AccessTimeIcon sx={{ mr: 1 }} />
              {`${diet?.changeLogs?.length || 0}`}
            </Box>
          }
          sx={{
            ml: 1,
            cursor: "pointer",
            "& .MuiChip-label": { pl: "5px !important", pr: 1 },
          }}
        />
      }
      popoverContent={
        <Box>
          <Box sx={{ px: 2, py: 2, fontWeight: "500" }}>Diet Change Logs</Box>
          {diet.changeLogs?.map((log, i) => (
            <Box
              key={i}
              sx={{
                display: "flex",
                alignItems: "center",
                py: 1,
                px: 2,
                minWidth: "350px",
                maxWidth: "350px",
                borderTop: "solid 1px #ddd",
              }}
            >
              <div style={{ flex: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <InfoTag>
                    <AccessTimeIcon sx={{ fontSize: "14px", mr: "2px" }} />
                    <div className="exact-date">{fDate(log.date || "")}</div>
                  </InfoTag>
                </Box>
                {log.description ? (
                  <Box
                    sx={{
                      fontSize: "13px",
                      mt: 1,
                      opacity: "0.7",
                    }}
                  >
                    {log.description}
                  </Box>
                ) : null}
              </div>
              {log.docUrl ? (
                <Tooltip title="Download Diet Document" placement="right" arrow>
                  <a href={log.docUrl} target="_blank">
                    <IconButton>
                      <DownloadIcon />
                    </IconButton>
                  </a>
                </Tooltip>
              ) : null}
            </Box>
          ))}
        </Box>
      }
    />
  );
}
