/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { IBreed } from "../../../types/breed";
import breedService from "../../../service/breed.service";
import { useAppContext } from "../../../contexts/useStorageContext";
import ListItem from "@mui/material/ListItem";
import BreedFormDialog from "./BreedFormDialog";

const filter = createFilterOptions();
export default function BreedAutoComplete({
  label,
  value,
  onChange,
  fullWidth = false,
}: {
  label?: string;
  value?: IBreed;
  onChange?: (selected: IBreed | null | undefined) => void;
  fullWidth?: boolean;
}) {
  const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
  const { state } = useAppContext();
  const { currentCompany } = state;
  const [options, setOptions] = React.useState<IBreed[]>([]);
  const fetchOptions = async () => {
    const result = await breedService.fetchBreedsByFarmIdBarnId(currentCompany?._id);
    setOptions(result?.data || []);
  };

  React.useEffect(() => {
    fetchOptions();
  }, []);
  return (
    <>
      <Autocomplete
        value={value || null}
        onChange={(event, newValue) => {
          if (newValue?.name?.toLowerCase()?.includes("create new breed")) {
            setOpenCreateDialog(true);
          } else {
            onChange && onChange(newValue);
          }
        }}
        getOptionLabel={(option) => value?.name || ""}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            fullWidth={fullWidth}
            placeholder="Select Breed"
            size="small"
            sx={{ minWidth: "200px" }}
          />
        )}
        renderOption={(props, option) => <ListItem {...props}>{option.name}</ListItem>}
        filterOptions={(options, params) => {
          const filtered = filter(options, params as any);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option.name);
          // if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            name: `Create New Breed`,
          });
          // }

          return filtered as any[];
        }}
      />
      {openCreateDialog && (
        <BreedFormDialog
          open
          onClose={() => setOpenCreateDialog(false)}
          onNewCreated={(created) => {
            onChange && onChange(created);
            setOptions([created, ...options]);
            setOpenCreateDialog(false);
          }}
        />
      )}
    </>
  );
}
