/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IPenLooseFeedRecord } from "../types/dailyIntake";
import backendApi from "./backendApi";
import { showSnackbar } from "./snackbar.service";

const routePrefix = "loose-feed";

export default {
  async getLooseFeed(
    companyId: string,
    penId: string,
    date: string
  ): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/get-record`, {
        companyId,
        penId,
        date,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },

  async getByDateRange(payload: {
    companyId: string;
    penId?: string;
    penIds?: string[];
    from: string;
    to: string;
  }): Promise<IPenLooseFeedRecord[]> {
    try {
      const { companyId, penId, penIds, from, to } = payload;
      const result = await backendApi()?.post(
        `${routePrefix}/get-by-date-range`,
        {
          companyId,
          penId,
          penIds,
          from,
          to,
        }
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
      }
      return [];
    }
  },

  async save(companyId: string, record: IPenLooseFeedRecord): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/save`, {
        companyId,
        record: record,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "Loose feed is successfully saved"
      );
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
};
