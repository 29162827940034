/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import EditIcon from "@mui/icons-material/Edit";
import { IPen } from "../../../types/pen";
import GeneralPopoverWrapper from "../Common/GeneralPopoverWrapper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import { useAppContext } from "../../../contexts/useStorageContext";
import CowFormDialog from "../cow/CowFormDialog";
import { ICow } from "../../../types/cow";
import DailyIntakePanel from "./DailyIntakePanel";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PenIntakeOverviewDialog from "./PenIntakeOverviewDialog";
import PenFormDialog from "./PenFormDialog";
import penService from "../../../service/pen.service";
import ConfirmDialog from "../Common/ConfirmDialog";
import ReportsDialog from "../Report/ReportsDialog";
import TableControlHeader from "../Common/TableControlHeader";
import { IBarn } from "../../../types/barn";

export default function PenTable({
  loading,
  pens,
  selectedIds,
  onChangeSelectedIds,
  onAddNewCow,
  onChangePens,
  selectedBarns,
  onClose,
}: {
  loading?: boolean;
  pens: IPen[];
  selectedIds: string[];
  onChangeSelectedIds: (selected: string[]) => void;
  onAddNewCow: (createdCow: ICow) => void;
  onChangePens?: (changedPens: IPen[]) => void;
  selectedBarns?: IBarn[];
  onClose?: () => void;
}) {
  const { state } = useAppContext();
  const { currentFarm, currentCompany } = state;
  const [openCowDialog, setOpenCowDialog] = React.useState(false);
  const [openFeedDialog, setOpenFeedDialog] = React.useState(false);
  const [openIntakeTableDialog, setOpenIntakeTableDialog] =
    React.useState(false);
  const [targetPen, setTargetPen] = React.useState<IPen>();
  const [openPenEditDialog, setOpenPenEditDialog] = React.useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] =
    React.useState(false);
  const [tableProcessing, setTableProcessing] = React.useState(false);
  const [openReportsDialog, setOpenReportsDialog] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 150,
      editable: false,
    },
    {
      field: "barn",
      headerName: "Barn",
      width: 150,
      editable: false,
      valueGetter: (params: any) => params.value?.name,
    },
    {
      field: "diet",
      headerName: "Diet",
      width: 150,
      editable: false,
      renderCell: (params: any) => {
        return <div>{params.row?.diet?.type}</div>;
      },
    },
    {
      field: "actions",
      headerName: "",
      width: 10,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
      renderCell: (params: any) => {
        return (
          <Box>
            <GeneralPopoverWrapper
              closeOnClickContent
              triggerElement={
                <IconButton size="small">
                  <MoreVertIcon />
                </IconButton>
              }
              popoverContent={
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      setTargetPen(params.row);
                      setOpenPenEditDialog(true);
                    }}
                  >
                    <ListItemIcon>
                      <EditIcon />
                    </ListItemIcon>
                    <ListItemText>Edit Pen Info</ListItemText>
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      setTargetPen(params.row);
                      setOpenCowDialog(true);
                    }}
                  >
                    <ListItemIcon>
                      <AddCircleOutlineIcon />
                    </ListItemIcon>
                    <ListItemText>Add New Cow</ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setTargetPen(params.row);
                      setOpenFeedDialog(true);
                    }}
                  >
                    <ListItemIcon>
                      <EditCalendarIcon />
                    </ListItemIcon>
                    <ListItemText>Daily Intake Record</ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setTargetPen(params.row);
                      setOpenIntakeTableDialog(true);
                    }}
                  >
                    <ListItemIcon>
                      <TrendingUpIcon />
                    </ListItemIcon>
                    <ListItemText>Intake Overview</ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setTargetPen(params.row);
                      setOpenReportsDialog(true);
                    }}
                  >
                    <ListItemIcon>
                      <NoteAltIcon />
                    </ListItemIcon>
                    <ListItemText>Reports</ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setTargetPen(params.row);
                      setOpenDeleteConfirmDialog(true);
                    }}
                    sx={{ color: "error.main" }}
                  >
                    <ListItemIcon>
                      <DeleteIcon color="error" />
                    </ListItemIcon>
                    <ListItemText>Delete Pen</ListItemText>
                  </MenuItem>
                </MenuList>
              }
            />
          </Box>
        );
      },
    },
  ];

  const deletePens = async (penId: string) => {
    setOpenDeleteConfirmDialog(false);
    setTableProcessing(true);
    await penService.deletePens(currentCompany?._id || "", [penId]);
    onChangePens && onChangePens(pens.filter((p) => p._id != penId));
    setTableProcessing(false);
  };

  return (
    <Box>
      <TableControlHeader
        searchTerm={searchTerm}
        onChangeSearchTerm={(term) => setSearchTerm(term)}
        title="Pens"
        itemLength={pens.length}
        selectedItemIds={selectedIds}
        parentsInfo={
          selectedBarns?.length
            ? selectedBarns?.length == 1
              ? selectedBarns?.[0].name
              : `${selectedBarns?.length} Barns`
            : ""
        }
        onClickAddButton={() => {
          setTargetPen(undefined);
          setOpenPenEditDialog(true);
        }}
        onClose={onClose}
      />
      <DataGridPro
        loading={loading || tableProcessing}
        getRowId={(row) => row._id || ""}
        autoHeight
        rows={pens.filter(
          (p) =>
            (!searchTerm ||
              p.name?.toLowerCase()?.includes(searchTerm.toLowerCase())) &&
            (!selectedBarns?.length ||
              selectedBarns.map((b) => b._id).includes(p.barn?._id))
        )}
        columns={columns}
        disableSelectionOnClick
        checkboxSelection
        selectionModel={selectedIds}
        onSelectionModelChange={(selected) => {
          onChangeSelectedIds(selected as string[]);
        }}
        initialState={{ pinnedColumns: { right: ["actions"] } }}
      />
      {openCowDialog && targetPen ? (
        <CowFormDialog
          farmId={currentFarm?._id || ""}
          barn={targetPen.barn}
          pen={targetPen}
          open
          onClose={() => setOpenCowDialog(false)}
          onNewCreated={(createdCow) => {
            onAddNewCow(createdCow);
            setOpenCowDialog(false);
          }}
        />
      ) : null}
      {openFeedDialog && targetPen ? (
        <DailyIntakePanel
          pen={targetPen}
          open
          onClose={() => setOpenFeedDialog(false)}
        />
      ) : null}
      {openIntakeTableDialog && targetPen ? (
        <PenIntakeOverviewDialog
          open
          onClose={() => setOpenIntakeTableDialog(false)}
          pen={targetPen}
        />
      ) : null}

      {currentFarm?._id && openPenEditDialog ? (
        <PenFormDialog
          farmId={currentFarm?._id || ""}
          open
          onClose={() => setOpenPenEditDialog(false)}
          pen={targetPen}
          onUpdated={(updatedPen) => {
            const tempPens = [...pens];
            const targetIndex = tempPens.findIndex(
              (p) => p._id == updatedPen._id
            );
            if (targetIndex > -1) {
              tempPens.splice(targetIndex, 1, updatedPen);
              onChangePens && onChangePens(tempPens);
              setOpenPenEditDialog(false);
            }
          }}
          onNewCreated={(created) => {
            onChangePens && onChangePens([created, ...pens]);
            setOpenPenEditDialog(false);
          }}
        />
      ) : null}

      {openDeleteConfirmDialog && targetPen ? (
        <ConfirmDialog
          title={<>Confirm Deleting Pen</>}
          content={
            <Box
              sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
            >
              Are you sure you want to delete
              <Box
                sx={{ color: "warning.main", px: 1 }}
              >{` ${targetPen.name}`}</Box>
              ?
            </Box>
          }
          onConfirm={() => deletePens(targetPen._id || "")}
          onCancel={() => setOpenDeleteConfirmDialog(false)}
        />
      ) : null}
      {openReportsDialog && targetPen ? (
        <ReportsDialog
          pen={targetPen}
          open
          onClose={() => setOpenReportsDialog(false)}
        />
      ) : null}
    </Box>
  );
}
