/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import breedService from "../../../service/breed.service";
import { useAppContext } from "../../../contexts/useStorageContext";
import ListItem from "@mui/material/ListItem";
import dietService from "../../../service/diet.service";
import { IDiet } from "../../../types/diet";
import DietFormDialog from "./DietFormDialog";

const filter = createFilterOptions();
export default function DietAutoComplete({
  selectedId,
  label,
  value,
  onChange,
  fullWidth = false,
  error,
}: {
  selectedId?: string;
  label?: string;
  value?: IDiet;
  onChange?: (selected: IDiet | null | undefined) => void;
  fullWidth?: boolean;
  error?: boolean;
}) {
  const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
  const { state } = useAppContext();
  const { currentCompany } = state;
  const [options, setOptions] = React.useState<IDiet[]>([]);

  const fetchOptions = async () => {
    const result = await dietService.fetchDiets(currentCompany?._id);
    setOptions(result?.data || []);
  };

  React.useEffect(() => {
    fetchOptions();
  }, []);
  return (
    <>
      <Autocomplete
        value={value || options.find((d) => selectedId && d._id == selectedId) || null}
        onChange={(event, newValue) => {
          if (newValue?.name?.toLowerCase()?.includes("create new diet")) {
            setOpenCreateDialog(true);
          } else {
            onChange && onChange(newValue);
          }
        }}
        getOptionLabel={(option) => option?.name || ""}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            fullWidth={fullWidth}
            placeholder="Select Diet"
            size="small"
            sx={{ minWidth: "200px" }}
            error={error}
          />
        )}
        renderOption={(props, option) => <ListItem {...props}>{option.name}</ListItem>}
        filterOptions={(options, params) => {
          const filtered = filter(options, params as any);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option.name);
          // if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            name: `Create New Diet`,
          });
          // }

          return filtered as any[];
        }}
      />
      {openCreateDialog && (
        <DietFormDialog
          open
          onClose={() => setOpenCreateDialog(false)}
          onCreated={(created) => {
            onChange && onChange(created);
            setOptions([created, ...options]);
            setOpenCreateDialog(false);
          }}
        />
      )}
    </>
  );
}
