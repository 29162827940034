/* eslint-disable @typescript-eslint/no-explicit-any */
import { IReportPopulated } from "../types/report";
import backendApi from "./backendApi";
import { showSnackbar } from "./snackbar.service";

const routePrefix = "report";

export default {
  async getReports({
    company,
    farm,
    barn,
    pen,
    cow,
  }: {
    company: string;
    farm?: string;
    barn?: string;
    pen?: string;
    cow?: string;
  }): Promise<IReportPopulated[]> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/get`, {
        company,
        farm,
        barn,
        pen,
        cow,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return [];
      }
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
      }
      return [];
    }
  },
  async addNewReport(
    report: IReportPopulated,
    company: string
  ): Promise<IReportPopulated | undefined> {
    if (!report) return;
    try {
      const result = await backendApi()?.post(`${routePrefix}/create`, {
        company,
        report,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return;
      }
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "New report successfully created"
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return;
      }
    }
  },

  async updateReport(company: string, report: IReportPopulated): Promise<any> {
    if (!company) return;
    try {
      const result = await backendApi()?.post(`${routePrefix}/update`, {
        company,
        report,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return report;
      }
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "Successfully updated"
      );
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async deleteReport(company: string, id: string): Promise<any> {
    if (!id) return;
    try {
      const result = await backendApi()?.post(`${routePrefix}/delete`, {
        id,
        company,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "Successfully deleted"
      );
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
};
