import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { IBarn } from "../../../types/barn";
import barnService from "../../../service/barn.service";
import { useAppContext } from "../../../contexts/useStorageContext";

export default function BarnAutoComplete({
  label,
  selectedId,
  farmId,
  value,
  onChange,
  fullWidth = false,
}: {
  label?: string;
  selectedId?: string;
  farmId: string;
  value?: IBarn;
  onChange?: (selected: IBarn | null | undefined) => void;
  fullWidth?: boolean;
}) {
  const { state } = useAppContext();
  const { currentCompany } = state;
  const [options, setOptions] = React.useState<IBarn[]>([]);
  const fetchOptions = async () => {
    if (!currentCompany?._id) return;
    const result = await barnService.fetchBarns(farmId, currentCompany?._id);
    setOptions(result?.data || []);
    if (selectedId) {
      onChange && onChange(result?.data?.find((b: IBarn) => b._id == selectedId));
    }
  };

  React.useEffect(() => {
    fetchOptions();
  }, [currentCompany]);
  return (
    <Autocomplete
      value={value || options.find((o) => o._id && selectedId && o._id == selectedId) || null}
      onChange={(event, newValue) => {
        onChange && onChange(newValue);
      }}
      getOptionLabel={(option) => option.name || ""}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          fullWidth={fullWidth}
          placeholder="Select Barn"
          size="small"
          sx={{ minWidth: "200px" }}
        />
      )}
    />
  );
}
