/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import * as React from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ProgressButton from "../../components/Common/ProgressButton";
import { IUser } from "../../../types/user";
import userService from "../../../service/user.service";
import CompanyAutoComplete from "../../components/company/CompanyAutoComplete";
import UserRoleSelect from "./UserRoleSelect";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import FreeRatioCropper from "../../components/Common/FreeRatioCropper";
import { useAppContext } from "../../../contexts/useStorageContext";
import { UserRoles } from "../../../types/auth";

export default function UserDetailForm({
  user,
  onNewCreated,
  onUpdated,
}: {
  user?: IUser;
  onNewCreated?: (created: IUser) => void;
  onUpdated?: (updates: IUser) => void;
}) {
  const { state } = useAppContext();
  const { myProfile } = state;
  const [editingUser, setEditingUser] = React.useState<IUser>({
    approved: true,
  });
  const [processing, setProcessing] = React.useState(false);
  const [openImageUploadDialog, setOpenImageUploadDialog] =
    React.useState(false);

  const handleCreate = async () => {
    setProcessing(true);
    const result = await userService.addNewUser({
      fullname: editingUser.fullname,
      email: editingUser.email,
      companyId: editingUser.company?._id,
      role: editingUser.role,
      phone: editingUser.phone,
      approved: editingUser.approved,
    });
    if (result?.data) {
      onNewCreated && onNewCreated(result.data);
    }
    setProcessing(false);
  };

  const handleUpdate = async () => {
    setProcessing(true);
    const result = await userService.updateUser(editingUser);
    if (result?.data) {
      onUpdated && onUpdated(editingUser);
    }
    setProcessing(false);
  };

  React.useEffect(() => {
    if (user) {
      setEditingUser(user);
    }
  }, [user]);
  return (
    <Box>
      <Stack spacing={3}>
        <Box
          sx={{
            position: "relative",
            width: "150px",
            height: "150px",
            background: "#f3f3f3",
            borderRadius: "5px",
            backgroundImage: `url(${editingUser.avatar || ""})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              top: 10,
              left: 10,
              backgroundColor: "#0003",
              color: "#fff",
              "&:hover": { backgroundColor: "primary.main", color: "#fff" },
            }}
            onClick={() => setOpenImageUploadDialog(true)}
          >
            <CameraAltIcon />
          </IconButton>
        </Box>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 3, sm: 2 }}
        >
          <TextField
            value={editingUser.fullname || ""}
            onChange={(e) =>
              setEditingUser({ ...editingUser, fullname: e.target.value })
            }
            placeholder="Full Name"
            fullWidth
            size="small"
          />
          <TextField
            value={editingUser.email || ""}
            onChange={(e) =>
              setEditingUser({ ...editingUser, email: e.target.value })
            }
            placeholder="Email"
            fullWidth
            size="small"
          />
        </Stack>
        {myProfile?.role == UserRoles.superadmin && (
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 3, sm: 2 }}
          >
            <Box sx={{ flex: 1 }}>
              <CompanyAutoComplete
                fullWidth
                value={editingUser.company}
                onChange={(selected) =>
                  setEditingUser({
                    ...editingUser,
                    company: selected || undefined,
                  })
                }
              />
            </Box>

            <Box sx={{ flex: 1 }}>
              <UserRoleSelect
                value={editingUser.role}
                onChange={(newVal) =>
                  setEditingUser({ ...editingUser, role: newVal })
                }
              />
            </Box>
          </Stack>
        )}
        {myProfile?.role == UserRoles.superadmin && (
          <FormControlLabel
            control={
              <Switch
                checked={editingUser.approved}
                onChange={(e, checked) =>
                  setEditingUser({ ...editingUser, approved: checked })
                }
              />
            }
            label="Approved Status"
            sx={{ flex: 1 }}
          />
        )}
      </Stack>
      <Box mt={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
        {user ? (
          <ProgressButton
            onClick={() => !processing && handleUpdate()}
            title="Save Changes"
            inProgress={processing}
          />
        ) : (
          <ProgressButton
            onClick={() => !processing && handleCreate()}
            title="Create User"
            inProgress={processing}
          />
        )}
      </Box>
      {openImageUploadDialog && (
        <FreeRatioCropper
          ratio={1}
          onSave={(url) => {
            setEditingUser({ ...editingUser, avatar: url });
            setOpenImageUploadDialog(false);
          }}
          open
          onClose={() => setOpenImageUploadDialog(false)}
        />
      )}
    </Box>
  );
}
