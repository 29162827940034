import { BrowserRouter, Route, Routes } from "react-router-dom";

import Layout from "../views/layout";
import Home from "../views/pages/home";
import Companies from "../views/pages/companies";
import Farms from "../views/pages/farms";
import Users from "../views/pages/users";
import Settings from "../views/pages/settings";
import Diets from "../views/pages/diets";
import Login from "../views/pages/auth/Login";
import Signup from "../views/pages/auth/Signup";
import { useAppContext } from "../contexts/useStorageContext";
import React from "react";
import ForgotPassword from "../views/pages/auth/forgot";
import PrivacyPolicy from "../views/pages/policy/privacyPolicy";
import TermsAndConditions from "../views/pages/policy/termsAndConditions";

export default function Root() {
  const { state } = useAppContext();
  return (
    <BrowserRouter>
      {state.loggedInUserInfo?.email ? (
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="dashboard" element={<Home />} />
            <Route path="companies" element={<Companies />} />
            <Route path="farms" element={<Farms />} />
            <Route path="users" element={<Users />} />
            <Route path="settings" element={<Settings />} />
            <Route path="diets" element={<Diets />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            ></Route>
          </Route>
        </Routes>
      ) : (
        <Routes>
          <Route path="/forgot-password" element={<ForgotPassword />}></Route>
          <Route path="/register" element={<Signup />}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          ></Route>
          <Route path="*" element={<Login />}></Route>
        </Routes>
      )}
    </BrowserRouter>
  );
}
