/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { IBarn } from "../../../../types/barn";

export default function MultipleBarnAutoComplete({
  options,
  value,
  onChange,
}: {
  options: IBarn[];
  value?: IBarn[];
  onChange?: (selected: IBarn[] | null) => void;
}) {
  return (
    <Autocomplete
      multiple
      value={value || []}
      onChange={(event, newValue) => {
        onChange && onChange(newValue);
      }}
      getOptionLabel={(option) => option.name || ""}
      options={options}
      sx={{
        minWidth: { xs: "100%", sm: 180 },
        "& .MuiAutocomplete-tag.MuiChip-root": {
          my: 0,
          maxHeight: "28px",
          background: "white",
        },
      }}
      renderInput={(params) => <TextField {...params} fullWidth placeholder="Select Barn" size="small" />}
      limitTags={2}
      fullWidth
    />
  );
}
