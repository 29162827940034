/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from "@mui/material/Box";
import React from "react";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { Button, InputAdornment, Paper } from "@mui/material";
import { IFeed } from "../../../types/dailyIntake";
import { useAppContext } from "../../../contexts/useStorageContext";
import { FarmContext } from "../../pages/farms/FarmContext";
import { IDiet } from "../../../types/diet";
import CowAutoComplete from "../cow/CowAutocomplete";

export default function FeedFormDialog({
  diet,
  feed,
  open,
  onClose,
  onSave,
  onAdd,
  stallCount,
}: {
  diet?: IDiet;
  feed?: IFeed;
  open: boolean;
  onClose: () => void;
  onSave?: (feedDetails: IFeed) => void;
  onAdd?: (feedDetails: IFeed) => void;
  stallCount?: number;
}): React.ReactElement {
  const { state } = useAppContext();
  const { currentCompany } = state;
  const farmContext = React.useContext(FarmContext);
  const [editingFeed, setEditingFeed] = React.useState<IFeed>(
    feed || {
      timestamp: new Date(),
      diet: diet?._id,
    }
  );
  const [isAutoFillable, setIsAutoFillable] = React.useState(false);

  const generateBlankObjectArray = (number: number) => {
    const resultArray: any[] = [];
    for (let i = 0; i < number; i++) {
      resultArray.push({});
    }
    return resultArray;
  };

  const filleEmptyStartTimesInBetween = () => {
    const tempEditingFeed = { ...editingFeed };
    tempEditingFeed.stallFeeds?.map((stallFeed, i) => {
      const previousStartTime = editingFeed.stallFeeds?.[i - 1]?.startTime;
      const nextStartTime = editingFeed.stallFeeds?.find(
        (f, j) => f?.startTime && j > i
      )?.startTime;
      const nextStartTimeIndex =
        editingFeed.stallFeeds?.findIndex((f, j) => f?.startTime && j > i) || 0;
      const fillable =
        i && !stallFeed?.startTime && previousStartTime && nextStartTime
          ? true
          : false;
      if (fillable) {
        stallFeed.startTime = new Date(
          (previousStartTime?.getTime() || 0) +
            ((nextStartTime?.getTime() || 0) -
              (previousStartTime?.getTime() || 0)) /
              (nextStartTimeIndex - i + 1)
        );
      }
    });
    setEditingFeed(tempEditingFeed);
  };

  React.useEffect(() => {
    const tempIsFallable = editingFeed.stallFeeds?.filter((s, i) => {
      const previousStartTime = editingFeed.stallFeeds?.[i - 1]?.startTime;
      const nextStartTime = editingFeed.stallFeeds?.find(
        (f, j) => f?.startTime && j > i
      )?.startTime;

      const val =
        i && !s?.startTime && previousStartTime && nextStartTime ? true : false;
      return val;
    }).length
      ? true
      : false;
    setIsAutoFillable(tempIsFallable);
  }, [editingFeed]);

  return (
    <>
      <Dialog open={open} maxWidth="lg">
        <Box
          sx={{
            background: "white",
            width: { xs: "100%", sm: "unset" },
            maxWidth: "100%",
          }}
        >
          <Box
            p={2}
            pt={1}
            pb={0}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography
              variant="h6"
              sx={{ display: "flex", alignItems: "flex-end" }}
            >
              <Box
                sx={{
                  display: { xs: "none", sm: "flex" },
                  alignItems: "flex-end",
                }}
              >
                Feed Details
              </Box>
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box p={2}>
            <Stack spacing={2}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
                <Box sx={{ flex: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label="Feed Time"
                      value={dayjs(editingFeed.timestamp) || null}
                      onChange={(newValue) =>
                        setEditingFeed({
                          ...editingFeed,
                          timestamp: newValue?.toDate(),
                        })
                      }
                      slotProps={{
                        textField: { size: "small", fullWidth: true },
                      }}
                    />
                  </LocalizationProvider>
                </Box>
                <TextField
                  sx={{ flex: 1 }}
                  size="small"
                  label="Fed Amount"
                  type="number"
                  value={editingFeed.fedAmount}
                  onChange={(e: any) =>
                    setEditingFeed({
                      ...editingFeed,
                      fedAmount: Number(e.target.value) || undefined,
                    })
                  }
                  fullWidth
                />
                <TextField
                  sx={{ flex: 1 }}
                  size="small"
                  label="Refused Amount"
                  type="number"
                  value={editingFeed.refusedAmount}
                  onChange={(e: any) =>
                    setEditingFeed({
                      ...editingFeed,
                      refusedAmount: Number(e.target.value) || undefined,
                    })
                  }
                  fullWidth
                />
              </Stack>
              <Box
                sx={{
                  maxHeight: { xs: "320px", sm: "450px" },
                  overflow: "auto",
                }}
              >
                {new Array(stallCount || 0).fill(undefined).map((el, i) => (
                  <Paper
                    key={`stall-${i}`}
                    sx={{
                      display: { xs: "block", sm: "flex" },
                      flexWrap: "wrap",
                      alignItems: "center",
                      p: "5px 8px",
                      mb: 1,
                      "& .MuiFormControl-root": {
                        minWidth: "unset",
                      },
                      "& .MuiInputBase-root": {
                        py: "0px !important",
                        pr: "20px",
                        minWidth: "unset",
                        height: "25px",
                        "& input": { height: "16px", pl: "0px !important" },
                      },
                      "& .MuiAutocomplete-hasClearIcon.MuiInputBase-root": {
                        pr: "40px",
                      },
                      "& .MuiAutocomplete-endAdornment": { right: "1px" },
                    }}
                  >
                    <Stack spacing={1} direction={{ xs: "column", sm: "row" }}>
                      <Box mr={1}>{`Stall ${i + 1}`}</Box>
                      <Box
                        sx={{
                          width: "150px",
                        }}
                      >
                        <CowAutoComplete
                          selectedId={editingFeed.stallFeeds?.[i]?.cowId}
                          cows={farmContext.cows}
                          fullWidth
                          onChange={(selected) => {
                            const tempStallFeeds = [
                              ...(editingFeed.stallFeeds ||
                                generateBlankObjectArray(stallCount || 0)),
                            ];
                            const targetStallFeed = {
                              ...(tempStallFeeds[i] || { stallNumber: i + 1 }),
                            };
                            targetStallFeed.cowId = selected?._id || "";
                            tempStallFeeds.splice(i, 1, targetStallFeed);
                            setEditingFeed({
                              ...editingFeed,
                              stallFeeds: tempStallFeeds,
                            });
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: "120px",
                          "& .MuiInputBase-root": {
                            px: "5px",
                          },
                          "& input": { pr: "0px" },
                          "& .MuiInputAdornment-root": {
                            mr: "0px",
                          },
                        }}
                      >
                        <TextField
                          sx={{ flex: 1 }}
                          placeholder=""
                          size="small"
                          type="number"
                          value={editingFeed.stallFeeds?.[i]?.fedAmount}
                          onChange={(e: any) => {
                            const tempStallFeeds = [
                              ...(editingFeed.stallFeeds ||
                                generateBlankObjectArray(stallCount || 0)),
                            ];
                            const targetStallFeed = {
                              ...(tempStallFeeds[i] || { stallNumber: i + 1 }),
                            };
                            targetStallFeed.fedAmount =
                              Number(e.target.value) || undefined;
                            tempStallFeeds.splice(i, 1, targetStallFeed);
                            setEditingFeed({
                              ...editingFeed,
                              stallFeeds: tempStallFeeds,
                            });
                          }}
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {"Fed:"}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>

                      <Box
                        sx={{
                          width: "120px",
                          "& .MuiInputBase-root": {
                            px: "5px",
                          },
                          "& input": { pr: "0px" },
                          "& .MuiInputAdornment-root": {
                            mr: "0px",
                          },
                        }}
                      >
                        <TextField
                          sx={{ flex: 1 }}
                          placeholder=""
                          size="small"
                          type="number"
                          value={editingFeed.stallFeeds?.[i]?.refusedAmount}
                          onChange={(e: any) => {
                            const tempStallFeeds = [
                              ...(editingFeed.stallFeeds ||
                                generateBlankObjectArray(stallCount || 0)),
                            ];
                            const targetStallFeed = {
                              ...(tempStallFeeds[i] || { stallNumber: i + 1 }),
                            };
                            targetStallFeed.refusedAmount =
                              Number(e.target.value) || undefined;
                            tempStallFeeds.splice(i, 1, targetStallFeed);
                            setEditingFeed({
                              ...editingFeed,
                              stallFeeds: tempStallFeeds,
                            });
                          }}
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {"Ref:"}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: "130px",
                          "& .MuiInputBase-root": {
                            px: "5px",
                          },
                          "& input": { pr: "0px" },
                          "& .MuiInputAdornment-root": {
                            mx: "0px",
                          },
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            value={
                              (editingFeed.stallFeeds?.[i]?.startTime
                                ? dayjs(editingFeed.stallFeeds?.[i]?.startTime)
                                : undefined) || null
                            }
                            onChange={(newValue) => {
                              const tempStallFeeds = [
                                ...(editingFeed.stallFeeds ||
                                  generateBlankObjectArray(stallCount || 0)),
                              ];
                              const targetStallFeed = {
                                ...(tempStallFeeds[i] || {
                                  stallNumber: i + 1,
                                }),
                              };
                              targetStallFeed.startTime =
                                newValue?.toDate() || undefined;
                              tempStallFeeds.splice(i, 1, targetStallFeed);
                              setEditingFeed({
                                ...editingFeed,
                                stallFeeds: tempStallFeeds,
                              });
                            }}
                            slotProps={{
                              textField: {
                                size: "small",
                                InputProps: {
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <PlayArrowIcon
                                        color={
                                          editingFeed.stallFeeds?.[i]?.startTime
                                            ? "success"
                                            : "disabled"
                                        }
                                      />
                                    </InputAdornment>
                                  ),
                                },
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </Box>

                      <Box
                        sx={{
                          width: "130px",
                          "& .MuiInputBase-root": {
                            px: "5px",
                          },
                          "& input": { pr: "0px" },
                          "& .MuiInputAdornment-root": {
                            mx: "0px",
                          },
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            minutesStep={1}
                            timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                            value={
                              (editingFeed.stallFeeds?.[i]?.endTime
                                ? dayjs(editingFeed.stallFeeds?.[i]?.endTime)
                                : undefined) || null
                            }
                            onChange={(newValue) => {
                              const tempStallFeeds = [
                                ...(editingFeed.stallFeeds ||
                                  generateBlankObjectArray(stallCount || 0)),
                              ];
                              const targetStallFeed = {
                                ...(tempStallFeeds[i] || {
                                  stallNumber: i + 1,
                                }),
                              };
                              targetStallFeed.endTime =
                                newValue?.toDate() || undefined;
                              tempStallFeeds.splice(i, 1, targetStallFeed);
                              setEditingFeed({
                                ...editingFeed,
                                stallFeeds: tempStallFeeds,
                              });
                            }}
                            slotProps={{
                              textField: {
                                size: "small",
                                InputProps: {
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <StopIcon
                                        color={
                                          editingFeed.stallFeeds?.[i]?.endTime
                                            ? "error"
                                            : "disabled"
                                        }
                                      />
                                    </InputAdornment>
                                  ),
                                },
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </Box>
                      {!editingFeed.stallFeeds?.[i]?.startTime ? (
                        <Button
                          sx={{ height: "25px" }}
                          variant="contained"
                          onClick={() => {
                            const tempStallFeeds = [
                              ...(editingFeed.stallFeeds ||
                                generateBlankObjectArray(stallCount || 0)),
                            ];
                            const targetStallFeed = {
                              ...(tempStallFeeds[i] || { stallNumber: i + 1 }),
                            };
                            targetStallFeed.startTime = new Date();
                            tempStallFeeds.splice(i, 1, { ...targetStallFeed });
                            setEditingFeed({
                              ...editingFeed,
                              stallFeeds: tempStallFeeds,
                            });
                          }}
                        >
                          Start Now
                        </Button>
                      ) : null}
                      {editingFeed.stallFeeds?.[i]?.startTime &&
                      !editingFeed.stallFeeds?.[i]?.endTime ? (
                        <Button
                          onClick={() => {
                            const tempStallFeeds = [
                              ...(editingFeed.stallFeeds ||
                                generateBlankObjectArray(stallCount || 0)),
                            ];
                            const targetStallFeed = {
                              ...(tempStallFeeds[i] || { stallNumber: i + 1 }),
                            };
                            targetStallFeed.endTime = new Date();
                            tempStallFeeds.splice(i, 1, targetStallFeed);
                            setEditingFeed({
                              ...editingFeed,
                              stallFeeds: tempStallFeeds,
                            });
                          }}
                          sx={{ height: "25px" }}
                          variant="contained"
                          color="error"
                        >
                          End Now
                        </Button>
                      ) : null}
                    </Stack>
                  </Paper>
                ))}
              </Box>
              {isAutoFillable ? (
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    onClick={filleEmptyStartTimesInBetween}
                    size="small"
                    variant="contained"
                    sx={{ height: "25px" }}
                  >
                    Fill empty start times in between
                  </Button>
                </Box>
              ) : null}
              {feed ? (
                <Button
                  disabled={!editingFeed.timestamp}
                  onClick={() => {
                    onSave && onSave(editingFeed);
                  }}
                  fullWidth
                  variant="contained"
                >
                  Save Feed
                </Button>
              ) : (
                <Button
                  disabled={!editingFeed.timestamp}
                  onClick={() => {
                    onAdd && onAdd(editingFeed);
                  }}
                  fullWidth
                  variant="contained"
                >
                  Save Feed
                </Button>
              )}
            </Stack>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
