/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from "@mui/material/Box";
import React from "react";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { IBarn } from "../../../types/barn";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import ProgressButton from "../Common/ProgressButton";
import farmService from "../../../service/farm.service";
import barnService from "../../../service/barn.service";
import { useAppContext } from "../../../contexts/useStorageContext";

export default function BarnFormDialog({
  farmId,
  barn,
  open,
  onClose,
  onUpdated,
  onNewCreated,
}: {
  farmId?: string;
  barn?: IBarn;
  open: boolean;
  onClose: () => void;
  onUpdated?: (updateInfo: IBarn) => void;
  onNewCreated?: (created: IBarn) => void;
}): React.ReactElement {
  const { state } = useAppContext();
  const { currentCompany } = state;
  const [editingBarn, setEditingBarn] = React.useState<IBarn>(barn || {});
  const [processing, setProcessing] = React.useState(false);

  const handleCreate = async () => {
    setProcessing(true);
    const result = await barnService.addNewBarn(editingBarn.name || "", farmId);
    if (result?.data) {
      onNewCreated && onNewCreated(result.data);
    }
    setProcessing(false);
  };

  const handleUpdate = async () => {
    if (!currentCompany?._id || !farmId || !editingBarn.name) return;
    setProcessing(true);
    const result = await barnService.updateBarn(currentCompany?._id || "", farmId || "", editingBarn);
    if (result?.data) {
      onUpdated && onUpdated(editingBarn);
    }
    setProcessing(false);
  };

  return (
    <>
      <Dialog open={open} maxWidth="xs">
        <Box sx={{ background: "white", width: "1000px", maxWidth: "100%" }}>
          <Box p={2} pb={0} sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">{barn ? "Barn Details" : "New Barn"}</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box p={2}>
            <Box>
              <Stack spacing={3}>
                <TextField
                  value={editingBarn.name || ""}
                  onChange={(e) => setEditingBarn({ ...editingBarn, name: e.target.value })}
                  placeholder="Barn Name"
                  fullWidth
                  size="small"
                />
              </Stack>
              <Box mt={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
                {barn ? (
                  <ProgressButton
                    onClick={() => !processing && handleUpdate()}
                    title="Save Changes"
                    inProgress={processing}
                    size="small"
                  />
                ) : (
                  <ProgressButton
                    disabled={!editingBarn.name || !farmId}
                    onClick={() => !processing && handleCreate()}
                    title="Create Barn"
                    inProgress={processing}
                    size="small"
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
