/* eslint-disable @typescript-eslint/no-explicit-any */
import { IBarn, ITemperatureGroup } from "../types/barn";
import backendApi from "./backendApi";
import { showSnackbar } from "./snackbar.service";

const routePrefix = "barn";

export default {
  async saveBarnTempRecord(
    companyId: string,
    barnId: string,
    date: string,
    payload: ITemperatureGroup
  ): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/save-barn-temp`, {
        companyId,
        barnId,
        date,
        temp: payload,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "Barn temperature record is successfully saved"
      );
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async fetchBarns(farmId: string, companyId?: string): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/all`, {
        farmId,
        companyId,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async addNewBarn(name: string, farmId?: string): Promise<any> {
    if (!farmId) return;
    try {
      const result = await backendApi()?.post(`${routePrefix}/create`, {
        name,
        farmId,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "New farm successfully created"
      );
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async updateBarn(
    companyId: string,
    farmId: string,
    barn: IBarn
  ): Promise<any> {
    if (!farmId) return;
    try {
      const result = await backendApi()?.post(`${routePrefix}/update-barn`, {
        companyId,
        barn,
        farmId,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "Farm is successfully updated"
      );
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },

  async deleteBarns(companyId: string, barnIds: string[]): Promise<any> {
    if (!barnIds) return;
    try {
      const result = await backendApi()?.post(`${routePrefix}/delete-barns`, {
        companyId,
        barnIds,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "Successfully deleted"
      );
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
};
