/* eslint-disable @typescript-eslint/no-explicit-any */
import { MenuItem, TextField } from "@mui/material";
import * as React from "react";
import { DietType } from "../../../types/diet";

interface IDietTypeSelectProps {
  label?: string;
  placeholder?: string;
  value?: DietType;
  onChange: (newVal?: DietType) => void;
  error?: boolean;
  helperText?: string;
}
export default function DietTypeSelect(props: IDietTypeSelectProps) {
  const { value, onChange, helperText, error } = props;
  return (
    <TextField
      label={props.label}
      select
      fullWidth
      placeholder="Select Diet Type"
      helperText={helperText}
      error={error}
      value={value}
      size="small"
      onChange={(e) => {
        onChange(e.target.value as unknown as DietType);
      }}
    >
      {Object.values(DietType).map((type, i) => (
        <MenuItem key={`comp-type-${i}`} value={type as DietType}>
          {type}
        </MenuItem>
      ))}
    </TextField>
  );
}
