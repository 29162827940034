/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import SearchIcon from "@mui/icons-material/Search";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import CloseIcon from "@mui/icons-material/Close";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import IconButton from "@mui/material/IconButton";
import { Tooltip } from "@mui/material";
import SimpleSearchInput from "./SimpleSearchInput";
import AddTableItemButton from "./AddIconButton";

const ChartIconButton = styled(IconButton)(() => ({
  borderRadius: "6px",
  width: "31px",
  height: "31px",
  background: "#e8f3ea",
  "&:hover": {
    backgroundColor: "#d4e5d8",
  },
}));

const TableTitle = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  fontSize: "20px",
  fontWeight: "500",
  borderBottom: "solid 1px #e1e1e1",
  paddingBottom: "12px",
}));

const TableItemsCount = styled(Box)(() => ({
  marginLeft: 8,
  marginRight: 8,
  fontSize: "18px",
  fontWeight: "500",
  opacity: "0.6",
}));

const TableTitleParentFilterInfo = styled(Box)(({ theme }) => ({
  marginLeft: 8,
  fontSize: "18px",
  fontWeight: "500",
  color: theme.palette.secondary.main,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}));

export default function TableControlHeader({
  searchTerm,
  onChangeSearchTerm,
  title,
  itemLength,
  selectedItemIds,
  parentsInfo,
  showChart,
  onClickAddButton,
  onClose,
}: {
  searchTerm?: string;
  onChangeSearchTerm?: (changed: string) => void;
  title: string;
  itemLength: number;
  selectedItemIds: string[];
  parentsInfo?: string;
  showChart?: (isCompare: boolean) => void;
  onClickAddButton?: () => void;
  onClose?: () => void;
}) {
  const [openSearch, setOpenSearch] = React.useState(false);
  return (
    <TableTitle>
      <AddTableItemButton
        onClick={() => onClickAddButton && onClickAddButton()}
      />
      {title}

      <TableItemsCount>({itemLength})</TableItemsCount>

      {parentsInfo ? <>of</> : null}
      {parentsInfo ? (
        <TableTitleParentFilterInfo>{parentsInfo}</TableTitleParentFilterInfo>
      ) : null}
      <Box sx={{ flex: 1 }} />
      {false && selectedItemIds.length > 1 ? (
        <>
          <Tooltip title="View Average Chart" placement="top" arrow>
            <ChartIconButton
              onClick={() => showChart && showChart(false)}
              size="small"
              color="primary"
              sx={{ mr: "5px" }}
            >
              <ShowChartIcon />
            </ChartIconButton>
          </Tooltip>
          {/* <Tooltip title="View Compare Chart" placement="top" arrow>
            <ChartIconButton size="small" color="primary">
              <StackedLineChartIcon />
            </ChartIconButton>
          </Tooltip> */}
          {/* <Button
            onClick={() => showChart(false)}
            size="small"
            variant="contained"
            startIcon={<TrendingUpIcon />}
            sx={{ mr: 1 }}
          >
            Average
          </Button>
          <Button
            onClick={() => showChart(true)}
            size="small"
            variant="contained"
            startIcon={<SignalCellularAltIcon />}
          >
            Compare
          </Button> */}
        </>
      ) : false && selectedItemIds.length == 1 ? (
        <Tooltip title="View Chart" placement="top" arrow>
          <ChartIconButton size="small" color="primary">
            <TrendingUpIcon />
          </ChartIconButton>
        </Tooltip>
      ) : null}
      {openSearch ? (
        <>
          <Box pl={1} sx={{ pr: "3px", display: "flex", alignItems: "center" }}>
            <SimpleSearchInput
              height={33}
              size="small"
              placeholder="Search..."
              value={searchTerm || ""}
              onChange={(e) =>
                onChangeSearchTerm && onChangeSearchTerm(e.target.value)
              }
              onClear={() => onChangeSearchTerm && onChangeSearchTerm("")}
            />
            <IconButton
              onClick={() => {
                setOpenSearch(false);
                onChangeSearchTerm && onChangeSearchTerm("");
              }}
              size="small"
              sx={{ ml: "3px" }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </>
      ) : (
        <IconButton onClick={() => setOpenSearch(true)} size="small">
          <SearchIcon />
        </IconButton>
      )}

      <IconButton onClick={() => onClose && onClose()} size="small">
        <VisibilityOffIcon />
      </IconButton>
    </TableTitle>
  );
}
