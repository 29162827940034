/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { ICowIntakeRecordModel } from "../../../types/cow";
import { useAppContext } from "../../../contexts/useStorageContext";

export default function CowIntakeTable({
  loading,
  rows,
}: {
  loading?: boolean;
  rows: ICowIntakeRecordModel[];
}): React.ReactElement {
  const { state } = useAppContext();
  const { currentCompany } = state;
  const columns = [
    {
      field: "date",
      headerName: "Date",
      width: 150,
      editable: false,
    },
    {
      field: "diet",
      headerName: "Diet",
      width: 150,
      editable: false,
      valueGetter: (params: any) => {
        return params.row.diet?.name;
      },
    },
    {
      field: "fedAmount",
      headerName: "Fed",
      width: 130,
      editable: false,
    },
    {
      field: "refusedAmount",
      headerName: "Refused",
      width: 130,
      editable: false,
    },
    {
      field: "durationSeconds",
      headerName: "Total Duration",
      width: 130,
      editable: false,
      valueGetter: (params: any) => {
        return `${((params.row.durationSeconds || 0) / 60).toFixed(1)} mins`;
      },
    },
    {
      field: "speed",
      headerName: "Eating Speed",
      width: 130,
      editable: false,
      valueGetter: (params: any) => {
        const mins = (params.row.durationSeconds || 0) / 60;
        return mins
          ? `${(
              ((params.row.fedAmount || 0) - (params.row.refusedAmount || 0)) /
              mins
            ).toFixed(1)} ${currentCompany?.weightUnit || "lbs"}/min`
          : "";
      },
    },
    {
      field: "dmPercentage",
      headerName: "DM (%)",
      width: 90,
      editable: false,
      valueGetter: (params: any) =>
        params.value ? Number(Number(params.value)?.toFixed(1)) : "",
    },
  ];

  return (
    <>
      <DataGridPro
        loading={loading}
        getRowId={(row) => row._id || ""}
        autoHeight
        rows={rows}
        columns={columns}
        disableSelectionOnClick
        rowHeight={40}
      />
    </>
  );
}
