/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, ListItem, TextField } from "@mui/material";
import * as React from "react";
import { GeneticProductionPotential } from "../../../types/cow";

interface IGeneticProductionPotentialSelectProps {
  label?: string;
  placeholder?: string;
  value?: GeneticProductionPotential;
  onChange: (newVal?: GeneticProductionPotential) => void;
  error?: boolean;
  helperText?: string;
  fullWidth?: boolean;
}
export default function GeneticProductionPotentialSelect(props: IGeneticProductionPotentialSelectProps) {
  const { value, onChange } = props;
  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        onChange && onChange(newValue || undefined);
      }}
      options={Object.values(GeneticProductionPotential)}
      renderOption={(props, option) => <ListItem {...props}>{option}</ListItem>}
      renderInput={(params: any) => (
        <TextField
          {...params}
          label="Genetic Production Potential"
          fullWidth={props?.fullWidth || true}
          placeholder="Genetic Production Potential"
          size="small"
          sx={{ minWidth: "200px" }}
        />
      )}
    />
  );
}
