/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { IFarm } from "../../../types/farm";
import ProgressButton from "../../components/Common/ProgressButton";
import farmService from "../../../service/farm.service";

export default function FarmDetailForm({
  companyId,
  farm,
  onNewCreated,
  onUpdated,
}: {
  companyId?: string;
  farm?: IFarm;
  onNewCreated?: (created: IFarm) => void;
  onUpdated?: (updates: IFarm) => void;
}) {
  const [editingFarm, setEditingFarm] = React.useState<IFarm>({});
  const [processing, setProcessing] = React.useState(false);

  const handleCreate = async () => {
    setProcessing(true);
    const result = await farmService.addNewFarm(editingFarm, companyId);
    if (result?.data) {
      onNewCreated && onNewCreated(result.data);
    }
    setProcessing(false);
  };

  const handleUpdate = async () => {
    // setProcessing(true);
    // const updateInfo = { ...editingFarm };
    // delete updateInfo._id;
    // const result = await farmService.updateFarm(updateInfo);
    // if (result?.data) {
    //   onUpdated && onUpdated(updateInfo);
    // }
    // setProcessing(false);
  };

  React.useEffect(() => {
    if (farm) {
      setEditingFarm(farm);
    }
  }, [farm]);
  return (
    <Box>
      <Stack spacing={3}>
        <TextField
          value={editingFarm.name || ""}
          onChange={(e) => setEditingFarm({ ...editingFarm, name: e.target.value })}
          placeholder="Farm Name"
          fullWidth
          size="small"
        />
        <TextField
          value={editingFarm.address || ""}
          onChange={(e) => setEditingFarm({ ...editingFarm, address: e.target.value })}
          placeholder="Address"
          fullWidth
          size="small"
        />
      </Stack>
      <Box mt={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
        {farm ? (
          <ProgressButton onClick={() => !processing && handleUpdate()} title="Save Changes" inProgress={processing} />
        ) : (
          <ProgressButton onClick={() => !processing && handleCreate()} title="Create Farm" inProgress={processing} />
        )}
      </Box>
    </Box>
  );
}
