import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

export const InfoTag = styled(Box)(() => ({
  background: "#e9e9e9",
  padding: "2px 5px",
  borderRadius: "5px",
  marginRight: "5px",
  display: "flex",
  alignItems: "center",
  fontSize: "13px",
}));
