/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from "@mui/material/Box";
import React from "react";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { FarmContext } from "../../../pages/farms/FarmContext";
import { useAppContext } from "../../../../contexts/useStorageContext";
import intakeService from "../../../../service/intake.service";
import { showSnackbar } from "../../../../service/snackbar.service";
import { getIntakeRecordDateString } from "../../../../helpers/formatTime";
import dayjs, { Dayjs } from "dayjs";
import { PickersShortcutsItem } from "@mui/x-date-pickers/PickersShortcuts";
import { DateRange } from "@mui/x-date-pickers-pro";
import { sortBy } from "lodash";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { CircularProgress, Switch } from "@mui/material";
import { ICow, ICowIntakeRecordModel } from "../../../../types/cow";
import CowIntakeTable from "../CowIntakeTable";
import CowIntakesChart from "../CowIntakeOverviewChart";

const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  {
    label: "Current Month",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("month"), today.endOf("month")];
    },
  },
  {
    label: "Last Month",
    getValue: () => {
      const currentDate = new Date();

      // Set the date to the 1st day of the current month
      const firstDayOfCurrentMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );

      // Subtract 1 day from the 1st day of the current month to get the last day of the previous month
      const lastDayOfPreviousMonth = new Date(firstDayOfCurrentMonth);
      lastDayOfPreviousMonth.setDate(firstDayOfCurrentMonth.getDate() - 1);

      // Set the date to the 1st day of the previous month
      const firstDayOfPreviousMonth = new Date(lastDayOfPreviousMonth);
      firstDayOfPreviousMonth.setDate(1);
      return [dayjs(firstDayOfPreviousMonth), dayjs(lastDayOfPreviousMonth)];
    },
  },
  {
    label: "Last 100 Days",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(100, "day"), today];
    },
  },
  {
    label: "This Year",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("year"), today.endOf("year")];
    },
  },
  {
    label: "Last 365 Days",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(365, "day"), today];
    },
  },
];

export default function CowIntakeOverviewDialog({
  cow,
  open,
  onClose,
}: {
  cow: ICow;
  open: boolean;
  onClose: () => void;
}): React.ReactElement {
  const { state } = useAppContext();
  const { currentCompany } = state;
  const farmContext = React.useContext(FarmContext);
  const [loading, setLoading] = React.useState(false);
  const [records, setRecords] = React.useState<ICowIntakeRecordModel[]>([]);
  const [fromDate, setFromDate] = React.useState(
    getIntakeRecordDateString(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    )
  );
  const [toDate, setToDate] = React.useState(
    getIntakeRecordDateString(new Date())
  );
  const [isTable, setIsTable] = React.useState(false);
  const [chartComponentKey, setChartComponentIndex] = React.useState(0);
  const [isDmCalculated, setIsDmCalculated] = React.useState(false);

  const fetchCowRecords = async () => {
    try {
      if (!currentCompany?._id || !cow?._id) return;
      setLoading(true);
      const result = await intakeService.getCowRecordsByDateRange(
        currentCompany._id,
        cow._id || "",
        fromDate,
        toDate
      );
      if (!result) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "Intake Fetch API error"
        );
        setLoading(false);
        return;
      }
      setRecords(sortBy(result.data, (val) => val.date));
      setLoading(false);
    } catch (error) {
      showSnackbar("danger", "Error", "Intake Fetch API error");
      setLoading(false);
    }
  };

  const dmCalculatedRecords = React.useCallback(() => {
    if (!isDmCalculated) return records;
    return records.map((record) => ({
      ...record,
      fedAmount: record.fedAmount
        ? (record.fedAmount * (record.dmPercentage || 100)) / 100
        : undefined,
      refusedAmount: record.refusedAmount
        ? (record.refusedAmount * (record.dmPercentage || 100)) / 100
        : undefined,
    }));
  }, [records, isDmCalculated]);

  React.useEffect(() => {
    if (!fromDate || !toDate) return;
    fetchCowRecords();
  }, [fromDate, toDate]);

  React.useEffect(() => {
    setChartComponentIndex(chartComponentKey + 1);
  }, [records, isDmCalculated]);

  return (
    <>
      <Dialog open={open} maxWidth="xl">
        <Box sx={{ background: "white", width: "1000px", maxWidth: "100%" }}>
          <Box p={2} pb={0} sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              sx={{ display: "flex", alignItems: "flex-end", mr: 2 }}
            >
              <Box
                sx={{
                  display: { xs: "none", sm: "flex" },
                  alignItems: "flex-end",
                }}
              >
                Intake Overview
                <Box sx={{ mb: "2px", opacity: 0.7, fontSize: "16px", mx: 1 }}>
                  of
                </Box>
              </Box>
              <Box sx={{ color: "secondary.main" }}>{cow.name}</Box>
            </Typography>
            <Box sx={{ minWidth: "260px", mr: 3 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateRangePicker
                  slots={{ field: SingleInputDateRangeField }}
                  slotProps={{
                    textField: { size: "small", fullWidth: true },
                    shortcuts: {
                      items: shortcutsItems,
                    },
                  }}
                  value={[dayjs(fromDate), dayjs(toDate)]}
                  onChange={(val) => {
                    if (val[0]) {
                      setFromDate(getIntakeRecordDateString(val[0]?.toDate()));
                    }
                    if (val[1]) {
                      setToDate(getIntakeRecordDateString(val[1]?.toDate()));
                    }
                  }}
                />
              </LocalizationProvider>
            </Box>
            <RadioGroup
              row
              value={isTable}
              onChange={(e, val) => setIsTable(val == "true")}
            >
              <FormControlLabel
                value="false"
                control={<Radio size="small" />}
                label="Chart"
              />
              <FormControlLabel
                value="true"
                control={<Radio size="small" />}
                label="Table"
              />
            </RadioGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={isDmCalculated}
                  onChange={(e, checked) => setIsDmCalculated(checked)}
                />
              }
              label="DM"
            />
            <Box sx={{ flex: 1 }} />
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          {loading && (
            <Box
              sx={{
                height: "300px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}

          {isTable ? (
            <Box p={2}>
              <CowIntakeTable loading={loading} rows={dmCalculatedRecords()} />
            </Box>
          ) : null}

          {!isTable && records.length ? (
            <Box p={2}>
              <CowIntakesChart
                key={chartComponentKey}
                intakeRecords={dmCalculatedRecords()}
              />
            </Box>
          ) : null}

          {!isTable && !records.length ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "300px",
              }}
            >
              No Records...
            </Box>
          ) : null}
        </Box>
      </Dialog>
    </>
  );
}
