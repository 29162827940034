import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";

export default function AddIconButton({ onClick }: { onClick: () => void }) {
  return (
    <IconButton
      onClick={onClick}
      sx={{
        borderRadius: "5px",
        backgroundColor: "#f1f1f1",
        mr: 1,
        width: "30px",
        height: "30px",
        transform: "scale(1)",
        transition: "0.2s",
        "&:hover": {
          backgroundColor: "primary.main",
          color: "white",
          transform: "scale(1.1)",
        },
      }}
    >
      <AddIcon />
    </IconButton>
  );
}
