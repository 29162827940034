/* eslint-disable @typescript-eslint/no-explicit-any */
import { SampleRecordFilterParams } from "../types/common";
import {
  ISampleRecordBaseFields,
  ISampleRecordCreationPayload,
  ISampleRecordPopulated,
} from "../types/datalake/sample";
import backendApi from "./backendApi";
import { showSnackbar } from "./snackbar.service";

const routePrefix = "datalake/sample-record";

interface GetDataParams extends SampleRecordFilterParams {
  page?: number;
  pageSize?: number;
  onlySpectra?: boolean;
  notIncludeSpectra?: boolean;
}
export default {
  async saveSampleRecords(
    companyId: string,
    newRecords: ISampleRecordCreationPayload[],
    dataField: string,
    fileName?: string
  ): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/bulk-save`, {
        newRecords,
        companyId,
        dataField,
        fileName,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "Successfully saved"
      );
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },

  async getData({
    companyId,
    page,
    pageSize,
    searchTerm,
    chemicalFilters,
    minUid,
    maxUid,
    onlySpectra,
    uids,
    notIncludeSpectra,
    sampleSource,
    sortModel,
  }: GetDataParams): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/get`, {
        page,
        pageSize,
        searchTerm,
        chemicalFilters,
        minUid,
        maxUid,
        companyId,
        uids,
        onlySpectra,
        notIncludeSpectra,
        sampleSource,
        sortModel,
      });
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async searchSamplesBySampleId(
    companyId: string,
    searchTerm: string
  ): Promise<ISampleRecordPopulated[]> {
    try {
      const result = await backendApi()?.post(
        `${routePrefix}/search-sample-id`,
        { searchTerm, companyId }
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
      }
      return [];
    }
  },
  async getFieldsData(
    filters: GetDataParams & { fields: string[] }
  ): Promise<any> {
    try {
      const result = await backendApi()?.post(
        `${routePrefix}/get-fields-data`,
        filters
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async getLastSSIDs(companyId: string): Promise<any> {
    try {
      const result = await backendApi()?.get(
        `${routePrefix}/get-last-company-uid`,
        { params: { companyId } }
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async checkSampleExistence(
    companyId: string,
    sampleNumbers: string[],
    dataField?: string
  ): Promise<any> {
    try {
      const result = await backendApi()?.post(
        `${routePrefix}/check-sample-existence`,
        { sampleNumbers, companyId, dataField }
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async updateRecord(uid: number, updateData: ISampleRecordBaseFields) {
    try {
      const result = await backendApi()?.post(`${routePrefix}/update-record`, {
        uid,
        updateData,
      });
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async updateRecordProperty(uid: number, fieldName: string, newValue: any) {
    try {
      const result = await backendApi()?.post(
        `${routePrefix}/update-record-property`,
        {
          uid,
          fieldName,
          newValue,
        }
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async delete(uids: number[]) {
    try {
      const result = await backendApi()?.post(`${routePrefix}/delete`, {
        uids,
      });
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
};
