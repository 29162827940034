import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { ICow } from "../../../types/cow";

export default function CowAutoComplete({
  cows,
  label,
  selectedId,
  value,
  onChange,
  fullWidth = false,
}: {
  cows?: ICow[];
  label?: string;
  selectedId?: string;
  value?: ICow;
  onChange?: (selected: ICow | null | undefined) => void;
  fullWidth?: boolean;
}) {
  return (
    <Autocomplete
      value={value || (cows || []).find((o) => o._id && selectedId && o._id == selectedId) || null}
      onChange={(event, newValue) => {
        onChange && onChange(newValue);
      }}
      getOptionLabel={(option) => option.name || ""}
      options={cows || []}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          fullWidth={fullWidth}
          placeholder="Cow"
          size="small"
          sx={{ minWidth: "200px" }}
        />
      )}
    />
  );
}
