/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from "@mui/material/Box";
import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Dialog from "@mui/material/Dialog";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { Button, Paper, TextField } from "@mui/material";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { IReportPopulated } from "../../../types/report";
import ReportTypeSelect from "./ReportTypeSelect";
import ProgressButton from "../Common/ProgressButton";
import TaskIcon from "@mui/icons-material/Task";
import { useAppContext } from "../../../contexts/useStorageContext";
import AnyAssetFileUploader from "../Common/AnyAssetFileUploader";
import reportService from "../../../service/report.service";
import { IFarm } from "../../../types/farm";
import { IBarn } from "../../../types/barn";
import { IPen } from "../../../types/pen";
import { getFileNameFromAssetUrl } from "../../../helpers/utils";
import { getIntakeRecordDateString } from "../../../helpers/formatTime";
import dayjs from "dayjs";
import { ICow } from "../../../types/cow";

export default function ReportFormDialog({
  farm,
  barn,
  pen,
  open,
  onClose,
  report,
  onCreated,
  onUpdated,
  cow,
}: {
  cow?: ICow;
  farm?: IFarm;
  barn?: IBarn;
  pen?: IPen;
  open: boolean;
  report?: IReportPopulated;
  onClose: () => void;
  onCreated: (created: IReportPopulated) => void;
  onUpdated?: (updated: IReportPopulated) => void;
}): React.ReactElement {
  const { state } = useAppContext();
  const { currentCompany } = state;

  const [editingReport, setEditingReport] = React.useState<IReportPopulated>(
    report || {
      company: currentCompany,
      cow: cow,
      farm: farm,
      barn: barn,
      pen: pen,
      date: getIntakeRecordDateString(new Date()),
    }
  );
  const [processing, setProcessing] = React.useState(false);
  const [openUploadDialog, setOpenUploadDialog] = React.useState(false);

  const handleCreate = async () => {
    if (
      !editingReport?.description ||
      !editingReport.type ||
      !currentCompany?._id
    )
      return;
    setProcessing(true);
    const result = await reportService.addNewReport(
      editingReport,
      currentCompany?._id
    );
    if (result) {
      onCreated && onCreated(result);
    }
    setProcessing(false);
  };

  const handleUpdate = async () => {
    if (
      !editingReport.description ||
      !editingReport.type ||
      !currentCompany?._id
    )
      return;
    setProcessing(true);
    const result = await reportService.updateReport(
      currentCompany?._id || "",
      editingReport
    );
    if (result?.data) {
      onUpdated && onUpdated(editingReport);
    }
    setProcessing(false);
  };

  return (
    <>
      <Dialog open={open} maxWidth="xs">
        <Box sx={{ background: "white", width: "1000px", maxWidth: "100%" }}>
          <Box
            p={2}
            pb={0}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography variant="h6">
              {report ? "Report Information" : "New Report"}
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box p={2}>
            <Stack spacing={3}>
              <ReportTypeSelect
                label="Report Type"
                value={editingReport.type}
                onChange={(val) =>
                  setEditingReport({ ...editingReport, type: val })
                }
              />
              <TextField
                label="Short Title"
                value={editingReport.reportTitle || ""}
                onChange={(e) =>
                  setEditingReport({
                    ...editingReport,
                    reportTitle: e.target.value,
                  })
                }
                fullWidth
                size="small"
              />
              <TextField
                multiline
                minRows={4}
                label="Details"
                value={editingReport.description || ""}
                onChange={(e) =>
                  setEditingReport({
                    ...editingReport,
                    description: e.target.value,
                  })
                }
                fullWidth
                size="small"
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date"
                  value={dayjs(editingReport.date)}
                  onChange={(newValue) =>
                    setEditingReport({
                      ...editingReport,
                      date: getIntakeRecordDateString(newValue?.toDate()),
                    })
                  }
                  slotProps={{ textField: { size: "small" } }}
                />
              </LocalizationProvider>
            </Stack>
            {editingReport.docUrls?.length ? (
              <Box mt={2}>
                {editingReport.docUrls?.map((docUrl) => (
                  <Paper
                    sx={{
                      px: 1,
                      color: "primary.main",
                      display: "flex",
                      alignItems: "center",
                      border: "solid 1px #eee",
                      textDecoration: "underline",
                      marginBottom: "3px",
                    }}
                  >
                    <TaskIcon sx={{ mr: 1 }} />
                    <a href={docUrl} target="_blank">
                      {getFileNameFromAssetUrl(docUrl)}
                    </a>
                    <div style={{ flex: 1 }} />
                    <IconButton
                      onClick={() =>
                        setEditingReport({
                          ...editingReport,
                          docUrls: (editingReport.docUrls || []).filter(
                            (url) => url != docUrl
                          ),
                        })
                      }
                      size="small"
                      sx={{ ml: 1 }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Paper>
                ))}
              </Box>
            ) : null}
            <Box
              mt={3}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                onClick={() => setOpenUploadDialog(true)}
                size="small"
                startIcon={<ReceiptLongIcon />}
                color="inherit"
                variant="outlined"
              >
                Add Document
              </Button>

              {report ? (
                <ProgressButton
                  onClick={() => !processing && handleUpdate()}
                  title="Save Changes"
                  inProgress={processing}
                  size="small"
                />
              ) : (
                <ProgressButton
                  disabled={
                    !editingReport.reportTitle ||
                    !editingReport.description ||
                    !editingReport.type
                  }
                  onClick={() => !processing && handleCreate()}
                  title="Create"
                  inProgress={processing}
                  size="small"
                />
              )}
            </Box>
          </Box>
          {openUploadDialog && (
            <AnyAssetFileUploader
              open
              onClose={() => setOpenUploadDialog(false)}
              onSave={(url) => {
                setEditingReport({
                  ...editingReport,
                  docUrls: [...(editingReport.docUrls || []), url],
                });
                setOpenUploadDialog(false);
              }}
            />
          )}
        </Box>
      </Dialog>
    </>
  );
}
