/* eslint-disable @typescript-eslint/no-explicit-any */
import backendApi from "./backendApi";
import { showSnackbar } from "./snackbar.service";

const routePrefix = "breed";

export default {
  async fetchBreedsByFarmIdBarnId(companyId?: string): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/all`, { companyId });
      if (result?.status != 200) {
        showSnackbar("danger", "Error", result?.data.message || "API error response");
        return null;
      }
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar("danger", "Error", error?.response?.data?.message || "API error response");
        return null;
      }
    }
  },
  async addNewBreed(name: string, description?: string, companyId?: string): Promise<any> {
    if (!name) return;
    try {
      const result = await backendApi()?.post(`${routePrefix}/create`, { name, description, companyId });
      if (result?.status != 200) {
        showSnackbar("danger", "Error", result?.data.message || "API error response");
        return null;
      }
      showSnackbar("success", "Success", result?.data.message || "New breed successfully created");
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar("danger", "Error", error?.response?.data?.message || "API error response");
        return null;
      }
    }
  },
};
