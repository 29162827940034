import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";

const StyledTooltip = styled(Stack)(() => ({
  fontSize: "15px",
  display: "flex",
  alignItems: "center",
  "& .record": {
    padding: "5px",
    "& .title": {
      fontWeight: "600",
      marginBottom: "8px",
    },
    "& .info": {
      display: "flex",
      alignItems: "center",
      marginTop: "5px",
      "& .label": {
        opacity: "0.5",
        marginRight: "5px",
      },
      "& .value": {
        opacity: "0.8",
      },
    },
  },
}));

interface intakeData {
  title: string;
  fed: number;
  refused: number;
}

export default function TrendArrowTooltip({
  before,
  now,
}: {
  before: intakeData;
  now: intakeData;
}) {
  return (
    <StyledTooltip p={1} direction="row" spacing={2}>
      <div className="record">
        <div className="title">{before.title}</div>
        <div className="info">
          <div className="label">Fed: </div>
          <div className="value">{before.fed}</div>
        </div>
        <div className="info">
          <div className="label">Refused: </div>
          <div className="value">{before.refused}</div>
        </div>
        <div className="info">
          <div className="label">Actual Intake: </div>
          <div className="value">{before.fed - before.refused}</div>
        </div>
      </div>
      <div className="record">
        <div className="title">Today</div>
        <div className="info">
          <div className="label">Fed: </div>
          <div className="value">{now.fed}</div>
        </div>
        <div className="info">
          <div className="label">Refused: </div>
          <div className="value">{now.refused}</div>
        </div>
        <div className="info">
          <div className="label">Actual Intake: </div>
          <div className="value">{now.fed - now.refused}</div>
        </div>
      </div>
    </StyledTooltip>
  );
}
