import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { IPen } from "../../../types/pen";
import penService from "../../../service/pen.service";

export default function PenAutoComplete({
  label,
  selectedId,
  farmId,
  value,
  onChange,
  fullWidth = false,
}: {
  label?: string;
  selectedId?: string;
  farmId: string;
  value?: IPen;
  onChange?: (selected: IPen | null | undefined) => void;
  fullWidth?: boolean;
}) {
  const [options, setOptions] = React.useState<IPen[]>([]);
  const fetchOptions = async () => {
    const result = await penService.fetchPensByFarmIdBarnId(farmId, []);
    setOptions(result?.data || []);
    if (selectedId) {
      onChange && onChange(result?.data?.find((p: IPen) => p._id == selectedId));
    }
  };

  React.useEffect(() => {
    fetchOptions();
  }, []);
  return (
    <Autocomplete
      value={value || null}
      onChange={(event, newValue) => {
        onChange && onChange(newValue);
      }}
      getOptionLabel={(option) => option.name || ""}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          fullWidth={fullWidth}
          placeholder="Select Pen"
          size="small"
          sx={{ minWidth: "200px" }}
        />
      )}
    />
  );
}
