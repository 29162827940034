/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import { ICow } from "../../../types/cow";
import Box from "@mui/material/Box";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import IconButton from "@mui/material/IconButton";
import GeneralPopoverWrapper from "../Common/GeneralPopoverWrapper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CowFormDialog from "./CowFormDialog";
import { useAppContext } from "../../../contexts/useStorageContext";
import CowIntakeOverviewDialog from "./CowIntakeOverviewDialog";
import cowService from "../../../service/cow.service";
import ConfirmDialog from "../Common/ConfirmDialog";
import TableControlHeader from "../Common/TableControlHeader";
import { IPen } from "../../../types/pen";
import { IBarn } from "../../../types/barn";
import ReportsDialog from "../Report/ReportsDialog";

export default function CowTable({
  loading,
  cows,
  selectedIds,
  onChangeSelectedIds,
  onChangeCows,
  selectedPens,
  selectedBarns,
  onClose,
}: {
  loading?: boolean;
  cows: ICow[];
  selectedIds: string[];
  onChangeSelectedIds: (selected: string[]) => void;
  onChangeCows?: (changedCows: ICow[]) => void;
  selectedPens?: IPen[];
  selectedBarns?: IBarn[];
  onClose?: () => void;
}) {
  const { state } = useAppContext();
  const { currentFarm, currentCompany } = state;
  const [targetCow, setTargetCow] = React.useState<ICow>();
  const [openCowEditDialog, setOpenCowEditDialog] = React.useState(false);
  const [openIntakeOverviewDialog, setOpenIntakeOverviewDialog] =
    React.useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] =
    React.useState(false);
  const [tableProcessing, setTableProcessing] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [openReportsDialog, setOpenReportsDialog] = React.useState(false);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 150,
      editable: false,
    },
    {
      field: "breed",
      headerName: "Breed",
      width: 150,
      editable: false,
      valueGetter: (params: any) => params.value?.name,
    },
    {
      field: "actions",
      headerName: "",
      width: 10,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
      renderCell: (params: any) => {
        return (
          <Box>
            <GeneralPopoverWrapper
              closeOnClickContent
              triggerElement={
                <IconButton size="small">
                  <MoreVertIcon />
                </IconButton>
              }
              popoverContent={
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      setTargetCow(params.row);
                      setOpenCowEditDialog(true);
                    }}
                  >
                    <ListItemIcon>
                      <EditIcon />
                    </ListItemIcon>
                    <ListItemText>Edit Cow Info</ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setTargetCow(params.row);
                      setOpenIntakeOverviewDialog(true);
                    }}
                  >
                    <ListItemIcon>
                      <TrendingUpIcon />
                    </ListItemIcon>
                    <ListItemText>Intake Overview</ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setTargetCow(params.row);
                      setOpenReportsDialog(true);
                    }}
                  >
                    <ListItemIcon>
                      <NoteAltIcon />
                    </ListItemIcon>
                    <ListItemText>Reports</ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setTargetCow(params.row);
                      setOpenDeleteConfirmDialog(true);
                    }}
                    sx={{ color: "error.main" }}
                  >
                    <ListItemIcon>
                      <DeleteIcon color="error" />
                    </ListItemIcon>
                    <ListItemText>Delete Cow</ListItemText>
                  </MenuItem>
                </MenuList>
              }
            />
          </Box>
        );
      },
    },
  ];

  const deleteCows = async (cowId: string) => {
    setOpenDeleteConfirmDialog(false);
    setTableProcessing(true);
    await cowService.deleteCows(currentCompany?._id || "", [cowId]);
    onChangeCows && onChangeCows(cows.filter((c) => c._id != cowId));
    setTableProcessing(false);
  };

  return (
    <Box>
      <TableControlHeader
        searchTerm={searchTerm}
        onChangeSearchTerm={(term) => setSearchTerm(term)}
        title="Cows"
        itemLength={cows.length}
        selectedItemIds={selectedIds}
        parentsInfo={
          selectedPens?.length
            ? selectedPens?.length == 1
              ? selectedPens?.[0].name
              : `${selectedPens.length} Pens`
            : selectedBarns?.length
            ? selectedBarns.length == 1
              ? selectedBarns[0].name
              : `${selectedBarns.length} Barns`
            : ""
        }
        onClickAddButton={() => {
          setTargetCow(undefined);
          setOpenCowEditDialog(true);
        }}
        onClose={onClose}
      />
      <DataGridPro
        loading={loading || tableProcessing}
        getRowId={(row) => row._id || ""}
        autoHeight
        rows={cows.filter(
          (c) =>
            (!searchTerm ||
              c.name?.toLowerCase()?.includes(searchTerm.toLowerCase())) &&
            ((!selectedBarns?.length && !selectedPens?.length) ||
              (!selectedPens?.length &&
                selectedBarns?.map((b) => b._id).includes(c.barn?._id)) ||
              selectedPens?.map((p) => p._id).includes(c.pen?._id))
        )}
        columns={columns}
        disableSelectionOnClick
        checkboxSelection
        selectionModel={selectedIds}
        onSelectionModelChange={(selected) => {
          onChangeSelectedIds(selected as string[]);
        }}
        initialState={{ pinnedColumns: { right: ["actions"] } }}
      />
      {openCowEditDialog && currentFarm?._id ? (
        <CowFormDialog
          farmId={currentFarm?._id || ""}
          open
          onClose={() => setOpenCowEditDialog(false)}
          cow={targetCow}
          onUpdated={(updatedCow) => {
            const tempCows = [...cows];
            const targetIndex = tempCows.findIndex(
              (c) => c._id == updatedCow._id
            );
            if (targetIndex > -1) {
              tempCows.splice(targetIndex, 1, updatedCow);
              onChangeCows && onChangeCows(tempCows);
            }
            if (targetCow?._id == updatedCow?._id) {
              setTargetCow(updatedCow);
            }
          }}
          onNewCreated={(created) => {
            onChangeCows && onChangeCows([created, ...cows]);
            setOpenCowEditDialog(false);
          }}
        />
      ) : null}
      {openIntakeOverviewDialog && targetCow ? (
        <CowIntakeOverviewDialog
          open
          onClose={() => setOpenIntakeOverviewDialog(false)}
          cow={targetCow}
        />
      ) : null}
      {openDeleteConfirmDialog && targetCow ? (
        <ConfirmDialog
          title={<>Confirm Deleting Cow</>}
          content={
            <Box
              sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
            >
              Are you sure you want to delete
              <Box
                sx={{ color: "warning.main", px: 1 }}
              >{` ${targetCow.name}`}</Box>
              ?
            </Box>
          }
          onConfirm={() => deleteCows(targetCow._id || "")}
          onCancel={() => setOpenDeleteConfirmDialog(false)}
        />
      ) : null}
      {openReportsDialog && targetCow ? (
        <ReportsDialog
          cow={targetCow}
          open
          onClose={() => setOpenReportsDialog(false)}
        />
      ) : null}
    </Box>
  );
}
