/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAppContext } from "../../../contexts/useStorageContext";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { debounce } from "lodash";
import { ISampleRecordPopulated } from "../../../types/datalake/sample";
import sampleRecordService from "../../../service/sampleRecord.service";

const InputThresholdBeforeSearch = 1;
export interface ISampleIdAutocompleteBaseProps {
  label?: string;
  onSelectValues: (ids: ISampleRecordPopulated[]) => void;
  value?: ISampleRecordPopulated[];
  error?: boolean;
  helperText?: any;
  companyId?: string;
}
type OmittedAutocompleteProps =
  | "value"
  | "onChange"
  | "renderInput"
  | "options";
const NewOptionPrefix = "New Sample ID: ";

export type ISampleIdAutocompleteProps = ISampleIdAutocompleteBaseProps &
  Omit<
    AutocompleteProps<ISampleRecordPopulated, true, false, true>,
    OmittedAutocompleteProps
  >;

export default function SampleMultipleAutocomplete({
  value,
  onSelectValues,
  fullWidth = false,
  label,
  companyId,
  ...otherProps
}: ISampleIdAutocompleteProps) {
  const [open, toggleOpen] = React.useState(false);
  const { state } = useAppContext();
  const { loggedInUserInfo } = state;
  const [options, setOptions] = React.useState<ISampleRecordPopulated[]>([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [searching, setSearching] = React.useState(false);

  const handleClose = () => {
    toggleOpen(false);
  };

  async function search(searchText: string): Promise<void> {
    if (searchText.length < InputThresholdBeforeSearch) return;
    setSearching(true);
    try {
      const result = await sampleRecordService.searchSamplesBySampleId(
        companyId || "",
        searchText
      );
      if (result?.length) {
        setOptions(result);
      } else {
        setOptions([]);
      }
    } catch (error) {
      setSearching(false);
    }
    setSearching(false);
  }

  const debouncedSearch = debounce(search, 300);

  const handleSelectOption = (val: ISampleRecordPopulated[]) => {
    onSelectValues(val);
  };

  return (
    <>
      <Autocomplete<ISampleRecordPopulated, true, false, true>
        multiple={true}
        value={value}
        {...otherProps}
        options={options}
        clearOnBlur
        onChange={(e, v) => {
          handleSelectOption(v as ISampleRecordPopulated[]);
        }}
        getOptionLabel={(option) =>
          (option as ISampleRecordPopulated)?.sampleNumber || ""
        }
        renderOption={(props, option) => {
          return (
            <ListItem {...props} sx={{ p: 0, borderTop: "solid 1px #ddd" }}>
              <Box>{option?.sampleNumber || ""}</Box>
            </ListItem>
          );
        }}
        renderInput={(params) => (
          <Box
            sx={{ display: "flex", alignItems: "center", position: "relative" }}
          >
            <TextField
              {...params}
              label={label}
              fullWidth
              placeholder={otherProps.placeholder}
              size="small"
              error={otherProps.error}
              helperText={otherProps.helperText}
            />
          </Box>
        )}
        onInputChange={(
          event: React.ChangeEvent<unknown>,
          searchText: string,
          reason: "input" | "reset" | "clear"
        ) => {
          if (
            searchText.length >= InputThresholdBeforeSearch &&
            reason === "input"
          ) {
            debouncedSearch(searchText);
            setSearchTerm(searchText);
          }
        }}
        filterOptions={(options, params) => {
          return options;
        }}
        popupIcon={
          searching ? (
            <CircularProgress size={25} color="primary" />
          ) : (
            <ExpandMoreIcon />
          )
        }
      />
    </>
  );
}
