/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from "@mui/material/Box";
import * as React from "react";
import ReactApexChart from "react-apexcharts";

export enum RecordsMiniChartContext {
  RFI = "RFI",
  PH = "PH",
  LooseFeed = "Loose Feed",
}

export default function RecordsMiniChart({
  context,
  expectedAmount,
  records,
  id,
}: {
  context: RecordsMiniChartContext;
  expectedAmount?: number;
  id: string;
  records: {
    averagePh: number;
    fedAmount: number;
    refusedAmount: number;
    date: string;
    looseFeedAmount?: number;
  }[];
}) {
  const [miniChartOptions] = React.useState<ApexCharts.ApexOptions>({
    series: [
      {
        name: context,
        data: records?.map(
          (r) =>
            (context == RecordsMiniChartContext.RFI
              ? Number(((r.fedAmount || 0) - (r.refusedAmount || 0)).toFixed(1))
              : context == RecordsMiniChartContext.PH
              ? r.averagePh
              : context == RecordsMiniChartContext.LooseFeed
              ? r.looseFeedAmount
              : null) || null
        ),
        color: "#05dd9f",
      },
    ],
    chart: {
      type: "line",
      id: `mini-chart-${id}`,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: context == RecordsMiniChartContext.PH ? 0 : 1,
    },
    xaxis: {
      categories: records?.map((r) => r.date || ""),
      type: "datetime",
      axisBorder: {
        show: false,
      },
      labels: {
        show: false,
      },
      tickAmount: "dataPoints",
    },
    yaxis: {
      show: false,
    },
    legend: {
      show: false,
    },
    grid: {
      show: false,
    },
    annotations: {
      yaxis: [
        ...(context == RecordsMiniChartContext.RFI && expectedAmount
          ? [
              {
                y: expectedAmount,
                borderColor: "#000",
              },
            ]
          : []),
      ],
      points: [
        ...(context == RecordsMiniChartContext.PH
          ? records
              .filter((r) => r.averagePh)
              .map((r) => {
                return {
                  x: new Date(r.date as string).getTime(),
                  y: r.averagePh,
                  marker: {
                    size: 2,
                    radius: 0,
                    fillColor: "#fff",
                    strokeColor: "#19daa3",
                    strokeWidth: 3,
                  },
                };
              })
          : []),
      ],
    },
  });

  return (
    <Box>
      <ReactApexChart
        options={miniChartOptions}
        series={miniChartOptions.series}
        type={"line"}
        height={70}
      />
    </Box>
  );
}
