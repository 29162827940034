/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";
import {
  excelSerialNumberToDate,
  excelTimeFractionToDate,
  fTime,
  getIntakeRecordDateString,
} from "../../helpers/formatTime";
import { DataGridPro } from "@mui/x-data-grid-pro/DataGridPro";
import { useAppContext } from "../../contexts/useStorageContext";
import { FarmContext } from "../pages/farms/FarmContext";
import intakeService from "../../service/intake.service";
import ProgressButton from "./Common/ProgressButton";

export default function IntakeDataImportDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const { state } = useAppContext();
  const farmContext = React.useContext(FarmContext);
  const { currentCompany } = state;
  const [isInvalidJson, setIsInvalidJson] = React.useState(false);
  const [parsedRows, setParsedRows] = React.useState<any[]>([]);
  const [headerCells, setHeaderCells] = React.useState<string[]>([]);
  const [processing, setProcessing] = React.useState(false);

  const columns = headerCells.map((header) => ({
    field: header,
    headerName: header,
    width: 150,
    editable: false,
    valueGetter: (params: any) => {
      if (
        header?.toLowerCase()?.includes("day") ||
        header?.toLowerCase()?.includes("date")
      ) {
        return getIntakeRecordDateString(params.value);
      } else if (
        header?.toLowerCase()?.includes("time") ||
        header?.toLowerCase()?.includes("pushup")
      ) {
        return fTime(params.value);
      } else {
        return params.value;
      }
    },
  }));

  const handleImport = async () => {
    const dataForDb = parsedRows.map((row) => {
      const penNameKey =
        Object.keys(row).find((k) => k.toLowerCase() == "pen") || "";
      const dateKey =
        Object.keys(row).find(
          (k) => k.toLowerCase() == "day" || k.toLowerCase() == "date"
        ) || "";
      const dietKey =
        Object.keys(row).find(
          (k) => k.toLowerCase() == "status" || k.toLowerCase() == "diet"
        ) || "";
      const fedAmountKey =
        Object.keys(row).find((k) => k.toLowerCase().startsWith("fed")) || "";
      const refusedAmountKey =
        Object.keys(row).find((k) => k.toLowerCase().startsWith("ref")) || "";
      const feedTimeKeys = Object.keys(row).filter(
        (k) =>
          k.toLowerCase().includes("time") && k.toLowerCase().includes("fed")
      );
      const pushTimeKeys = Object.keys(row).filter((k) =>
        k.toLowerCase().includes("pushup")
      );

      const pen = farmContext.pens.find(
        (p) =>
          p.name?.toLowerCase() == row[penNameKey]?.toString()?.toLowerCase()
      );
      const diet = farmContext.diets.find(
        (d) => d.name?.toLowerCase() == row[dietKey]?.toString()?.toLowerCase()
      );
      const cowCount = farmContext.cows.filter(
        (c) => c.pen?._id == pen?._id
      )?.length;
      const feeds = feedTimeKeys.map((feedTimeKey) => {
        return {
          timestamp: new Date(row[feedTimeKey]),
          diet: diet?._id,
        };
      });
      const pushups = pushTimeKeys.map((pushTimeKey) => {
        return {
          timestamp: new Date(row[pushTimeKey]),
        };
      });

      return {
        company: currentCompany?._id,
        pen: pen,
        date: getIntakeRecordDateString(row[dateKey]),
        diet: diet,
        fedAmount: Number(Number(row[fedAmountKey])?.toFixed(1)),
        refusedAmount: Number(Number(row[refusedAmountKey])?.toFixed(1)),
        cowAverageFedAmount:
          cowCount && row[fedAmountKey]
            ? Number(Number(row[fedAmountKey] / cowCount).toFixed(1))
            : undefined,
        cowAverageRefusedAmount:
          cowCount && row[refusedAmountKey]
            ? Number(Number(row[refusedAmountKey] / cowCount).toFixed(1))
            : undefined,
        feeds: feeds,
        pushUps: pushups,
      };
    });
    setProcessing(true);
    const result = await intakeService.saveMultipleDayIntakeData(
      currentCompany?._id || "",
      dataForDb
    );
    setProcessing(false);
    if (result) {
      onClose();
    }
  };

  const onDrop = React.useCallback((acceptedFiles: any[]) => {
    if (!acceptedFiles?.length) return;
    try {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          setIsInvalidJson(false);
          const data = e?.target?.result;
          if (!data) {
            setIsInvalidJson(true);
            return;
          }

          // Use xlsx library to parse the data
          const workbook = XLSX.read(data, { type: "binary" });
          const dailyPenIntakes: any = [];
          workbook.SheetNames.map((sheetName) => {
            const sheet = workbook.Sheets[sheetName];

            // Convert sheet data to JSON
            let jsonData = XLSX.utils.sheet_to_json(sheet, {
              header: 1,
              dateNF: "mm/dd/yyyy", // Specify the date format
            });
            jsonData = jsonData.map((itemArray: any) =>
              itemArray.map((item: any) => {
                return typeof item == "string" ? item.trim() : item;
              })
            );
            const headerRow: string[] = jsonData.reduce(
              (prev: any, row: any) => (row.length > prev.length ? row : prev),
              []
            ) as string[];
            setHeaderCells(headerRow as string[]);
            const headerIndex = jsonData.findIndex((row) => row === headerRow);
            const rows: any[] = jsonData.slice(headerIndex + 1);

            const rowsAsObjects = rows.map((row: any[]) => {
              const rowObject: any = {};
              headerRow.forEach((header: string, index) => {
                if (
                  header?.toLowerCase()?.includes("day") ||
                  header?.toLowerCase()?.includes("date")
                ) {
                  rowObject[header] = new Date(
                    excelSerialNumberToDate(row[index])
                  );
                } else if (
                  header?.toLowerCase()?.includes("time") ||
                  header?.toLowerCase()?.includes("pushup")
                ) {
                  const time = excelTimeFractionToDate(row[index]);
                  const date: Date = Object.values(rowObject).find(
                    (o) => o instanceof Date
                  ) as Date;
                  rowObject[header] = new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    date.getDate(),
                    time.getHours(),
                    time.getMinutes(),
                    time.getSeconds(),
                    time.getMilliseconds()
                  );
                } else {
                  rowObject[header] = row[index];
                }
              });
              return rowObject;
            });
            dailyPenIntakes.push(
              ...rowsAsObjects.filter((r) => Object.values(r)?.[0] != undefined)
            );
          });
          setParsedRows(
            dailyPenIntakes.map((r: any, i: number) => ({ ...r, id: i }))
          );
        } catch (error) {
          setIsInvalidJson(true);
        }
      };

      reader.readAsBinaryString(acceptedFiles[0]);
    } catch (error) {
      setIsInvalidJson(true);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <Dialog open={open} maxWidth={parsedRows ? "xl" : false}>
      <Box p={1} pb={3} sx={{ background: "white" }}>
        <Box
          px={2}
          pt={1}
          pb={1}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Typography variant="h6">Import Intake Data</Typography>
          <IconButton onClick={onClose} sx={{ marginTop: "-10px" }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          px={2}
          sx={{
            width: parsedRows?.length ? "1200px" : "500px",
            maxWidth: "100%",
            maxHeight: "700px",
            overflow: "auto",
          }}
        >
          {isInvalidJson && (
            <Typography color="error" fontWeight={500} mt={1}>
              Invalid JSON file
            </Typography>
          )}
          {!parsedRows.length ? (
            <Box
              sx={{
                background: "#eee",
                border: "dashed 2px #bbb",
                height: "250px",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <p style={{ textAlign: "center" }}>
                Drag and drop file here, <br />
                or click to select file
              </p>
            </Box>
          ) : (
            <DataGridPro
              getRowId={(row) => row.id || ""}
              autoHeight
              rows={parsedRows}
              columns={columns}
              disableSelectionOnClick
            />
          )}
        </Box>
        {parsedRows?.length ? (
          <Box sx={{ display: "flex", justifyContent: "flex-end", pt: 2 }}>
            <ProgressButton
              inProgress={processing}
              onClick={() => handleImport()}
              size="small"
              variant="contained"
              title="Import Data"
            />
          </Box>
        ) : null}
      </Box>
    </Dialog>
  );
}
