/* eslint-disable @typescript-eslint/no-explicit-any */
import { IPen } from "../types/pen";
import backendApi from "./backendApi";
import { showSnackbar } from "./snackbar.service";

const routePrefix = "pen";

export default {
  async fetchPensByFarmIdBarnId(
    farmId: string,
    barnIds: string[]
  ): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/all`, {
        farmId,
        barnIds,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async addNewPen(farmId: string, barnId: string, pen: IPen): Promise<any> {
    if (!farmId || !barnId) return;
    try {
      const result = await backendApi()?.post(`${routePrefix}/create`, {
        farmId,
        barnId,
        pen,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "New pen successfully created"
      );
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async updatePen(companyId: string, farmId: string, pen: IPen): Promise<any> {
    if (!farmId || !pen) return;
    try {
      const result = await backendApi()?.post(`${routePrefix}/update-pen`, {
        companyId,
        farmId,
        pen,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "Pen is successfully updated"
      );
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async deletePens(companyId: string, penIds: string[]): Promise<any> {
    if (!penIds) return;
    try {
      const result = await backendApi()?.post(`${routePrefix}/delete-pens`, {
        companyId,
        penIds,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "Successfully deleted"
      );
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
};
