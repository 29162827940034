import * as React from "react";
import GeneralPopoverWrapper from "./GeneralPopoverWrapper";
import MenuList from "@mui/material/MenuList";
import DownloadIcon from "@mui/icons-material/Download";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { IDiet } from "../../../types/diet";
import { Box, IconButton, Tooltip } from "@mui/material";
import DietLogsPopover from "../diets/DietLogsPopover/DietLogsPopover";

export default function DietsListPopover({
  diets,
  triggerElement,
}: {
  diets: IDiet[];
  triggerElement: JSX.Element;
}) {
  return (
    <GeneralPopoverWrapper
      triggerElement={triggerElement}
      popoverContent={
        <MenuList sx={{ minWidth: "250px" }}>
          {diets.map((diet, i) => (
            <MenuItem
              key={`diet-${i}`}
              sx={{ borderTop: i != 0 ? "solid 1px #eee" : "" }}
            >
              <ListItemIcon>
                <MenuBookIcon />
              </ListItemIcon>
              <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
                {diet.name}
                <DietLogsPopover diet={diet} />
              </Box>
              {diet.docUrl ? (
                <Tooltip
                  title="Download Current Document"
                  placement="right"
                  arrow
                >
                  <a href={diet.docUrl} target="_blank">
                    <IconButton size="small">
                      <DownloadIcon color="info" />
                    </IconButton>
                  </a>
                </Tooltip>
              ) : null}
            </MenuItem>
          ))}
        </MenuList>
      }
    />
  );
}
