import Box from "@mui/material/Box";
import * as React from "react";
import SimpleSearchInput from "../../../components/Common/SimpleSearchInput";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import PenAutoComplete from "./PenAutocomplete";
import { IPen } from "../../../../types/pen";
import { IBarn } from "../../../../types/barn";
import { IconButton } from "@mui/material";
import MultipleBarnAutoComplete from "./BarnAutocomplete";
import IntakeDataImportDialog from "../../../components/IntakeDataImportDialog";

const FilterButton = styled(IconButton)(() => ({
  borderRadius: "6px",
  background: "#fff",
  border: "solid 1px #bbb",
  width: "36px",
  height: "36px",
}));

export enum ViewSwitchValue {
  Barn = "Barns",
  Pen = "Pens",
  Cow = "Cows",
}

export default function SwitchAndFilterPanel({
  freeSearchTerm,
  onChangeFreeSearchTerm,
  selectedBarns,
  onChangeSelectedBarns,
  barns,
  pens,
  selectedPens,
  onChangeSelectedPens,
  currentViews,
  onChangeCurrentViews,
}: {
  freeSearchTerm: string;
  onChangeFreeSearchTerm: (changed: string) => void;
  selectedBarns: IBarn[] | undefined;
  barns: IBarn[];
  onChangeSelectedBarns: (selected: IBarn[] | undefined) => void;
  pens: IPen[];
  selectedPens: IPen[] | undefined;
  onChangeSelectedPens: (selected: IPen[] | undefined) => void;
  currentViews: ViewSwitchValue[];
  onChangeCurrentViews: (changedVals: ViewSwitchValue[]) => void;
}) {
  const [showFilters, setShowFilters] = React.useState(false);
  const [openIntakeImportDialog, setOpenIntakeImportDialog] =
    React.useState(false);

  const handleClickViewItem = (itemVal: ViewSwitchValue) => {
    if (currentViews.includes(itemVal)) {
      onChangeCurrentViews(currentViews.filter((t) => t != itemVal));
    } else {
      onChangeCurrentViews([...currentViews, itemVal]);
    }
  };
  return (
    <Box mt={2}>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <Box
          mr={{ sm: 2 }}
          sx={{
            mb: 2,
            width: { xs: "100%", sm: "unset" },
            display: { xs: "flex", sm: "block" },
            justifyContent: "space-between",
          }}
        >
          <ButtonGroup
            aria-label="button group"
            sx={{ "& button": { px: 2, height: "40px" } }}
          >
            {Object.values(ViewSwitchValue).map((val) => (
              <Button
                key={`view-item-${val}`}
                onClick={() => handleClickViewItem(val)}
                color={currentViews.includes(val) ? "secondary" : "inherit"}
                sx={{
                  color: currentViews.includes(val) ? "secondary.main" : "#bbb",
                  background: currentViews.includes(val) ? "#ffe7db" : "#fff",
                }}
              >
                {val}
              </Button>
            ))}
          </ButtonGroup>
          <FilterButton
            onClick={() => setShowFilters(!showFilters)}
            sx={{ display: { sm: "none" } }}
          >
            {showFilters ? <ExpandLessIcon /> : <ManageSearchIcon />}
          </FilterButton>
        </Box>
        <Box
          mr={{ sm: 1 }}
          mb={showFilters ? 1 : 0}
          sx={{
            transition: "0.2s",
            overflow: "hidden",
            height: { xs: showFilters ? "40px" : "0px", sm: "unset" },
            width: { xs: "100%", sm: "unset" },
          }}
        >
          <MultipleBarnAutoComplete
            options={barns}
            value={selectedBarns}
            onChange={(selected) => {
              onChangeSelectedBarns(selected || undefined);
            }}
          />
        </Box>
        <Box
          mr={{ sm: 1 }}
          mb={showFilters ? 1 : 0}
          sx={{
            transition: "0.2s",
            overflow: "hidden",
            height: { xs: showFilters ? "40px" : "0px", sm: "unset" },
            width: { xs: "100%", sm: "unset" },
          }}
        >
          <PenAutoComplete
            options={pens}
            value={selectedPens}
            onChange={(selected) => onChangeSelectedPens(selected || undefined)}
          />
        </Box>
        <Box
          mb={showFilters ? 1 : 0}
          sx={{
            transition: "0.2s",
            overflow: "hidden",
            height: { xs: showFilters ? "40px" : "0px", sm: "unset" },
            width: { xs: "100%", sm: "unset" },
          }}
        >
          <SimpleSearchInput
            value={freeSearchTerm}
            onChange={(e) => onChangeFreeSearchTerm(e.target.value)}
            onClear={() => onChangeFreeSearchTerm("")}
            size="small"
            placeholder="Free Search..."
          />
        </Box>
        <Box sx={{ flex: 1 }} />
        <Box>
          {/* <Button onClick={() => setOpenIntakeImportDialog(true)} size="small" variant="contained">
            Import Intake Data
          </Button> */}
        </Box>
        {openIntakeImportDialog && (
          <IntakeDataImportDialog
            open
            onClose={() => setOpenIntakeImportDialog(false)}
          />
        )}
      </Box>
    </Box>
  );
}
