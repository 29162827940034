import { Divider } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";

interface ConfirmDialogProps {
  expanded?: boolean;
  title?: React.ReactElement;
  content?: React.ReactElement;
  onConfirmText?: React.ReactElement;
  onConfirm: () => void;
  onCancel: () => void;
}

function ConfirmDialog(props: ConfirmDialogProps): React.ReactElement {
  const { expanded, title, content, onConfirmText, onConfirm, onCancel } = {
    ...props,
  };

  return (
    <Dialog open maxWidth={expanded ? "md" : "sm"} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      {content && (
        <>
          <Divider />
          <DialogContent>{content}</DialogContent>
          <Divider />
        </>
      )}
      <DialogActions style={{ marginBottom: 10, marginRight: 10 }}>
        <Button className="cancel-button" variant="text" onClick={onCancel}>
          Cancel
        </Button>

        <Button color="primary" variant="contained" onClick={onConfirm}>
          {onConfirmText ? onConfirmText : "Confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
