import React from "react";
import { IBarn } from "../../../types/barn";
import { IPen } from "../../../types/pen";
import { ICow } from "../../../types/cow";
import { IDiet } from "../../../types/diet";

export interface IFarmContext {
  barns: IBarn[];
  pens: IPen[];
  cows: ICow[];
  diets: IDiet[];
}

export const FarmContext = React.createContext<IFarmContext>({ barns: [], pens: [], cows: [], diets: [] });
