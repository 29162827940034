import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { WeatherType } from "../../../types/dailyIntake";
import ListItem from "@mui/material/ListItem";
import { ListItemIcon } from "@mui/material";

export default function WeatherAutocomplete({
  value,
  onChange,
  fullWidth = false,
  label,
}: {
  value?: WeatherType;
  onChange?: (selected: WeatherType | null) => void;
  fullWidth?: boolean;
  label?: string;
}) {
  return (
    <Autocomplete
      value={value}
      fullWidth={fullWidth}
      onChange={(event, newValue) => {
        onChange && onChange(newValue);
      }}
      renderOption={(props, option) => (
        <ListItem {...props}>
          <ListItemIcon>
            <img
              src={`/img/icons/weather/${option}.png`}
              style={{ width: "25px", height: "25px" }}
            />
          </ListItemIcon>
          {option}
        </ListItem>
      )}
      options={Object.values(WeatherType)}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth={fullWidth}
          label={label}
          size="small"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <React.Fragment>
                {params.InputProps.startAdornment}
                {value ? (
                  <img
                    src={`/img/icons/weather/${value}.png`}
                    style={{ width: "25px", height: "25px" }}
                  />
                ) : null}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
