/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGridPro } from "@mui/x-data-grid-pro";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import { IBarn } from "../../../types/barn";
import Box from "@mui/material/Box";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from "@mui/material";
import GeneralPopoverWrapper from "../Common/GeneralPopoverWrapper";
import { useAppContext } from "../../../contexts/useStorageContext";
import BarnDailyRecordDialog from "./BarnDailyRecordDialog";
import BarnFormDialog from "./BarnFormDialog";
import ConfirmDialog from "../Common/ConfirmDialog";
import barnService from "../../../service/barn.service";
import ReportsDialog from "../Report/ReportsDialog";
import TableControlHeader from "../Common/TableControlHeader";
import PenFormDialog from "../pen/PenFormDialog";
import { IPen } from "../../../types/pen";

export default function BarnTable({
  loading,
  barns,
  selectedIds,
  onChangeSelectedIds,
  onChangeBarns,
  onClose,
  onAddNewPen,
}: {
  loading?: boolean;
  barns: IBarn[];
  selectedIds: string[];
  onChangeSelectedIds: (selected: string[]) => void;
  onChangeBarns?: (changedBarns: IBarn[]) => void;
  onClose?: () => void;
  onAddNewPen?: (created: IPen) => void;
}) {
  const { state } = useAppContext();
  const { currentFarm, currentCompany } = state;
  const [openPenDialog, setOpenPenDialog] = React.useState(false);
  const [targetBarn, setTargetBarn] = React.useState<IBarn>();
  const [openDailyRecordDialog, setOpenDailyRecordDialog] =
    React.useState(false);
  const [openBarnDialog, setOpenBarnDialog] = React.useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] =
    React.useState(false);
  const [tableProcessing, setTableProcessing] = React.useState(false);
  const [openReportsDialog, setOpenReportsDialog] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 150,
      editable: false,
    },
    {
      field: "actions",
      headerName: "",
      width: 10,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
      renderCell: (params: any) => {
        return (
          <Box>
            <GeneralPopoverWrapper
              closeOnClickContent
              triggerElement={
                <IconButton size="small">
                  <MoreVertIcon />
                </IconButton>
              }
              popoverContent={
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      setTargetBarn(params.row);
                      setOpenBarnDialog(true);
                    }}
                  >
                    <ListItemIcon>
                      <EditIcon />
                    </ListItemIcon>
                    <ListItemText>Edit Barn Info</ListItemText>
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      setTargetBarn(params.row);
                      setOpenPenDialog(true);
                    }}
                  >
                    <ListItemIcon>
                      <AddCircleOutlineIcon />
                    </ListItemIcon>
                    <ListItemText>Add New Pen</ListItemText>
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      setTargetBarn(params.row);
                      setOpenDailyRecordDialog(true);
                    }}
                  >
                    <ListItemIcon>
                      <ThermostatIcon />
                    </ListItemIcon>
                    <ListItemText>Temperature Record</ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setTargetBarn(params.row);
                      setOpenReportsDialog(true);
                    }}
                  >
                    <ListItemIcon>
                      <NoteAltIcon />
                    </ListItemIcon>
                    <ListItemText>Reports</ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setTargetBarn(params.row);
                      setOpenDeleteConfirmDialog(true);
                    }}
                    sx={{ color: "error.main" }}
                  >
                    <ListItemIcon>
                      <DeleteIcon color="error" />
                    </ListItemIcon>
                    <ListItemText>Delete Barn</ListItemText>
                  </MenuItem>
                </MenuList>
              }
            />
          </Box>
        );
      },
    },
  ];

  const deleteBarns = async (barnId: string) => {
    setOpenDeleteConfirmDialog(false);
    setTableProcessing(true);
    await barnService.deleteBarns(currentCompany?._id || "", [barnId]);
    onChangeBarns && onChangeBarns(barns.filter((b) => b._id != barnId));
    setTableProcessing(false);
  };
  return (
    <Box>
      <TableControlHeader
        searchTerm={searchTerm}
        onChangeSearchTerm={(term) => setSearchTerm(term)}
        title="Barns"
        itemLength={barns.length}
        selectedItemIds={selectedIds}
        onClickAddButton={() => {
          setTargetBarn(undefined);
          setOpenBarnDialog(true);
        }}
        onClose={onClose}
      />
      <DataGridPro
        loading={loading || tableProcessing}
        getRowId={(row) => row._id || ""}
        checkboxSelection
        autoHeight
        rows={barns.filter(
          (b) =>
            !searchTerm ||
            b.name?.toLowerCase()?.includes(searchTerm.toLowerCase())
        )}
        columns={columns}
        disableSelectionOnClick
        selectionModel={selectedIds}
        onSelectionModelChange={(selected) => {
          onChangeSelectedIds(selected as string[]);
        }}
        initialState={{ pinnedColumns: { right: ["actions"] } }}
      />
      {openDailyRecordDialog && currentFarm?._id && targetBarn ? (
        <BarnDailyRecordDialog
          barn={targetBarn}
          open
          onClose={() => setOpenDailyRecordDialog(false)}
        />
      ) : null}
      {openPenDialog && currentFarm?._id && targetBarn ? (
        <PenFormDialog
          farmId={currentFarm._id}
          barnId={targetBarn._id}
          open
          onClose={() => setOpenPenDialog(false)}
          onNewCreated={(created) => {
            onAddNewPen && onAddNewPen(created);
            setOpenPenDialog(false);
          }}
        />
      ) : null}
      {openBarnDialog && currentFarm?._id ? (
        <BarnFormDialog
          barn={targetBarn}
          farmId={currentFarm?._id}
          open
          onClose={() => setOpenBarnDialog(false)}
          onUpdated={(updatedBarn) => {
            const tempBarns = [...barns];
            const targetIndex = tempBarns.findIndex(
              (b) => b._id == updatedBarn._id
            );
            if (targetIndex > -1) {
              tempBarns.splice(targetIndex, 1, updatedBarn);
              onChangeBarns && onChangeBarns(tempBarns);
              setOpenBarnDialog(false);
            }
          }}
          onNewCreated={(created) => {
            onChangeBarns && onChangeBarns([created, ...barns]);
            setOpenBarnDialog(false);
          }}
        />
      ) : null}
      {openDeleteConfirmDialog && targetBarn ? (
        <ConfirmDialog
          title={<>Confirm Deleting Barn</>}
          content={
            <Box
              sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
            >
              Are you sure you want to delete
              <Box
                sx={{ color: "warning.main", px: 1 }}
              >{` ${targetBarn.name}`}</Box>
              ?
            </Box>
          }
          onConfirm={() => deleteBarns(targetBarn._id || "")}
          onCancel={() => setOpenDeleteConfirmDialog(false)}
        />
      ) : null}
      {openReportsDialog && targetBarn ? (
        <ReportsDialog
          barn={targetBarn}
          open
          onClose={() => setOpenReportsDialog(false)}
        />
      ) : null}
    </Box>
  );
}
