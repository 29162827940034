/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, ListItem, TextField } from "@mui/material";
import * as React from "react";
import { LactationState } from "../../../types/cow";

interface ILactationStateSelectProps {
  label?: string;
  placeholder?: string;
  value?: LactationState;
  onChange: (newVal?: LactationState) => void;
  error?: boolean;
  helperText?: string;
  fullWidth?: string;
}
export default function LactationStateSelect(props: ILactationStateSelectProps) {
  const { value, onChange } = props;
  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        onChange && onChange(newValue || undefined);
      }}
      options={Object.values(LactationState)}
      renderOption={(props, option) => <ListItem {...props}>{option}</ListItem>}
      renderInput={(params: any) => (
        <TextField
          {...params}
          fullWidth={props?.fullWidth || true}
          label="Lactation State"
          size="small"
          sx={{ minWidth: "200px" }}
        />
      )}
    />
  );
}
