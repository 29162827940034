import { ISampleRecordPopulated } from "./datalake/sample";

export enum DietType {
  TMR = "TMR",
  Pasture = "Pasture",
  Combo = "Combo",
  FreeChoice = "FreeChoice",
}

export interface IDietChangeLog {
  date?: string;
  description?: string;
  docUrl?: string;
}

export interface IDiet {
  _id?: string;
  name?: string;
  type?: DietType;
  description?: string;
  docUrl?: string;
  changeLogs?: IDietChangeLog[];
  samples?: ISampleRecordPopulated[];
}
