/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import IconButton from "@mui/material/IconButton";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Popover from "@mui/material/Popover";
import parseHtml from "html-react-parser";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuList from "@mui/material/MenuList";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import { INotification } from "../../../types/notification";
import notificationsService from "../../../service/notifications.service";
import { useAppContext } from "../../../contexts/useStorageContext";
import { actionTypes } from "../../../types/state";
import { MenuItem } from "@mui/material";

const MarkReadButton = styled(Button)(({ theme }) => ({
  height: "20px",
  fontSize: "13px",
  borderRadius: "100px",
  color: theme.palette.warning.main,
  border: "solid 1px",
  background: "#0000",
  transform: "scale(1)",
  transition: "0.2s",
  "&:hover": {
    transform: "scale(1.1)",
    color: "white",
    background: theme.palette.warning.main,
    border: "none",
  },
  "&:active": {
    transform: "scale(0.9)",
    color: "white",
    background: theme.palette.warning.main,
    border: "none",
  },
}));

export function NotificationIconButton() {
  const { dispatch, state } = useAppContext();
  const { notifications } = state;
  const [anchorElNoti, setAnchorElNoti] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenNotifications = React.useCallback(
    async (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElNoti(event.currentTarget);
      if (notifications?.length) {
        await notificationsService.read(notifications.map((n) => n._id));
        dispatch!({
          type: actionTypes.setNotifications,
          value: notifications.map((n) => ({ ...n, seen: true })),
        });
      }
    },
    [notifications]
  );
  const handleCloseNotiMenu = () => {
    setAnchorElNoti(null);
  };

  const fetchNotifications = async () => {
    const result = await notificationsService.get({});
    dispatch!({
      type: actionTypes.setNotifications,
      value: result?.data || [],
    });
  };

  React.useEffect(() => {
    fetchNotifications();
  }, []);
  return (
    <>
      <IconButton onClick={handleOpenNotifications}>
        <Badge
          badgeContent={notifications?.filter((n) => !n.seen).length || 0}
          color={
            notifications?.filter((n) => !n.seen).length ? "error" : "default"
          }
        >
          <NotificationsNoneIcon
            sx={{
              color: "button.black",
              fontSize: { xs: "30px", lg: "35px" },
            }}
          />
        </Badge>
      </IconButton>

      <Popover
        sx={{ mt: "45px" }}
        id="menu-appbar-noti-btn"
        anchorEl={anchorElNoti}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElNoti)}
        onClose={handleCloseNotiMenu}
      >
        <Box sx={{ width: 400 }}>
          <Box px={2} pt={1} pb={0}>
            <Typography fontSize={17}>{`Notifications`}</Typography>
          </Box>
          {notifications?.length ? (
            <Box>
              <MenuList sx={{ maxHeight: "400px", overflow: "auto" }}>
                {notifications.map((notification) => (
                  <Box
                    key={notification._id}
                    sx={{
                      borderTop: "solid 1px #ddd",
                      padding: "6px 14px",
                    }}
                  >
                    <Box
                      color={`${notification.type}.main`}
                      sx={{ fontSize: "15px", fontWeight: "500", mb: "4px" }}
                    >
                      {notification.title}
                    </Box>
                    <Box sx={{ fontSize: "14px", opacity: 0.8 }}>
                      {parseHtml(notification.description)}
                    </Box>
                  </Box>
                ))}
              </MenuList>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "200px",
                opacity: 0.5,
              }}
            >
              No notifications
            </Box>
          )}
        </Box>
      </Popover>
    </>
  );
}
