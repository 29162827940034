import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function Index() {
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", pt: 6 }}>
        <Typography variant="h4">Settings</Typography>
      </Box>
    </>
  );
}
