/* eslint-disable @typescript-eslint/no-explicit-any */
import backendApi from "./backendApi";
import { showSnackbar } from "./snackbar.service";

const routePrefix = "auth";

export default {
  async updateForgotPassword(email: string, code: string, password: string): Promise<any> {
    const result = await backendApi()?.post(`${routePrefix}/update-forgot-password`, {
      email,
      code,
      password,
    });
    if (result?.status != 200) {
      showSnackbar("danger", "Error", result?.data.message || "API error response");
      return null;
    }
    showSnackbar("success", "Success", "Password is updated");
    return result;
  },
  async submitVerifyCode(email: string, code: string): Promise<any> {
    const result = await backendApi()?.post(`${routePrefix}/update-verify-code`, {
      email,
      code,
    });
    if (result?.status != 200) {
      showSnackbar("danger", "Error", result?.data.message || "API error response");
      return null;
    }
    showSnackbar("success", "Success", "Verification code is submitted");
    return result;
  },
  async verifyEmail(email: string): Promise<any> {
    const result = await backendApi()?.post(`${routePrefix}/verify-email`, {
      email,
    });
    if (result?.status != 200) {
      showSnackbar("danger", "Error", result?.data.message || "API error response");
      return null;
    }
    showSnackbar("success", "Success", "Verification code is sent to your email");
    return result;
  },
  async signUp(fullname: string, email: string): Promise<any> {
    const result = await backendApi()?.post(`${routePrefix}/signup`, {
      fullname,
      email,
    });
    if (result?.status != 200) {
      showSnackbar("danger", "Error", result?.data.message || "API error response");
      return null;
    }
    showSnackbar("success", "Success", "Your account is successfully created");
    return result;
  },

  async login(email: string, password: string): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/login`, {
        password,
        email,
      });
      if (result?.status != 200) {
        return null;
      }
      showSnackbar("success", "Success", "Welcome to Intake-UI");
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        if (error?.response?.status == 403) {
          showSnackbar("warning", "Account not approved yet", "Please ask admin to approve your account creation");
        } else {
          showSnackbar("danger", "Error", error?.response?.data?.message || "API error response");
        }
        return null;
      }
    }
  },
};
