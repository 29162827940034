import * as React from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Box from "@mui/material/Box";

export default function TrendArrow({
  value,
  unit,
}: {
  value: number;
  unit: string;
}) {
  if (!value) return <>---</>;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        color: value < 0 ? "error.main" : value > 0 ? "success.main" : "#999",
      }}
    >
      {value < 0 && <ArrowDropDownIcon />}
      {value > 0 && <ArrowDropUpIcon />}

      {`${value} ${unit}`}
    </Box>
  );
}
