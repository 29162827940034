import * as React from "react";
import Chip from "@mui/material/Chip";
import GeneralPopoverWrapper from "../../Common/GeneralPopoverWrapper";
import Box from "@mui/material/Box";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { IDiet } from "../../../../types/diet";
import { InfoTag } from "../../Common/ListItemInfoTag";
import { fDate } from "../../../../helpers/formatTime";
import SampleMultipleAutocomplete from "../../datalake/SampleMultipleAutocomplete";
import { useAppContext } from "../../../../contexts/useStorageContext";
import { ISampleRecordPopulated } from "../../../../types/datalake/sample";
import ProgressButton from "../../Common/ProgressButton";

export default function DietSamplesPopover({
  diet,
  onAdd,
}: {
  diet: IDiet;
  onAdd: (added: ISampleRecordPopulated[]) => void;
}) {
  const { state } = useAppContext();
  const { myCompanyProfile } = state;
  const [addingSamples, setAddingSamples] = React.useState<
    ISampleRecordPopulated[]
  >([]);
  return (
    <GeneralPopoverWrapper
      triggerElement={
        <Chip
          variant="outlined"
          label={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              Samples:
              <Box
                sx={{
                  background: "#e2e2e2",
                  padding: "2px 9px",
                  borderRadius: "100px",
                  marginLeft: "5px",
                }}
              >{`${diet?.samples?.length || 0}`}</Box>
            </Box>
          }
          sx={{
            ml: 1,
            cursor: "pointer",
            "& .MuiChip-label": { pl: "5px !important", pr: 1 },
          }}
        />
      }
      popoverContent={
        <Box>
          <Box
            sx={{
              px: 2,
              py: 2,
              fontWeight: "500",
              display: "flex",
              minWidth: "300px",
            }}
          >
            <div style={{ flex: 1 }}>
              <SampleMultipleAutocomplete
                fullWidth
                value={addingSamples}
                onSelectValues={(selected) => {
                  setAddingSamples(selected);
                }}
                companyId={myCompanyProfile?._id}
                label="Find And Add Samples"
              />
            </div>
            <ProgressButton
              onClick={() => {
                onAdd(addingSamples);
                setAddingSamples([]);
              }}
              title="Add"
              sx={{ ml: 1 }}
            />
          </Box>
          {diet.samples?.map((sample, i) => (
            <Box
              key={i}
              sx={{
                display: "flex",
                alignItems: "center",
                py: 1,
                px: 2,
                minWidth: "350px",
                maxWidth: "350px",
                borderTop: "solid 1px #ddd",
              }}
            >
              <div style={{ flex: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <InfoTag>{sample.sampleNumber}</InfoTag>
                  {sample.sampleTakenDate ? (
                    <InfoTag>
                      <AccessTimeIcon sx={{ fontSize: "14px", mr: "2px" }} />
                      <div className="exact-date">
                        {fDate(sample.sampleTakenDate || "")}
                      </div>
                    </InfoTag>
                  ) : null}
                </Box>
              </div>
            </Box>
          ))}
        </Box>
      }
    />
  );
}
