import { IBarn } from "./barn";
import { ICompany } from "./company";
import { ICow } from "./cow";
import { IFarm } from "./farm";
import { IPen } from "./pen";

export enum ReportType {
  Vet = "Vet",
  HoofTrimmer = "Hoof Trimmer",
  Nutrition = "Nutrition",
  Other = "Other",
}

export interface IReportBase {
  _id?: string;
  reportTitle?: string;
  type?: ReportType;
  description?: string;
  docUrls?: string[];
  date?: string;
}

export interface IReport extends IReportBase {
  company?: string;
  farm?: string;
  barn?: string;
  pen?: string;
}

export interface IReportPopulated extends IReportBase {
  company?: ICompany;
  farm?: IFarm;
  barn?: IBarn;
  pen?: IPen;
  cow?: ICow;
}
