/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
// import Box from "@mui/material/Box";
import * as React from "react";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
// import Box from "@mui/material/Box";
import CompanyAutoComplete from "../../components/company/CompanyAutoComplete";
import WhitePanel from "../../components/Common/WhitePanel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Button, IconButton, Stack } from "@mui/material";
import { useAppContext } from "../../../contexts/useStorageContext";
import { ICompany } from "../../../types/company";
import { actionTypes } from "../../../types/state";
import farmService from "../../../service/farm.service";
import { IFarm } from "../../../types/farm";
import { UserRoles } from "../../../types/auth";
import companyService from "../../../service/company.service";
import FarmFormDialog from "./FarmFormDialog";
import SwitchAndFilterPanel, { ViewSwitchValue } from "./SwitchAndFilterPanel";
import { IBarn } from "../../../types/barn";
import { IPen } from "../../../types/pen";
import { ICow } from "../../../types/cow";
import BarnTable from "../../components/barn/BarnTable";
import PenTable from "../../components/pen/PenTable";
import CowTable from "../../components/cow/CowTable";
import { ChartTargetType } from "../../components/PenIntakesChart";
import BarnFormDialog from "../../components/barn/BarnFormDialog";
import barnService from "../../../service/barn.service";
import PenFormDialog from "../../components/pen/PenFormDialog";
import penService from "../../../service/pen.service";
import CowFormDialog from "../../components/cow/CowFormDialog";
import cowService from "../../../service/cow.service";
import { IFarmContext, FarmContext } from "./FarmContext";
import { IDiet } from "../../../types/diet";
import dietService from "../../../service/diet.service";

const TabsContainer = styled(Tabs)(() => ({
  position: "relative",
  minHeight: "30px",
  "& .MuiTabs-indicator": { display: "none" },
  "& .MuiSvgIcon-root": {
    color: "#444",
  },
  "& .MuiTabs-scroller": {
    overflow: "hidden",
  },
  "& .MuiTabs-scrollButtons": {
    opacity: "1",
    width: "45px",
    transition: "0.2s",
    position: "absolute",
    height: "40px",
    right: 0,
    justifyContent: "flex-end",
    zIndex: 2,
    backgroundImage: "linear-gradient(to right, #fff0, #ffff , #ffff, #ffff)",
    "& svg": { fontSize: "1.7rem" },
  },
  "& .MuiTabs-scrollButtons:nth-child(1)": {
    left: 0,
    justifyContent: "flex-start",
    backgroundImage: "linear-gradient(to left, #fff0, #ffff , #ffff, #ffff)",
  },
  "& .MuiTabs-scrollButtons.Mui-disabled": {
    width: "0px",
    opacity: 0,
  },
}));

const TabButton = styled(Tab)(({ theme }) => ({
  maxHeight: "38px",
  "&.Mui-selected": {
    background: theme.palette.primary.main,
    color: "white !important",
    border: "none !important",
  },
  border: "solid 1px",
  borderRadius: "100px",
  padding: "10px 20px",
  marginRight: "10px",
  minHeight: "30px",
  minWidth: "unset",
}));

export default function Index() {
  const { dispatch, state } = useAppContext();
  const { currentCompany, currentFarm, loggedInUserInfo, myCompanyProfile } =
    state;
  const [currentTabIndex, setCurrentTabIndex] = React.useState(-1);
  const [farms, setFarms] = React.useState<IFarm[]>([]);
  const [targetFarm, setTargetFarm] = React.useState<IFarm>();
  const [openFarmDialog, setOpenFarmDialog] = React.useState(false);
  const [currentViews, setCurrentViews] = React.useState<ViewSwitchValue[]>([
    ViewSwitchValue.Barn,
    ViewSwitchValue.Pen,
    ViewSwitchValue.Cow,
  ]);
  const [barns, setBarns] = React.useState<IBarn[]>([]);
  const [loadingBarns, setLoadingBarns] = React.useState(false);
  const [pens, setPens] = React.useState<IPen[]>([]);
  const [diets, setDiets] = React.useState<IDiet[]>([]);
  const [loadingPens, setLoadingPens] = React.useState(false);
  const [loadingCows, setLoadingCows] = React.useState(false);
  const [cows, setCows] = React.useState<ICow[]>([]);
  const [selectedBarns, setSelectedBarns] = React.useState<IBarn[]>([]);
  const [selectedPens, setSelectedPens] = React.useState<IPen[]>([]);
  const [selectedCows, setSelectedCows] = React.useState<ICow[]>([]);
  const [freeSearchTerm, setFreeSearchTerm] = React.useState("");
  const [isCompareCharts, setIsCompareCharts] = React.useState(false);
  const [chartTargetIds, setChartTargetIds] = React.useState<string[]>([]);
  const [chartTargetType, setChartTargetType] =
    React.useState<ChartTargetType>();
  const farmContext: IFarmContext = {
    barns,
    pens,
    cows,
    diets,
  };

  const handleOpenCharts = (
    targetType: ChartTargetType,
    targetIds: string[],
    isCompare: boolean
  ) => {
    setIsCompareCharts(isCompare);
    setChartTargetType(targetType);
    setChartTargetIds(targetIds);
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTabIndex(newValue);
    dispatch!({
      type: actionTypes.setCurrentFarm,
      value: farms[newValue] || undefined,
    });
  };

  const fetchCompanyFarms = async () => {
    if (!currentCompany?._id) return;
    const result = await farmService.farchAllCompanyFarms(currentCompany._id);
    if (!result?.data?.length) {
      setFarms([]);
    }
    setFarms(result.data || []);
    dispatch!({
      type: actionTypes.setCurrentFarm,
      value: result.data?.[0] || undefined,
    });
  };

  const fetchBarnsOfCurrentFarm = async () => {
    if (!currentFarm?._id) {
      setBarns([]);
      return;
    }
    setLoadingBarns(true);
    const result = await barnService.fetchBarns(
      currentFarm?._id,
      currentCompany?._id
    );
    if (!result?.data?.length) {
      setBarns([]);
    }
    setBarns(result.data || []);
    setLoadingBarns(false);
  };

  const fetchPensOfCurrentFarm = async () => {
    if (!currentFarm?._id) {
      setPens([]);
      return;
    }
    setLoadingPens(true);
    const result = await penService.fetchPensByFarmIdBarnId(
      currentFarm?._id,
      selectedBarns.map((b) => b._id || "")
    );
    if (!result?.data?.length) {
      setPens([]);
    }
    setPens(result.data || []);
    setLoadingPens(false);
  };

  const fetchCowsOfCurrentFarm = async () => {
    if (!currentFarm?._id) {
      setCows([]);
      return;
    }
    setLoadingCows(true);
    const result = await cowService.fetchCowsByFarmIdPenIds(
      currentFarm?._id,
      []
    );
    if (!result?.data?.length) {
      setCows([]);
    }
    setCows(result.data || []);
    setLoadingCows(false);
  };

  const fetchDiets = async () => {
    if (!currentCompany?._id) return;
    const result = await dietService.fetchDiets(currentCompany?._id);
    setDiets(result?.data || []);
  };

  const handleChangeCurrentCompany = (selected: ICompany | null) => {
    if (currentCompany?._id == selected?._id) return;
    dispatch!({
      type: actionTypes.setCurrentCompany,
      value: selected || undefined,
    });
  };

  const determineCurrentCompany = () => {
    setTimeout(async () => {
      if (currentCompany) return;
      if (loggedInUserInfo?.role == UserRoles.superadmin) {
        const result = await companyService.fetchCompanyShallowList();
        const companyList = result?.data || [];
        if (!companyList.length) return;
        const tempCurrentCompany = myCompanyProfile
          ? companyList.find((c: ICompany) => c._id == myCompanyProfile._id) ||
            companyList[0]
          : companyList[0];
        dispatch!({
          type: actionTypes.setCurrentCompany,
          value: tempCurrentCompany || undefined,
        });
      } else if (loggedInUserInfo?.company) {
        const result = await companyService.getMyCompany();
        if (!result.data) return;
        dispatch!({
          type: actionTypes.setCurrentCompany,
          value: result.data || undefined,
        });
      }
    }, 100);
  };

  React.useEffect(() => {
    fetchBarnsOfCurrentFarm();
    fetchPensOfCurrentFarm();
    fetchCowsOfCurrentFarm();
    fetchDiets();
  }, [currentFarm]);

  React.useEffect(() => {
    if (!farms.length) return;
    if (!currentFarm) {
      dispatch!({
        type: actionTypes.setCurrentFarm,
        value: farms[0] || undefined,
      });
    } else {
      setCurrentTabIndex(
        farms.findIndex((farm) => farm._id == currentFarm._id)
      );
    }
  }, [farms]);

  React.useEffect(() => {
    fetchCompanyFarms();
  }, [currentCompany]);

  React.useEffect(() => {
    determineCurrentCompany();
  }, []);

  return (
    <FarmContext.Provider value={farmContext}>
      <WhitePanel
        sx={{
          p: "10px !important",
          display: { xs: "block", sm: "flex" },
          alignItems: "center",
          mb: 2,
        }}
      >
        <CompanyAutoComplete
          value={currentCompany}
          onChange={(selected) => handleChangeCurrentCompany(selected)}
        />
        <Box sx={{ width: { xs: 0, sm: 10 } }} />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            pt: { xs: "12px", sm: 0 },
          }}
        >
          {farms.length ? (
            <IconButton
              onClick={() => setOpenFarmDialog(true)}
              sx={{ mr: 1, background: "#efefef" }}
              color="primary"
            >
              <AddIcon />
            </IconButton>
          ) : (
            <Button
              onClick={() => setOpenFarmDialog(true)}
              variant="outlined"
              sx={{ whiteSpace: "nowrap", borderRadius: 100 }}
              startIcon={<AddCircleOutlineIcon />}
            >
              Add New Farm
            </Button>
          )}
          {farms.length ? (
            <TabsContainer
              value={currentTabIndex}
              onChange={handleChangeTab}
              variant="scrollable"
              scrollButtons={true}
              allowScrollButtonsMobile
              aria-label="scrollable auto tabs example"
            >
              {farms.map((farm, i) => (
                <TabButton key={`farm-${i}`} label={farm.name} />
              ))}
            </TabsContainer>
          ) : null}
        </Box>
      </WhitePanel>
      <Box sx={{ mt: 1 }}>
        <SwitchAndFilterPanel
          freeSearchTerm={freeSearchTerm}
          onChangeFreeSearchTerm={(changed) => setFreeSearchTerm(changed)}
          barns={barns}
          selectedBarns={selectedBarns}
          onChangeSelectedBarns={(selected) => setSelectedBarns(selected || [])}
          pens={pens}
          selectedPens={selectedPens}
          onChangeSelectedPens={(selected) => setSelectedPens(selected || [])}
          currentViews={currentViews}
          onChangeCurrentViews={(changed) => setCurrentViews(changed)}
        />
      </Box>
      <Box sx={{ display: "flex", maxWidth: "100vw", overflow: "auto" }}>
        <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
          {currentViews.includes(ViewSwitchValue.Barn) ? (
            <WhitePanel
              sx={{
                flex: 1,
                minWidth: { xs: "98vw", sm: "unset" },
                width: { xs: "98vw", sm: "unset" },
              }}
            >
              <BarnTable
                loading={loadingBarns}
                selectedIds={selectedBarns?.map((b) => b._id || "") || []}
                onChangeSelectedIds={(selected) => {
                  const targetBarns = barns.filter((b) =>
                    selected.includes(b._id || "")
                  );
                  setSelectedBarns(targetBarns);
                }}
                barns={barns}
                onChangeBarns={(changedBarns) => {
                  setBarns(changedBarns);
                  const tempPens = [...pens];
                  tempPens.map((p) => {
                    p.barn = changedBarns.find((b) => b._id == p.barn?._id);
                  });
                  setPens(tempPens);
                }}
                onClose={() =>
                  setCurrentViews(
                    currentViews.filter((v) => v != ViewSwitchValue.Barn)
                  )
                }
                onAddNewPen={(createdPen) => setPens([createdPen, ...pens])}
              />
            </WhitePanel>
          ) : null}
          {currentViews.includes(ViewSwitchValue.Pen) ? (
            <WhitePanel
              sx={{
                flex: 1,
                minWidth: { xs: "98vw", sm: "unset" },
                width: { xs: "98vw", sm: "unset" },
              }}
            >
              <PenTable
                selectedBarns={selectedBarns}
                loading={loadingPens}
                selectedIds={selectedPens?.map((p) => p._id || "") || []}
                onChangeSelectedIds={(selected) => {
                  const targetPens = pens.filter((p) =>
                    selected.includes(p._id || "")
                  );
                  setSelectedPens(targetPens);
                }}
                pens={pens}
                onAddNewCow={(createdCow) => setCows([createdCow, ...cows])}
                onChangePens={(changedPens) => {
                  setPens(changedPens);
                  const tempCows = [...cows];
                  tempCows.map((c) => {
                    c.pen = changedPens.find((p) => p._id == c.pen?._id);
                  });
                  setCows(tempCows);
                }}
                onClose={() =>
                  setCurrentViews(
                    currentViews.filter((v) => v != ViewSwitchValue.Pen)
                  )
                }
              />
            </WhitePanel>
          ) : null}
          {currentViews.includes(ViewSwitchValue.Cow) ? (
            <WhitePanel
              sx={{
                flex: 1,
                minWidth: { xs: "98vw", sm: "unset" },
                width: { xs: "98vw", sm: "unset" },
              }}
            >
              <CowTable
                selectedBarns={selectedBarns}
                selectedPens={selectedPens}
                loading={loadingCows}
                cows={cows}
                selectedIds={selectedCows?.map((p) => p._id || "") || []}
                onChangeSelectedIds={(selected) => {
                  const targetCows = cows.filter((c) =>
                    selected.includes(c._id || "")
                  );
                  setSelectedCows(targetCows);
                }}
                onChangeCows={(changedCows) => {
                  setCows(changedCows);
                }}
                onClose={() =>
                  setCurrentViews(
                    currentViews.filter((v) => v != ViewSwitchValue.Cow)
                  )
                }
              />
            </WhitePanel>
          ) : null}
        </Stack>
      </Box>
      {openFarmDialog && (
        <FarmFormDialog
          companyId={currentCompany?._id}
          farm={targetFarm}
          open
          onClose={() => setOpenFarmDialog(false)}
          onNewCreated={(created: ICompany) => {
            setFarms([...farms, created]);
            setOpenFarmDialog(false);
          }}
        />
      )}
    </FarmContext.Provider>
  );
}
