/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as XLSX from "xlsx";
import Box from "@mui/material/Box";
import GetAppIcon from "@mui/icons-material/GetApp";
import React from "react";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { IPen } from "../../../../types/pen";
import { FarmContext } from "../../../pages/farms/FarmContext";
import { useAppContext } from "../../../../contexts/useStorageContext";
import intakeService from "../../../../service/intake.service";
import { showSnackbar } from "../../../../service/snackbar.service";
import {
  IPenIntakeRecord,
  IPenLooseFeedRecord,
} from "../../../../types/dailyIntake";
import PenIntakeTable, { PenIntakeTableHeaderTitles } from "./PenIntakeTable";
import { getIntakeRecordDateString } from "../../../../helpers/formatTime";
import dayjs, { Dayjs } from "dayjs";
import { PickersShortcutsItem } from "@mui/x-date-pickers/PickersShortcuts";
import { DateRange } from "@mui/x-date-pickers-pro";
import { sortBy } from "lodash";
import PenIntakesChart from "../../PenIntakesChart";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import {
  CircularProgress,
  DialogContent,
  DialogTitle,
  Switch,
  Tooltip,
} from "@mui/material";
import looseFeedService from "../../../../service/looseFeed.service";

const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  {
    label: "Current Month",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("month"), today.endOf("month")];
    },
  },
  {
    label: "Last Month",
    getValue: () => {
      const currentDate = new Date();

      // Set the date to the 1st day of the current month
      const firstDayOfCurrentMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );

      // Subtract 1 day from the 1st day of the current month to get the last day of the previous month
      const lastDayOfPreviousMonth = new Date(firstDayOfCurrentMonth);
      lastDayOfPreviousMonth.setDate(firstDayOfCurrentMonth.getDate() - 1);

      // Set the date to the 1st day of the previous month
      const firstDayOfPreviousMonth = new Date(lastDayOfPreviousMonth);
      firstDayOfPreviousMonth.setDate(1);
      return [dayjs(firstDayOfPreviousMonth), dayjs(lastDayOfPreviousMonth)];
    },
  },
  {
    label: "Last 100 Days",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(100, "day"), today];
    },
  },
  {
    label: "This Year",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("year"), today.endOf("year")];
    },
  },
  {
    label: "Last 365 Days",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(365, "day"), today];
    },
  },
];

export default function PenIntakeOverviewDialog({
  pen,
  open,
  onClose,
}: {
  pen: IPen;
  open: boolean;
  onClose: () => void;
}): React.ReactElement {
  const { state } = useAppContext();
  const { currentCompany } = state;
  const farmContext = React.useContext(FarmContext);
  const [loading, setLoading] = React.useState(false);
  const [records, setRecords] = React.useState<IPenIntakeRecord[]>([]);
  const [looseFeeds, setLooseFeeds] = React.useState<IPenLooseFeedRecord[]>([]);
  const [fromDate, setFromDate] = React.useState(
    getIntakeRecordDateString(
      // new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      new Date(new Date().getTime() - 86400000 * 15)
    )
  );
  const [toDate, setToDate] = React.useState(
    getIntakeRecordDateString(new Date())
  );
  const [isTable, setIsTable] = React.useState(false);
  const [isDmCalculated, setIsDmCalculated] = React.useState(false);
  const [chartComponentKey, setChartComponentIndex] = React.useState(0);
  const [exporting, setExporting] = React.useState(false);

  const fetchPenRecords = async () => {
    try {
      if (!currentCompany?._id || !pen?._id) return;
      setLoading(true);
      const [penRecords, penLooseFeeds] = await Promise.all([
        intakeService.getPenRecordsByDateRange({
          companyId: currentCompany._id,
          penId: pen._id,
          from: fromDate,
          to: toDate,
        }),
        looseFeedService.getByDateRange({
          companyId: currentCompany._id,
          penId: pen._id,
          from: fromDate,
          to: toDate,
        }),
      ]);
      setRecords(sortBy(penRecords, (val) => val.date));
      setLooseFeeds(sortBy(penLooseFeeds, (val) => val.startDate));
      setLoading(false);
    } catch (error) {
      showSnackbar("danger", "Error", "Intake Fetch API error");
      setLoading(false);
    }
  };

  const cowCount =
    farmContext.cows?.filter((c) => c.pen?._id == pen._id)?.length || 0;

  const dmCalculatedRecords = React.useCallback(() => {
    if (!isDmCalculated) return records;
    return records.map((record) => ({
      ...record,
      fedAmount: record.fedAmount
        ? Number(
            ((record.fedAmount * (record.dmPercentage || 100)) / 100).toFixed(1)
          )
        : undefined,
      refusedAmount: record.refusedAmount
        ? Number(
            (
              (record.refusedAmount * (record.dmPercentage || 100)) /
              100
            ).toFixed(1)
          )
        : undefined,
      cowAverageFedAmount: record.cowAverageFedAmount
        ? Number(
            (
              (record.cowAverageFedAmount * (record.dmPercentage || 100)) /
              100
            ).toFixed(1)
          )
        : undefined,
      cowAverageRefusedAmount: record.cowAverageRefusedAmount
        ? Number(
            (
              (record.cowAverageRefusedAmount * (record.dmPercentage || 100)) /
              100
            ).toFixed(1)
          )
        : undefined,
    }));
  }, [records, isDmCalculated]);

  const dmCalculatedLooseFeeds = React.useCallback(() => {
    if (!isDmCalculated) return looseFeeds;
    return looseFeeds.map((record) => ({
      ...record,
      totalAmount: record.totalAmount
        ? Number(
            ((record.totalAmount * (record.dmPercentage || 100)) / 100).toFixed(
              1
            )
          )
        : undefined,
    }));
  }, [looseFeeds, isDmCalculated]);

  const handleExportData = async () => {
    const exportFileName = `${pen.name}-(${fromDate})~(${toDate})`;
    setExporting(true);
    const exportRows = dmCalculatedRecords().map((record) => {
      return {
        [PenIntakeTableHeaderTitles.Date]: record.date,
        [PenIntakeTableHeaderTitles.Diet]: record.diet?.name,
        [PenIntakeTableHeaderTitles.FedAmount]: record.fedAmount,
        [PenIntakeTableHeaderTitles.RefusedAmount]: record.refusedAmount,
        [PenIntakeTableHeaderTitles.CowAverageFedAmount]:
          record.cowAverageFedAmount,
        [PenIntakeTableHeaderTitles.CowAverageRefusedAmount]:
          record.cowAverageRefusedAmount,
        [PenIntakeTableHeaderTitles.DM]: record.dmPercentage,
      };
    });
    const ws = XLSX.utils.json_to_sheet(exportRows, {
      header: Object.values(PenIntakeTableHeaderTitles),
    });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${exportFileName.trim()}.xlsx`);
    setExporting(false);
  };

  React.useEffect(() => {
    if (!fromDate || !toDate) return;
    fetchPenRecords();
  }, [fromDate, toDate]);

  React.useEffect(() => {
    setChartComponentIndex(chartComponentKey + 1);
  }, [records, isDmCalculated]);

  return (
    <>
      <Dialog
        open={open}
        maxWidth="xl"
        scroll="paper"
        sx={{ "& .MuiPaper-root": { mx: 1, my: 1 } }}
      >
        <DialogTitle
          sx={{
            width: "1500px",
            maxWidth: "100%",
            p: 0,
          }}
        >
          <Box
            p={2}
            pb={1}
            sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
          >
            <Typography
              variant="h6"
              sx={{ display: "flex", alignItems: "flex-end", mr: 2 }}
            >
              <Box
                sx={{
                  display: { xs: "none", sm: "flex" },
                  alignItems: "flex-end",
                }}
              >
                Intake Overview
                <Box sx={{ mb: "2px", opacity: 0.7, fontSize: "16px", mx: 1 }}>
                  of
                </Box>
              </Box>
              <Box sx={{ color: "secondary.main" }}>{pen.name}</Box>
              <Box
                sx={{ opacity: 0.7, ml: 1, fontSize: "18px" }}
              >{`(${cowCount} Cows)`}</Box>
            </Typography>
            <Box sx={{ minWidth: "260px", mr: 3 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateRangePicker
                  slots={{ field: SingleInputDateRangeField }}
                  slotProps={{
                    textField: { size: "small", fullWidth: true },
                    shortcuts: {
                      items: shortcutsItems,
                    },
                  }}
                  value={[dayjs(fromDate), dayjs(toDate)]}
                  onChange={(val) => {
                    if (val[0]) {
                      setFromDate(getIntakeRecordDateString(val[0]?.toDate()));
                    }
                    if (val[1]) {
                      setToDate(getIntakeRecordDateString(val[1]?.toDate()));
                    }
                  }}
                />
              </LocalizationProvider>
            </Box>
            <RadioGroup
              row
              value={isTable}
              onChange={(e, val) => setIsTable(val == "true")}
            >
              <FormControlLabel
                value="false"
                control={<Radio size="small" />}
                label="Chart"
              />
              <FormControlLabel
                value="true"
                control={<Radio size="small" />}
                label="Table"
              />
            </RadioGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={isDmCalculated}
                  onChange={(e, checked) => setIsDmCalculated(checked)}
                />
              }
              label="DM"
            />
            <Tooltip title="Download Xlsx">
              <IconButton onClick={() => handleExportData()}>
                <GetAppIcon />
              </IconButton>
            </Tooltip>

            <Box sx={{ flex: 1 }} />
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        {loading && (
          <Box
            sx={{
              height: "300px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <DialogContent sx={{ p: 0 }} dividers>
          {isTable ? (
            <Box p={2}>
              <PenIntakeTable loading={loading} rows={dmCalculatedRecords()} />
            </Box>
          ) : null}

          {!isTable && records.length ? (
            <Box p={2}>
              <PenIntakesChart
                isDM={isDmCalculated}
                key={chartComponentKey}
                intakeRecords={dmCalculatedRecords()}
                looseFeeds={dmCalculatedLooseFeeds()}
              />
            </Box>
          ) : null}

          {!isTable && !records.length ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "300px",
              }}
            >
              No Records...
            </Box>
          ) : null}
        </DialogContent>
      </Dialog>
    </>
  );
}
