import * as React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import NavLinks from "../NavLinks";
import VersionsDialog, { IVersionsObject } from "./VersionsDialog";
import { useAppContext } from "../../../../contexts/useStorageContext";
import { IconButton } from "@mui/material";
import CompanyFormDialog from "../../../pages/companies/CompanyFormDialog";
import { actionTypes } from "../../../../types/state";

const MenuLink = styled(Link)(() => {
  return {
    width: "100%",
  };
});

const DesktopNavbarMenuButton = styled(ListItem)(({ theme }) => {
  return {
    "& .MuiListItemSecondaryAction-root": {
      opacity: 0,
      marginRight: "10px",
      transition: "0.5s",
    },
    "&:hover .MuiListItemSecondaryAction-root": {
      opacity: 1,
      marginRight: "0px",
    },
    "&.active": {
      backgroundColor: theme.palette.primary.main,
    },
    "&.active .MuiListItemButton-root": {
      backgroundImage: "linear-gradient(to left, #fffd , white)",
      borderRight: `solid 2px ${theme.palette.primary.main}`,
    },
  };
});

export default function SideDrawerMenu({
  width,
  backendVersions,
}: {
  width?: number;
  backendVersions: IVersionsObject;
}) {
  const { dispatch, state } = useAppContext();
  const { loggedInUserInfo, myCompanyProfile } = state;
  const currentPath = useLocation();
  const [openVersionDialog, setOpenVersionDialog] = React.useState(false);
  const [openCompanyFormDialog, setOpenCompanyFormDialog] =
    React.useState(false);
  return (
    <Box
      role="presentation"
      sx={{
        width: width || 270,
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          background: "#f9f9f9",
          mb: 1,
          minHeight: "65px",
          position: "relative",
          "&:hover .controls": {
            display: "block",
          },
        }}
      >
        {myCompanyProfile?.logoUrl ? (
          <img
            src={myCompanyProfile?.logoUrl}
            alt="Intake Rate Logo"
            style={{ width: "100%", height: "auto", marginBottom: "-4px" }}
          />
        ) : null}
        {myCompanyProfile ? (
          <Box
            className="controls"
            sx={{
              position: "absolute",
              bottom: "0px",
              width: "100%",
              background: "linear-gradient(#0000, #0009, #000a)",
              color: "white",
              fontSize: "17px",
              fontWeight: "500",
              display: "none",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "30px 20px 5px 20px",
              }}
            >
              {myCompanyProfile.name}
              <Box sx={{ flex: 1 }} />
              <IconButton
                sx={{
                  "&:hover": {
                    background: "#fff3",
                  },
                }}
                onClick={() => setOpenCompanyFormDialog(true)}
              >
                <EditIcon sx={{ color: "white", fontSize: "17px" }} />
              </IconButton>
            </Box>
          </Box>
        ) : null}
      </Box>
      <List sx={{ pl: 1 }}>
        {NavLinks.filter(
          (nav) =>
            loggedInUserInfo?.role == "super" ||
            !(loggedInUserInfo?.role != "super" && nav.onlyAdmin)
        ).map((navItem) => (
          <DesktopNavbarMenuButton
            key={navItem.menu_title}
            disablePadding
            secondaryAction={<ArrowForwardIcon color="primary" />}
            selected={
              currentPath?.pathname &&
              currentPath?.pathname.includes(navItem.path)
                ? true
                : false
            }
            className={
              currentPath?.pathname &&
              currentPath?.pathname.includes(navItem.path)
                ? "active"
                : ""
            }
          >
            <MenuLink to={navItem.path}>
              <ListItemButton>
                <ListItemIcon>
                  {navItem.menu_icon({
                    color:
                      currentPath?.pathname &&
                      currentPath?.pathname.includes(navItem.path)
                        ? "primary"
                        : "inherit",
                  })}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Box
                      sx={{
                        color:
                          currentPath?.pathname &&
                          currentPath?.pathname.includes(navItem.path)
                            ? "primary.main"
                            : "inherit",
                      }}
                    >
                      {navItem.menu_title}
                    </Box>
                  }
                  color="primary"
                />
              </ListItemButton>
            </MenuLink>
          </DesktopNavbarMenuButton>
        ))}
      </List>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <Box px={2} py={1} sx={{ background: "#e7f0f7", fontSize: "13px" }}>
          Fermentrics © 2023 All Rights Reserved.
          <Box
            mt={1}
            sx={{
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                cursor: "pointer",
                color: "#aaa",
                "&:hover": { color: "info.main", textDecoration: "underline" },
                mb: "4px",
              }}
            >
              <a href="/terms-and-conditions">Terms and Conditions</a>
            </Box>
            <Box
              sx={{
                cursor: "pointer",
                color: "#aaa",
                "&:hover": { color: "info.main", textDecoration: "underline" },
              }}
            >
              <a href="/privacy-policy">Privacy Policy</a>
            </Box>
            <div style={{ flex: 1 }} />
          </Box>
        </Box>
      </Box>
      <VersionsDialog
        open={openVersionDialog}
        onClose={() => setOpenVersionDialog(false)}
        frontendVersions={{
          PRODUCT: window.env.PRODUCT || "dev",
          PRODUCT_VERSION: window.env.PRODUCT_VERSION || "0.0.0",
          GRPC_VERSION: window.env.GRPC_VERSION || "local",
          BUILD: window.env.BUILD || "now",
          COMMIT_TAG: window.env.COMMIT_TAG || "local",
        }}
        backendVersions={backendVersions || {}}
      />
      {openCompanyFormDialog && (
        <CompanyFormDialog
          company={myCompanyProfile}
          open
          onClose={() => setOpenCompanyFormDialog(false)}
          onUpdated={(updatedCompany) => {
            dispatch!({
              type: actionTypes.setMyCompanyProfile,
              value: updatedCompany,
            });
            setOpenCompanyFormDialog(false);
          }}
        />
      )}
    </Box>
  );
}
