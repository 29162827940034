/* eslint-disable @typescript-eslint/no-explicit-any */
import backendApi from "./backendApi";
import { showSnackbar } from "./snackbar.service";

const routePrefix = "notification";

export default {
  async get({
    companyId,
    page,
    pageSize,
  }: {
    companyId?: string;
    page?: number;
    pageSize?: number;
  }): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/get`, {
        companyId,
        page,
        pageSize,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async read(ids: string[]): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/read`, { ids });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
};
