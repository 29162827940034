/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import React from "react";
import ReactApexChart from "react-apexcharts";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { ICowIntakeRecordModel } from "../../../types/cow";
import { IDiet } from "../../../types/diet";
import DietsListPopover from "../Common/DietsListPopover";
import { cloneDeep, uniqBy } from "lodash";
import { useAppContext } from "../../../contexts/useStorageContext";

function getAverageArray(arr1: number[], arr2: number[]) {
  return arr1.map((num, index) => (num + arr2[index] || 0) / 2);
}

export enum ChartTargetType {
  Barn = "barn",
  Pen = "pen",
  Cow = "cow",
}

export enum ChartNames {
  AsFed = "As Fed",
  EatingSpeed = "Eating Speed",
  // DryMatter = "Dry Matter",
  // WaterPercentage = "Water Percentage",
  // Ingredients = "Ingredients",
}

export enum AnnotationNames {
  RecipeAnnotation = "Mark Recipe",
  AsFed = "As Fed",
  DryMatter = "Dry Matter",
}

const CowIntakesChart = ({
  intakeRecords,
}: {
  intakeRecords: ICowIntakeRecordModel[];
}) => {
  const { state } = useAppContext();
  const { currentCompany } = state;
  const [visibleChartNames, setVisibleChartNames] = React.useState<
    ChartNames[]
  >([ChartNames.AsFed]);

  const dietChangePoints: { date?: string; dietName?: string; diet: IDiet }[] =
    [];

  let currentDietId: any = "";
  intakeRecords.map((r, i) => {
    if (r.diet?._id && r.diet?._id != currentDietId) {
      dietChangePoints.push({
        date: r.date,
        diet: r.diet,
        dietName: r.diet?.name,
      });
      currentDietId = r.diet?._id;
    }
  });

  const [fedChartOptions, setFedChartOptions] =
    React.useState<ApexCharts.ApexOptions>({
      title: {
        text: "As Fed (lb)",
        align: "left",
      },
      chart: {
        id: "fed",
        group: "1",
        type: "line",
      },
      series: [
        {
          name: "Fed",
          data: intakeRecords.map((r) => r.fedAmount || 0),
          color: "#05dd9f",
        },
        {
          name: "Refused",
          data: intakeRecords.map((r) => r.refusedAmount || 0),
          color: "#dd2505",
        },
      ],
      xaxis: {
        categories: intakeRecords.map((r) => r.date || ""),
        type: "datetime",
      },
      stroke: {
        width: 2, // Adjust the width of the line here
        curve: "smooth",
      },
      dataLabels: {
        enabled: true,
      },
      legend: {
        position: "top",
      },
      annotations: {
        xaxis: dietChangePoints
          .filter((d) => d.date)
          .map((dietChange) => ({
            x: new Date(dietChange.date as string).getTime(),
            borderColor: "#000",
            fillColor: "#FEB019",
            opacity: 0.2,
            label: {
              offsetY: 0,
              orientation: "horizontal",
              borderColor: "#333",
              style: {
                fontSize: "12px",
                color: "#333",
                background: "#FEB019",
              },
              text: dietChange.dietName,
            },
          })),
      },
    });

  const [speedChartOptions] = React.useState<ApexCharts.ApexOptions>({
    title: {
      text: `Eating Speed (${currentCompany?.weightUnit || "lbs"}/min)`,
      align: "left",
    },
    chart: {
      id: "speed",
      group: "1",
      type: "line",
    },
    series: [
      {
        name: "Fed",
        data: intakeRecords.map((r) => {
          const mins = (r.durationSeconds || 0) / 60;
          return mins
            ? Number(
                (((r.fedAmount || 0) - (r.refusedAmount || 0)) / mins).toFixed(
                  1
                )
              )
            : 0;
        }),
        color: "#05dd9f",
      },
    ],
    xaxis: {
      categories: intakeRecords.map((r) => r.date || ""),
      type: "datetime",
    },
    stroke: {
      width: 2, // Adjust the width of the line here
      curve: "smooth",
    },
    dataLabels: {
      enabled: true,
    },
    legend: {
      position: "top",
    },
  });

  const handleClickViewItem = (val: ChartNames) => {
    if (visibleChartNames.includes(val)) {
      setVisibleChartNames(visibleChartNames.filter((n) => n != val));
    } else {
      setVisibleChartNames([...visibleChartNames, val]);
    }
  };

  return (
    <Box>
      <Box
        mb={2}
        sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
      >
        <ButtonGroup size="small" aria-label="button group" sx={{ mr: 2 }}>
          {Object.values(ChartNames).map((val) => (
            <Button
              key={`chart-item-${val}`}
              onClick={() => handleClickViewItem(val)}
              color={visibleChartNames.includes(val) ? "secondary" : "inherit"}
              sx={{
                color: visibleChartNames.includes(val)
                  ? "secondary.main"
                  : "#bbb",
                background: visibleChartNames.includes(val)
                  ? "#ffe7db"
                  : "#fff",
              }}
            >
              {val}
            </Button>
          ))}
        </ButtonGroup>
        {dietChangePoints?.length ? (
          <DietsListPopover
            triggerElement={
              <Button
                endIcon={<KeyboardArrowDownIcon />}
                size="small"
                variant="outlined"
                sx={{ mr: 2 }}
              >
                {`${
                  uniqBy(dietChangePoints, (val) => val.dietName).length
                } Diets applied`}
              </Button>
            }
            diets={uniqBy(dietChangePoints, (val) => val.dietName).map(
              (d) => d.diet
            )}
          />
        ) : null}

        <FormControlLabel
          control={
            <Switch
              checked={fedChartOptions?.dataLabels?.enabled}
              onChange={(e, checked) => {
                const tempOptions = cloneDeep(fedChartOptions);
                tempOptions.dataLabels = {
                  ...(tempOptions.dataLabels || {}),
                  enabled: checked,
                };
                setFedChartOptions(tempOptions);
              }}
            />
          }
          label="Data Labels"
        />

        {/* <Box>
          <RadioGroup row value={isCompare} onChange={(e, val) => changeIsCompare && changeIsCompare(!!val)}>
            <FormControlLabel value="true" control={<Radio size="small" />} label="Compare" />
            <FormControlLabel value="" control={<Radio size="small" />} label="Average" />
          </RadioGroup>
        </Box> */}
        <Box sx={{ flex: 1 }} />
      </Box>
      {visibleChartNames.includes(ChartNames.AsFed) && (
        <ReactApexChart
          options={fedChartOptions}
          series={fedChartOptions.series}
          type="line"
          height={250}
        />
      )}
      {visibleChartNames.includes(ChartNames.EatingSpeed) && (
        <ReactApexChart
          options={speedChartOptions}
          series={speedChartOptions.series}
          type="line"
          height={250}
        />
      )}
    </Box>
  );
};

export default CowIntakesChart;
