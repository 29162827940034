import { encryptStorageUtils } from "../service/encryptStorage";
import { ILoggedInUserInfo } from "../types/auth";
import { ICompany } from "../types/company";
import { ICompanySettings } from "../types/companySettings";
import { IFarm } from "../types/farm";
import { INotification } from "../types/notification";
import { ActionType, StateType, actionTypes } from "../types/state";
import { IUser } from "../types/user";

export const initialState = {
  openSideDrawer: false,
};

export const actionAppReducer = (
  state: StateType,
  action: ActionType
): StateType => {
  switch (action.type) {
    case actionTypes.setAllState:
      return action.value as StateType;
    case actionTypes.setOpenSideDrawer:
      return {
        ...state,
        openSideDrawer: action.value as boolean,
      };
    case actionTypes.setMyProfile:
      return {
        ...state,
        myProfile: action.value as IUser | undefined,
      };
    case actionTypes.setMyCompanyProfile:
      return {
        ...state,
        myCompanyProfile: action.value as ICompany | undefined,
      };
    case actionTypes.setNotifications:
      return {
        ...state,
        notifications: action.value as INotification[] | undefined,
      };
    case actionTypes.setMyCompanySettings:
      return {
        ...state,
        myCompanySettings: action.value as ICompanySettings | undefined,
      };
    case actionTypes.setCurrentCompany:
      return {
        ...state,
        currentCompany: action.value as ICompany | undefined,
      };
    case actionTypes.setCurrentFarm:
      return {
        ...state,
        currentFarm: action.value as IFarm | undefined,
      };
    case actionTypes.setLoggedInUserInfo:
      const loggedInInfo: ILoggedInUserInfo = action.value as ILoggedInUserInfo;
      encryptStorageUtils.setAuthTokenFromStorage(
        loggedInInfo?.authToken || ""
      );
      return {
        ...state,
        loggedInUserInfo: action.value as ILoggedInUserInfo | undefined,
      };
    default:
      return state;
  }
};
