/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from "@mui/material/Box";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import React from "react";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Typography from "@mui/material/Typography";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Stack from "@mui/material/Stack";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import LunchDiningIcon from "@mui/icons-material/LunchDining";
import UploadIcon from "@mui/icons-material/Upload";
import { styled } from "@mui/material/styles";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import ProgressButton from "../../Common/ProgressButton";
import { IPen } from "../../../../types/pen";
import { FarmContext } from "../../../pages/farms/FarmContext";
import {
  ICowPHRecord,
  IFeed,
  IPastureFeed,
  IPenIntakeRecord,
  IPenLooseFeedRecord,
  IPushUp,
} from "../../../../types/dailyIntake";
import {
  changeOnlyDate,
  fTime,
  fTimestamp,
  getIntakeRecordDateString,
} from "../../../../helpers/formatTime";
import TextField from "@mui/material/TextField";
import DietAutoComplete from "../../diets/DietAutocomplete";
import {
  Button,
  Chip,
  CircularProgress,
  InputBase,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from "@mui/material";
import GeneralPopoverWrapper, {
  GeneralPopoverWrapperCloseTrigger,
} from "../../Common/GeneralPopoverWrapper";
import intakeService from "../../../../service/intake.service";
import { useAppContext } from "../../../../contexts/useStorageContext";
import FeedFormDialog from "../FeedFormDialog";
import CowAutoComplete from "../../cow/CowAutocomplete";
import looseFeedService from "../../../../service/looseFeed.service";
import { INotification } from "../../../../types/notification";
import { actionTypes } from "../../../../types/state";

const SectionTitleContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  paddingBottom: "3px",
  "& button": {
    background: "#2973351c",
  },
  "& button:hover": {
    background: "#29733533",
  },
}));

const AdditionalFeedSectionTitleBar = styled(Box)(() => ({
  borderTop: "solid 1px #bbb",
  "& .section-bar-container": {
    marginTop: "-11px",
    "& .title-header": {
      background: "white",
      display: "inline-flex",
      padding: "2px 9px",
      fontSize: "15px",
      border: "solid 1px #bbb",
      borderRadius: "5px",
      alignItems: "center",
      cursor: "pointer",
      "& svg": {
        marginTop: "-3px",
        marginBottom: "-3px",
        marginRight: "-3px",
        marginLeft: "3px",
      },
      "& .feed-values": {
        display: "flex",
        alignItems: "center",
        fontSize: "14px",
        marginLeft: "8px",
        "&.success": {
          color: "success.main",
          fontWeight: "500",
        },
        "&.ongoing": {
          color: "warning.main",
          fontWeight: "500",
        },
        "&.none": {
          fontWeight: "500",
          opacity: "0.5",
        },
      },
    },
  },
}));

const FeedItem = styled(Box)(() => ({
  border: "solid 1px #ddd",
  flexWrap: "wrap",
  display: "flex",
  alignItems: "center",
  padding: "2px 10px",
  marginTop: "6px",
  borderRadius: "3px",
  boxShadow:
    "0px 2px 1px -1px rgb(0 0 0 / 1%), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 0px 4px 0px rgba(0,0,0,0.12)",
}));

const NoItems = styled(Box)(() => ({
  borderTop: "solid 1px #e1e1e1",
}));

export default function DailyIntakePanel({
  pen,
  open,
  onClose,
}: {
  pen: IPen;
  open: boolean;
  onClose: () => void;
}): React.ReactElement {
  const { state, dispatch } = useAppContext();
  const { currentCompany, notifications } = state;
  const farmContext = React.useContext(FarmContext);
  const [initialRecordBeforeEdit, setInitialRecordBeforeEdit] =
    React.useState<IPenIntakeRecord>({
      date: getIntakeRecordDateString(new Date()),
      pen: pen,
      company: currentCompany?._id || "",
    });
  const [editingData, setEditingData] = React.useState<IPenIntakeRecord>({
    date: getIntakeRecordDateString(new Date()),
    pen: pen,
    company: currentCompany?._id || "",
  });
  const [newPushup, setNewPushup] = React.useState<IPushUp>({
    timestamp: new Date(),
  });
  const [newCowPhRecord, setNewCowPhRecord] = React.useState<ICowPHRecord>();
  const [processing, setProcessing] = React.useState(false);
  const [savingLooseFeed, setSavingLooseFeed] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [openFeedDetailsDialog, setOpenFeedDetailsDialog] =
    React.useState(false);
  const [targetFeedIndex, setTargetFeedIndex] = React.useState(-1);
  const [looseFeed, setLooseFeed] = React.useState<IPenLooseFeedRecord>({});
  const [initialLooseFeed, setInitialLooseFeed] =
    React.useState<IPenLooseFeedRecord>({});
  const [openLooseFeedSection, setOpenLooseFeedSection] = React.useState(false);
  const [openPastureFeedSection, setOpenPastureFeedSection] =
    React.useState(false);
  const [pastureFeed, setPastureFeed] = React.useState<IPastureFeed>({});
  const [initialPastureFeed, setInitialPastureFeed] =
    React.useState<IPastureFeed>({});
  const [savingPasture, setSavingPastureFeed] = React.useState(false);

  const handleAddNewFeed = (newFeed: IFeed) => {
    const updatedFeeds = [...(editingData.feeds || []), newFeed];
    setEditingData({
      ...editingData,
      feeds: updatedFeeds,
      fedAmount:
        updatedFeeds?.reduce(
          (partialSum, a) => partialSum + (a.fedAmount || 0),
          0
        ) || undefined,
    });
    setOpenFeedDetailsDialog(false);
  };

  const handleSaveEditedFeed = (editedFeed: IFeed) => {
    const tempFeeds = [...(editingData.feeds || [])];
    tempFeeds.splice(targetFeedIndex, 1, editedFeed);
    setEditingData({
      ...editingData,
      feeds: tempFeeds,
      fedAmount:
        tempFeeds?.reduce(
          (partialSum, a) => partialSum + (a.fedAmount || 0),
          0
        ) || undefined,
    });
    setOpenFeedDetailsDialog(false);
  };

  const handleAddNewPushup = () => {
    setEditingData({
      ...editingData,
      pushUps: [...(editingData.pushUps || []), newPushup],
    });
    setNewPushup({ timestamp: new Date() });
  };

  const handleAddNewCowPhRecord = () => {
    if (!newCowPhRecord) return;
    const updatedCowPhRecords = [
      ...(editingData.cowPhRecords || []),
      newCowPhRecord,
    ];
    setEditingData({
      ...editingData,
      cowPhRecords: updatedCowPhRecords,
      averagePh:
        (updatedCowPhRecords
          .map((p) => p.ph)
          .reduce(
            (accumulator, currentValue) =>
              (accumulator || 0) + (currentValue || 0),
            0
          ) || 0) / updatedCowPhRecords.length,
    });
    setNewCowPhRecord(undefined);
  };

  const cowCount =
    farmContext.cows?.filter((c) => c.pen?._id == pen._id)?.length || 0;

  const handleSave = async () => {
    if (!editingData.date || !editingData.diet) return;
    setProcessing(true);
    const result = await intakeService.saveDayIntakeData(
      currentCompany?._id || "",
      editingData
    );
    const newNotification: INotification | null = result.data?.notification;
    if (newNotification) {
      // todo: show notification modal dialog
      dispatch!({
        type: actionTypes.setNotifications,
        value: [newNotification, ...(notifications || [])],
      });
    }
    setProcessing(false);
  };

  const fetchRecord = async () => {
    if (!currentCompany?._id || !pen._id || !editingData.date) return;
    setLoading(true);
    const result = await intakeService.getIntakeRecord(
      currentCompany?._id || "",
      pen._id || "",
      editingData.date || ""
    );
    setLoading(false);
    if (result?.data) {
      setInitialRecordBeforeEdit(result.data);
      setEditingData(result.data);
      setPastureFeed(result.data?.pastureFeed || {});
      setInitialPastureFeed(result.data?.pastureFeed || {});
    } else {
      setEditingData({
        date: editingData.date,
        pen: pen,
        company: currentCompany?._id || "",
      });
      setInitialRecordBeforeEdit({
        date: editingData.date,
        pen: pen,
        company: currentCompany?._id || "",
      });
    }
  };

  const fetchLooseFeed = async () => {
    if (!currentCompany?._id || !editingData.date || !pen._id) return;
    const result = await looseFeedService.getLooseFeed(
      currentCompany?._id || "",
      pen._id || "",
      editingData.date || ""
    );
    if (result?.data) {
      setInitialLooseFeed(result.data);
      setLooseFeed(result.data);
    } else {
      setInitialLooseFeed({
        pen: pen,
        company: currentCompany?._id || "",
      });
      setLooseFeed({
        pen: pen,
        company: currentCompany?._id || "",
      });
    }
  };

  const handleSaveLooseFeed = async () => {
    if (!looseFeed?.totalAmount || !looseFeed.diet || !looseFeed.startDate)
      return;
    setSavingLooseFeed(true);
    const result = await looseFeedService.save(
      currentCompany?._id || "",
      looseFeed
    );
    setSavingLooseFeed(false);
    if (looseFeed.endDate) {
      fetchRecord();
    }
  };
  const handleSavePastureFeed = async () => {
    if (!pastureFeed) return;
    setSavingPastureFeed(true);
    const updatedEditingData = { ...editingData, pastureFeed: pastureFeed };
    await intakeService.saveDayIntakeData(
      currentCompany?._id || "",
      updatedEditingData
    );
    setEditingData(updatedEditingData);
    setInitialRecordBeforeEdit(updatedEditingData);
    setInitialPastureFeed({ ...pastureFeed });
    setSavingPastureFeed(false);
    if (looseFeed.endDate) {
      fetchRecord();
    }
  };

  React.useEffect(() => {
    fetchRecord();
    fetchLooseFeed();
  }, [editingData.date, currentCompany]);

  return (
    <>
      <Dialog open={open} maxWidth="sm">
        <Box sx={{ background: "white", width: "550px", maxWidth: "100%" }}>
          <Box
            p={2}
            pt={1}
            pb={0}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography
              variant="h6"
              sx={{ display: "flex", alignItems: "flex-end" }}
            >
              <Box
                sx={{
                  display: { xs: "none", sm: "flex" },
                  alignItems: "flex-end",
                }}
              >
                Daily Intake
                <Box sx={{ mb: "2px", opacity: 0.7, fontSize: "16px", mx: 1 }}>
                  of
                </Box>
              </Box>

              <Box sx={{ color: "secondary.main" }}>{pen.name}</Box>
              <Box
                sx={{ opacity: 0.7, ml: 1, fontSize: "18px" }}
              >{`(${cowCount} Cows)`}</Box>
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box p={2}>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
              <Box sx={{ flex: 1 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date"
                    value={dayjs(editingData.date)}
                    onChange={(newValue) =>
                      setEditingData({
                        ...editingData,
                        date:
                          getIntakeRecordDateString(newValue?.toDate()) ||
                          undefined,
                      })
                    }
                    slotProps={{ textField: { size: "small" } }}
                  />
                </LocalizationProvider>
              </Box>

              <Box sx={{ flex: 1 }}>
                <DietAutoComplete
                  label="Diet"
                  selectedId={editingData?.diet?._id}
                  onChange={(selectedDiet) => {
                    const tempFeeds = [...(editingData.feeds || [])];
                    tempFeeds.map((f) => (f.diet = selectedDiet?._id));
                    setEditingData({
                      ...editingData,
                      feeds: tempFeeds,
                      diet: selectedDiet || undefined,
                    });
                  }}
                  fullWidth
                  error={!editingData?.diet?._id}
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                <TextField
                  type="number"
                  size="small"
                  label="DM (%)"
                  value={editingData?.dmPercentage || ""}
                  onChange={(e) =>
                    setEditingData({
                      ...editingData,
                      dmPercentage: Number(e.target.value) || undefined,
                    })
                  }
                />
              </Box>
            </Stack>
            <Box mt={2} sx={{ position: "relative" }}>
              <SectionTitleContainer>
                <Button
                  onClick={() => setOpenFeedDetailsDialog(true)}
                  sx={{ height: "25px" }}
                  startIcon={
                    <ControlPointIcon sx={{ fontSize: "18px !important" }} />
                  }
                >
                  New Feed
                </Button>
              </SectionTitleContainer>
              <Box>
                {!editingData.feeds?.length ? <NoItems /> : null}
                {editingData.feeds?.map((feed, i) => (
                  <FeedItem key={`feed-${i}`}>
                    <Box
                      sx={{
                        display: { xs: "block", sm: "flex" },
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box sx={{ width: "80px" }}>
                          {fTime(feed.timestamp)}
                        </Box>
                        <Box
                          sx={{
                            height: "23px",
                            width: "15px",
                            borderLeft: "solid 1px #e5e5e5",
                          }}
                        />
                        <Box sx={{ width: "80px" }}>{`${feed.fedAmount} ${
                          currentCompany?.weightUnit || "lbs"
                        }`}</Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          py: "4px",
                        }}
                      >
                        <Box
                          sx={{
                            height: "23px",
                            width: "10px",
                            borderLeft: "solid 1px #e5e5e5",
                          }}
                        />
                        <Box sx={{ fontSize: "14px", mr: "5px" }}>Refused:</Box>
                        <Box>
                          <InputBase
                            value={feed.refusedAmount}
                            onChange={(e) => {
                              const tempFeeds = editingData.feeds || [];
                              tempFeeds.splice(i, 1, {
                                ...feed,
                                refusedAmount:
                                  Number(e.target.value) || undefined,
                              });
                              setEditingData({
                                ...editingData,
                                feeds: tempFeeds,
                                refusedAmount:
                                  tempFeeds.reduce(
                                    (partialSum, a) =>
                                      partialSum + (a.refusedAmount || 0),
                                    0
                                  ) || undefined,
                              });
                            }}
                            type="number"
                            sx={{
                              width: "80px",
                              height: "27px",
                              px: 1,
                              borderRadius: "3px",
                              backgroundColor: "#f3f3f3",
                              color: "secondary.main",
                            }}
                          />
                        </Box>
                        <Box sx={{ fontSize: "14px", ml: "4px" }}>
                          {currentCompany?.weightUnit || "lbs"}
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ flex: 1 }} />
                    <GeneralPopoverWrapper
                      closeOnClickContent
                      triggerElement={
                        <IconButton size="small">
                          <MoreVertIcon />
                        </IconButton>
                      }
                      popoverContent={
                        <MenuList>
                          <MenuItem
                            onClick={() => {
                              setTargetFeedIndex(i);
                              setOpenFeedDetailsDialog(true);
                            }}
                          >
                            <ListItemIcon>
                              <EditIcon />
                            </ListItemIcon>
                            <ListItemText>Edit Feed</ListItemText>
                          </MenuItem>

                          <MenuItem
                            onClick={() => {
                              const tempFeeds = editingData.feeds || [];
                              tempFeeds.splice(i, 1);
                              setEditingData({
                                ...editingData,
                                feeds: tempFeeds,
                                fedAmount:
                                  tempFeeds?.reduce(
                                    (partialSum, a) =>
                                      partialSum + (a.fedAmount || 0),
                                    0
                                  ) || undefined,
                              });
                            }}
                          >
                            <ListItemIcon>
                              <DeleteOutlineIcon />
                            </ListItemIcon>
                            <ListItemText>Delete Feed</ListItemText>
                          </MenuItem>
                        </MenuList>
                      }
                    />
                  </FeedItem>
                ))}
              </Box>
              {loading && (
                <Box
                  sx={{
                    background: "#fffb",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    left: 0,
                    top: 0,
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </Box>

            <Box mt={2} sx={{ position: "relative" }}>
              <SectionTitleContainer>
                <GeneralPopoverWrapper
                  triggerElement={
                    <Button
                      sx={{ height: "25px" }}
                      startIcon={
                        <ControlPointIcon
                          sx={{ fontSize: "18px !important" }}
                        />
                      }
                    >
                      New Push Up
                    </Button>
                  }
                  popoverContent={
                    <Box p={2}>
                      <Stack spacing={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            label="Feed Time"
                            value={dayjs(newPushup.timestamp)}
                            onChange={(newValue) => {
                              if (!editingData.date || !newValue) return;
                              setNewPushup({
                                ...newPushup,
                                timestamp: changeOnlyDate(
                                  newValue.toDate(),
                                  new Date(editingData.date)
                                ),
                              });
                            }}
                            slotProps={{ textField: { size: "small" } }}
                          />
                        </LocalizationProvider>
                        <Button
                          disabled={!newPushup.timestamp}
                          onClick={() => {
                            handleAddNewPushup();
                          }}
                          fullWidth
                          variant="contained"
                          className={GeneralPopoverWrapperCloseTrigger}
                        >
                          Add Push Up
                        </Button>
                      </Stack>
                    </Box>
                  }
                />
              </SectionTitleContainer>
              <Box>
                {!editingData.pushUps?.length ? <NoItems /> : null}
                {editingData.pushUps?.map((pushup, i) => (
                  <Chip
                    key={`pushup-${i}`}
                    label={fTime(pushup.timestamp)}
                    size="small"
                    sx={{ mr: "5px", mt: "5px" }}
                    onDelete={() => {
                      const tempPushups = editingData.pushUps || [];
                      tempPushups.splice(i, 1);
                      setEditingData({ ...editingData, pushUps: tempPushups });
                    }}
                  />
                ))}
              </Box>
              {loading && (
                <Box
                  sx={{
                    background: "#fffb",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    left: 0,
                    top: 0,
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </Box>

            <Box mt={2} sx={{ position: "relative" }}>
              <SectionTitleContainer>
                <GeneralPopoverWrapper
                  triggerElement={
                    <Button
                      sx={{ height: "25px" }}
                      startIcon={
                        <ControlPointIcon
                          sx={{ fontSize: "18px !important" }}
                        />
                      }
                    >
                      Cow PH Record
                    </Button>
                  }
                  popoverContent={
                    <Box p={2}>
                      <Stack spacing={2}>
                        <Stack direction="row" spacing={1}>
                          <CowAutoComplete
                            cows={farmContext?.cows || []}
                            label="Measured Cow"
                            value={newCowPhRecord?.cow}
                            onChange={(selected) =>
                              setNewCowPhRecord({
                                ...(newCowPhRecord || {}),
                                cow: selected || undefined,
                              })
                            }
                          />
                          <TextField
                            size="small"
                            label="PH"
                            type="number"
                            value={newCowPhRecord?.ph}
                            onChange={(e) =>
                              setNewCowPhRecord({
                                ...(newCowPhRecord || {}),
                                ph: Number(e.target.value) || undefined,
                              })
                            }
                          />
                        </Stack>
                        <Button
                          disabled={!newCowPhRecord?.cow || !newCowPhRecord?.ph}
                          onClick={() => {
                            handleAddNewCowPhRecord();
                          }}
                          fullWidth
                          variant="contained"
                          className={GeneralPopoverWrapperCloseTrigger}
                        >
                          Add PH Record
                        </Button>
                      </Stack>
                    </Box>
                  }
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "14px",
                    ml: 1,
                    border: "solid 1px #ddd",
                    borderRadius: "5px",
                    padding: "3px 9px",
                  }}
                >
                  <Box>Average PH:</Box>
                  <Box sx={{ color: "warning.main", ml: 1 }}>
                    {editingData.averagePh || "None"}
                  </Box>
                </Box>
              </SectionTitleContainer>
              <Box>
                {!editingData.cowPhRecords?.length ? <NoItems /> : null}
                {editingData.cowPhRecords?.map((cowPhRecord, i) => (
                  <Chip
                    key={`ph-${i}`}
                    label={`${cowPhRecord.cow?.name} : ${cowPhRecord.ph}`}
                    size="small"
                    sx={{ mr: "5px", mt: "5px" }}
                    onDelete={() => {
                      const tempPhRecords = editingData.cowPhRecords || [];
                      tempPhRecords.splice(i, 1);
                      setEditingData({
                        ...editingData,
                        cowPhRecords: tempPhRecords,
                        averagePh:
                          (tempPhRecords
                            .map((p) => p.ph)
                            .reduce(
                              (accumulator, currentValue) =>
                                (accumulator || 0) + (currentValue || 0),
                              0
                            ) || 0) / tempPhRecords.length,
                      });
                    }}
                  />
                ))}
              </Box>
              {loading && (
                <Box
                  sx={{
                    background: "#fffb",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    left: 0,
                    top: 0,
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </Box>

            <Box
              mt={1}
              sx={{
                display: "flex",
                alignItems: "flex-end",
                flexWrap: "wrap",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  fontSize: "13px",
                  opacity: 0.8,
                }}
              >
                <Box mr={2} mt={1}>
                  <Box sx={{ mb: "8px" }}>
                    Total Fed:{" "}
                    <b>
                      {editingData.feeds?.reduce(
                        (partialSum, a) => partialSum + (a.fedAmount || 0),
                        0
                      ) || undefined}
                    </b>{" "}
                    {currentCompany?.weightUnit || "lbs"}
                  </Box>
                  <Box>
                    Cow Avg:{" "}
                    <b>
                      {(
                        (editingData.feeds?.reduce(
                          (partialSum, a) => partialSum + (a.fedAmount || 0),
                          0
                        ) || 0) / cowCount
                      ).toFixed(1)}
                    </b>{" "}
                    {currentCompany?.weightUnit || "lbs"}
                  </Box>
                </Box>
                <Box mt={1}>
                  <Box
                    sx={{ mb: "4px", display: "flex", alignItems: "center" }}
                  >
                    Total Refused:
                    <InputBase
                      key={editingData.date}
                      value={editingData.refusedAmount}
                      type="number"
                      sx={{
                        color: "secondary.main",
                        width: "70px",
                        height: "23px",
                        px: "2px",
                        borderRadius: "3px",
                        backgroundColor: "#f3f3f3",
                        mx: "3px",
                      }}
                      onChange={(e) =>
                        setEditingData({
                          ...editingData,
                          refusedAmount: Number(e.target.value) || undefined,
                        })
                      }
                    />
                    {currentCompany?.weightUnit || "lbs"}
                  </Box>
                  <Box>
                    Cow Avg:{" "}
                    <b>
                      {((editingData.refusedAmount || 0) / cowCount).toFixed(1)}
                    </b>{" "}
                    {currentCompany?.weightUnit || "lbs"}
                  </Box>
                </Box>
              </Box>
              <Box sx={{ flex: 1 }} />
              <GeneralPopoverWrapper
                transformHorizontal="center"
                tooltipTitle="Notes"
                triggerElement={
                  <IconButton
                    color={editingData.note ? "primary" : undefined}
                    sx={{ border: "solid 1px #ccc" }}
                  >
                    <TextSnippetIcon />
                  </IconButton>
                }
                popoverContent={
                  <Box p={2} sx={{ width: "400px" }}>
                    <TextField
                      fullWidth
                      multiline
                      minRows={3}
                      size="small"
                      label="Notes"
                      value={editingData?.note || ""}
                      onChange={(e) =>
                        setEditingData({ ...editingData, note: e.target.value })
                      }
                    />
                  </Box>
                }
              />

              <Box sx={{ flex: 1 }} />
              <ProgressButton
                inProgress={processing}
                onClick={() => handleSave()}
                disabled={
                  !editingData?.diet?._id ||
                  processing ||
                  JSON.stringify(initialRecordBeforeEdit) ==
                    JSON.stringify(editingData)
                }
                title="Save"
                sx={{ mt: "12px" }}
              />
            </Box>
            <Box mt={3}>
              <AdditionalFeedSectionTitleBar>
                <Box className="section-bar-container">
                  <Box
                    className="title-header"
                    onClick={() => {
                      if (!openLooseFeedSection) {
                        setOpenPastureFeedSection(false);
                      }
                      setOpenLooseFeedSection(!openLooseFeedSection);
                    }}
                  >
                    Loose Feed
                    {editingData.looseFeedAmount &&
                    editingData.looseFeedDiet ? (
                      <Box className="feed-values success">
                        {`${editingData.looseFeedDiet?.name}: `}
                        <Box sx={{ color: "warning.main", ml: "4px" }}>
                          {`${editingData.looseFeedAmount} 
                            ${currentCompany?.weightUnit || "lbs"}`}
                        </Box>
                        {editingData.looseFeedDmPercentage ? (
                          <Box
                            sx={{ ml: "4px", color: "#999", fontWeight: "400" }}
                          >{`(${editingData.looseFeedDmPercentage} %)`}</Box>
                        ) : null}
                      </Box>
                    ) : editingData.looseFeedDiet ? (
                      <Box className="feed-values ongoing">
                        {`${editingData.looseFeedDiet?.name}: Ongoing`}
                      </Box>
                    ) : (
                      <Box className="feed-values none">None</Box>
                    )}
                    {openLooseFeedSection ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </Box>
                </Box>
              </AdditionalFeedSectionTitleBar>
              {openLooseFeedSection && (
                <>
                  <Stack spacing={1} direction="row" mb={1} mt={2}>
                    <DietAutoComplete
                      label="Diet"
                      selectedId={looseFeed?.diet?._id}
                      onChange={(selectedDiet) => {
                        setLooseFeed({
                          ...looseFeed,
                          diet: selectedDiet || undefined,
                        });
                      }}
                      fullWidth
                      error={!looseFeed?.diet?._id}
                    />
                    <TextField
                      size="small"
                      type="number"
                      value={looseFeed?.totalAmount || ""}
                      onChange={(e) =>
                        setLooseFeed({
                          ...looseFeed,
                          totalAmount: Number(e.target.value) || undefined,
                        })
                      }
                      label="Total Amount"
                    />
                    <TextField
                      size="small"
                      type="number"
                      value={looseFeed?.dmPercentage || ""}
                      onChange={(e) =>
                        setLooseFeed({
                          ...looseFeed,
                          dmPercentage: Number(e.target.value) || undefined,
                        })
                      }
                      label="DM (%)"
                    />
                  </Stack>
                  <Stack direction="row" spacing={1} sx={{ flex: 1 }}>
                    <Box sx={{ flex: 1 }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Start Date"
                          value={
                            looseFeed?.startDate
                              ? dayjs(looseFeed?.startDate)
                              : null
                          }
                          onChange={(newValue) =>
                            setLooseFeed({
                              ...looseFeed,
                              startDate:
                                getIntakeRecordDateString(newValue?.toDate()) ||
                                undefined,
                            })
                          }
                          slotProps={{
                            textField: { size: "small", fullWidth: true },
                          }}
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="End Date"
                          value={
                            looseFeed?.endDate
                              ? dayjs(looseFeed?.endDate)
                              : null
                          }
                          onChange={(newValue) =>
                            setLooseFeed({
                              ...looseFeed,
                              endDate:
                                getIntakeRecordDateString(newValue?.toDate()) ||
                                undefined,
                            })
                          }
                          slotProps={{
                            textField: { size: "small", fullWidth: true },
                          }}
                        />
                      </LocalizationProvider>
                    </Box>
                  </Stack>
                  {looseFeed?.startDate &&
                  looseFeed?.endDate &&
                  looseFeed?.endDate < looseFeed?.startDate ? (
                    <Box>End date should be later than start date</Box>
                  ) : null}
                  <Box mt={1}>
                    <ProgressButton
                      inProgress={savingLooseFeed}
                      onClick={() => handleSaveLooseFeed()}
                      disabled={
                        !looseFeed?.diet ||
                        !looseFeed?.startDate ||
                        !looseFeed?.totalAmount ||
                        (!!looseFeed?.startDate &&
                          !!looseFeed?.endDate &&
                          looseFeed?.endDate < looseFeed?.startDate) ||
                        JSON.stringify(initialLooseFeed) ==
                          JSON.stringify(looseFeed)
                      }
                      title="Save Loose Feed"
                    />
                  </Box>
                </>
              )}
              <AdditionalFeedSectionTitleBar sx={{ mt: 3 }}>
                <Box className="section-bar-container">
                  <Box
                    className="title-header"
                    onClick={() => {
                      if (!openPastureFeedSection) {
                        setOpenLooseFeedSection(false);
                      }
                      setOpenPastureFeedSection(!openPastureFeedSection);
                    }}
                  >
                    Pasture Feed
                    {editingData.pastureFeed?.totalDurationMinutes ? (
                      <Box className="feed-values success">
                        {`${Number(
                          (
                            editingData.pastureFeed.totalDurationMinutes / 60
                          ).toFixed(1)
                        )} hours`}
                      </Box>
                    ) : editingData.pastureFeed?.startTime ? (
                      <Box className="feed-values ongoing">
                        {`${editingData.looseFeedDiet?.name}: Ongoing`}
                      </Box>
                    ) : (
                      <Box className="feed-values none">None</Box>
                    )}
                    {openPastureFeedSection ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </Box>
                </Box>
              </AdditionalFeedSectionTitleBar>
              {openPastureFeedSection ? (
                <Box mt={2}>
                  <Stack direction="row" spacing={1} sx={{ flex: 1 }}>
                    <Box sx={{ flex: 1 }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          label="Start Time"
                          value={
                            pastureFeed?.startTime
                              ? dayjs(pastureFeed?.startTime)
                              : null
                          }
                          onChange={(newValue) => {
                            if (!editingData.date || !newValue) return;
                            const newStartTime = changeOnlyDate(
                              newValue.toDate(),
                              new Date(editingData.date)
                            );
                            setPastureFeed({
                              ...pastureFeed,
                              startTime: newStartTime,
                              totalDurationMinutes: pastureFeed.endTime
                                ? (new Date(pastureFeed.endTime).getTime() -
                                    newStartTime.getTime()) /
                                  60000
                                : undefined,
                            });
                          }}
                          slotProps={{
                            textField: { size: "small", fullWidth: true },
                          }}
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          label="End Time"
                          value={
                            pastureFeed?.endTime
                              ? dayjs(pastureFeed?.endTime)
                              : null
                          }
                          onChange={(newValue) => {
                            if (!editingData.date || !newValue) return;
                            const newEndTime = changeOnlyDate(
                              newValue.toDate(),
                              new Date(editingData.date)
                            );
                            setPastureFeed({
                              ...pastureFeed,
                              endTime: newEndTime,
                              totalDurationMinutes: pastureFeed.startTime
                                ? (newEndTime.getTime() -
                                    new Date(pastureFeed.startTime).getTime()) /
                                  60000
                                : undefined,
                            });
                          }}
                          slotProps={{
                            textField: { size: "small", fullWidth: true },
                          }}
                        />
                      </LocalizationProvider>
                    </Box>
                  </Stack>
                  <Box mt={1}>
                    <ProgressButton
                      inProgress={savingPasture}
                      onClick={() => handleSavePastureFeed()}
                      disabled={
                        JSON.stringify(initialPastureFeed) ==
                        JSON.stringify(pastureFeed)
                      }
                      title="Save Pasture Feed"
                    />
                  </Box>
                </Box>
              ) : null}
            </Box>
          </Box>
          {openFeedDetailsDialog && (
            <FeedFormDialog
              diet={editingData.diet}
              open
              onClose={() => setOpenFeedDetailsDialog(false)}
              feed={editingData.feeds?.[targetFeedIndex]}
              onAdd={(addingFeed) => handleAddNewFeed(addingFeed)}
              onSave={(savingFeed) => handleSaveEditedFeed(savingFeed)}
              stallCount={pen.stallCount || 0}
            />
          )}
        </Box>
      </Dialog>
    </>
  );
}
