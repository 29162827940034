/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from "@mui/material/Box";
import React from "react";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { IPen } from "../../../types/pen";
import penService from "../../../service/pen.service";
import ProgressButton from "../Common/ProgressButton";
import BarnAutoComplete from "../barn/BarnAutoComplete";
import { IBarn } from "../../../types/barn";
import { useAppContext } from "../../../contexts/useStorageContext";

export default function PenFormDialog({
  farmId,
  barnId,
  pen,
  open,
  onClose,
  onUpdated,
  onNewCreated,
}: {
  farmId: string;
  barnId?: string;
  pen?: IPen;
  open: boolean;
  onClose: () => void;
  onUpdated?: (updateInfo: IPen) => void;
  onNewCreated?: (created: IPen) => void;
}): React.ReactElement {
  const { state } = useAppContext();
  const { currentCompany } = state;
  const [editingPen, setEditingPen] = React.useState<IPen>(pen || {});
  const [processing, setProcessing] = React.useState(false);

  const handleCreate = async () => {
    if (!farmId || !editingPen.name || !editingPen.barn?._id) return;
    setProcessing(true);
    const result = await penService.addNewPen(farmId, editingPen.barn?._id, editingPen);
    if (result?.data) {
      onNewCreated && onNewCreated(result.data);
    }
    setProcessing(false);
  };

  const handleUpdate = async () => {
    if (!farmId || !editingPen.name || !editingPen.barn?._id) return;
    setProcessing(true);
    const result = await penService.updatePen(currentCompany?._id || "", farmId, editingPen);
    if (result?.data) {
      onUpdated && onUpdated(editingPen);
    }
    setProcessing(false);
  };

  return (
    <>
      <Dialog open={open} maxWidth="xs">
        <Box sx={{ background: "white", width: "1000px", maxWidth: "100%" }}>
          <Box p={2} pb={0} sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">{pen ? "Pen Details" : "New Pen"}</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box p={2}>
            <Box>
              <Stack spacing={3}>
                <TextField
                  value={editingPen.name || ""}
                  onChange={(e) => setEditingPen({ ...editingPen, name: e.target.value })}
                  label="Pen Name"
                  fullWidth
                  size="small"
                />
                <BarnAutoComplete
                  label="Barn"
                  selectedId={barnId}
                  farmId={farmId}
                  value={editingPen.barn}
                  onChange={(b) => setEditingPen({ ...editingPen, barn: b || undefined })}
                  fullWidth
                />
                <Stack direction="row" spacing={2}>
                  <TextField
                    value={editingPen.stallCount || undefined}
                    onChange={(e) => setEditingPen({ ...editingPen, stallCount: Number(e.target.value) })}
                    label="Number of stalls"
                    fullWidth
                    size="small"
                    type="number"
                  />
                  <TextField
                    value={editingPen.expectedAsFedAmount || undefined}
                    onChange={(e) => setEditingPen({ ...editingPen, expectedAsFedAmount: Number(e.target.value) })}
                    label="Expected Fed Amount"
                    fullWidth
                    size="small"
                    type="number"
                  />
                </Stack>
              </Stack>
              <Box mt={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
                {pen ? (
                  <ProgressButton
                    onClick={() => !processing && handleUpdate()}
                    title="Save Changes"
                    disabled={!editingPen.name || !editingPen.barn || processing}
                    inProgress={processing}
                    size="small"
                  />
                ) : (
                  <ProgressButton
                    disabled={!editingPen.name || !editingPen.barn || processing}
                    onClick={() => !processing && handleCreate()}
                    title="Create Pen"
                    inProgress={processing}
                    size="small"
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
