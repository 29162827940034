/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from "@mui/material/Box";
import React from "react";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { Button, Paper, TextField } from "@mui/material";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { IDiet } from "../../../types/diet";
import DietTypeSelect from "./DietTypeSelect";
import ProgressButton from "../Common/ProgressButton";
import TaskIcon from "@mui/icons-material/Task";
import dietService from "../../../service/diet.service";
import { useAppContext } from "../../../contexts/useStorageContext";
import AnyAssetFileUploader from "../Common/AnyAssetFileUploader";
import { getIntakeRecordDateString } from "../../../helpers/formatTime";
import DietLogsPopover from "./DietLogsPopover/DietLogsPopover";
import DietSamplesPopover from "./DietSamplesPopover";
import { uniqBy } from "lodash";

export default function DietFormDialog({
  open,
  onClose,
  diet,
  onCreated,
  onUpdated,
}: {
  open: boolean;
  diet?: IDiet;
  onClose: () => void;
  onCreated: (created: IDiet) => void;
  onUpdated?: (updated: IDiet) => void;
}): React.ReactElement {
  const { state } = useAppContext();
  const { currentCompany } = state;

  const [editingDiet, setEditingDiet] = React.useState<IDiet>(diet || {});
  const [processing, setProcessing] = React.useState(false);
  const [openUploadDialog, setOpenUploadDialog] = React.useState(false);

  const handleCreate = async () => {
    if (!editingDiet.name || !editingDiet.type) return;
    setProcessing(true);
    const result = await dietService.addNewDiet(
      editingDiet,
      currentCompany?._id
    );
    if (result?.data) {
      onCreated && onCreated(result.data);
    }
    setProcessing(false);
  };

  const handleUpdate = async () => {
    if (!editingDiet.name || !editingDiet.type) return;
    setProcessing(true);
    const result = await dietService.updateDiet(
      currentCompany?._id || "",
      diet?._id || "",
      editingDiet
    );
    if (result?.data) {
      onUpdated && onUpdated(editingDiet);
    }
    setProcessing(false);
  };

  return (
    <>
      <Dialog open={open} maxWidth="sm">
        <Box sx={{ background: "white", width: "1000px", maxWidth: "100%" }}>
          <Box
            p={2}
            pb={0}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography variant="h6">
              {diet ? "Diet Information" : "New Diet"}
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box p={2}>
            <Stack spacing={3}>
              <TextField
                label="Diet Name"
                value={editingDiet.name || ""}
                onChange={(e) =>
                  setEditingDiet({ ...editingDiet, name: e.target.value })
                }
                placeholder="Diet Name"
                fullWidth
                size="small"
              />
              <DietTypeSelect
                label="Diet Type"
                value={editingDiet.type}
                onChange={(val) =>
                  setEditingDiet({ ...editingDiet, type: val })
                }
              />
              <TextField
                value={editingDiet.description}
                onChange={(e) =>
                  setEditingDiet({
                    ...editingDiet,
                    description: e.target.value || undefined,
                  })
                }
                label="Description"
                multiline
                maxRows={4}
              />
            </Stack>
            <Box mt={3} sx={{ display: "flex", alignItems: "center" }}>
              {editingDiet.docUrl ? (
                <Paper
                  sx={{
                    px: 1,
                    color: "primary.main",
                    display: "flex",
                    alignItems: "center",
                    border: "solid 1px #eee",
                    textDecoration: "underline",
                  }}
                >
                  <TaskIcon sx={{ mr: 1 }} />
                  <a href={editingDiet.docUrl} target="_blank">
                    Current Doc
                  </a>

                  <IconButton
                    onClick={() =>
                      setEditingDiet({ ...editingDiet, docUrl: "" })
                    }
                    size="small"
                    sx={{ ml: 1 }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Paper>
              ) : (
                <Button
                  onClick={() => setOpenUploadDialog(true)}
                  size="small"
                  startIcon={<ReceiptLongIcon />}
                  color="inherit"
                  variant="outlined"
                >
                  Attach Document
                </Button>
              )}
              <DietLogsPopover diet={editingDiet} />
              <DietSamplesPopover
                diet={editingDiet}
                onAdd={(added) => {
                  setEditingDiet({
                    ...editingDiet,
                    samples: uniqBy(
                      [...(editingDiet.samples || []), ...added],
                      (v) => v._id
                    ),
                  });
                }}
              />

              <div style={{ flex: 1 }} />

              {diet ? (
                <ProgressButton
                  onClick={() => !processing && handleUpdate()}
                  title="Save"
                  inProgress={processing}
                  size="small"
                />
              ) : (
                <ProgressButton
                  disabled={!editingDiet.name || !editingDiet.type}
                  onClick={() => !processing && handleCreate()}
                  title="Create"
                  inProgress={processing}
                  size="small"
                />
              )}
            </Box>
          </Box>
          {openUploadDialog && (
            <AnyAssetFileUploader
              showDescriptionField
              descriptionFieldLabel="Description (optional)"
              descriptionFieldPlaceholder="Short details of diet"
              open
              onClose={() => setOpenUploadDialog(false)}
              onSave={(url, description) => {
                setEditingDiet({
                  ...editingDiet,
                  docUrl: url,
                  changeLogs: [
                    {
                      date: getIntakeRecordDateString(new Date()),
                      description,
                      docUrl: url,
                    },
                    ...(editingDiet.changeLogs || []),
                  ],
                });
                setOpenUploadDialog(false);
              }}
            />
          )}
        </Box>
      </Dialog>
    </>
  );
}
