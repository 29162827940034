/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from "@mui/material/Box";
import React from "react";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import LunchDiningIcon from "@mui/icons-material/LunchDining";
import UploadIcon from "@mui/icons-material/Upload";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import {
  Button,
  Chip,
  CircularProgress,
  InputAdornment,
  InputBase,
} from "@mui/material";
import { IBarn, ITemperatureGroup } from "../../../types/barn";
import { useAppContext } from "../../../contexts/useStorageContext";
import { FarmContext } from "../../pages/farms/FarmContext";
import { getIntakeRecordDateString } from "../../../helpers/formatTime";
import ProgressButton from "../Common/ProgressButton";
import intakeService from "../../../service/intake.service";
import barnService from "../../../service/barn.service";
import WeatherAutocomplete from "../Common/WeatherAutocomplete";

export default function BarnDailyRecordDialog({
  barn,
  open,
  onClose,
}: {
  barn: IBarn;
  open: boolean;
  onClose: () => void;
}): React.ReactElement {
  const { state } = useAppContext();
  const { currentCompany } = state;
  const farmContext = React.useContext(FarmContext);
  const [selectedDate, setSelectedDate] = React.useState(
    getIntakeRecordDateString(new Date())
  );
  const [processing, setProcessing] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [tempGroup, setTempGroup] = React.useState<ITemperatureGroup>({});
  const [elementKey, setElementKey] = React.useState(0);

  const handleSave = async () => {
    if (!selectedDate) return;
    setProcessing(true);
    const result = await barnService.saveBarnTempRecord(
      currentCompany?._id || "",
      barn._id || "",
      selectedDate,
      tempGroup
    );
    setProcessing(false);
    if (result?.data) {
      onClose();
    }
  };

  const fetchRecord = async () => {
    if (!currentCompany?._id || !barn._id || !selectedDate) return;
    const childPen = farmContext.pens?.find((p) => p.barn?._id == barn._id);
    if (!childPen) return;
    setLoading(true);
    const result = await intakeService.getIntakeRecord(
      currentCompany?._id || "",
      childPen._id || "",
      selectedDate || ""
    );
    setLoading(false);
    if (result?.data) {
      const {
        dayInTemp,
        dayOutTemp,
        nightInTemp,
        nightOutTemp,
        dayWeatherType,
        nightWeatherType,
        weatherDescription,
      } = result.data;
      setTempGroup({
        dayInTemp,
        dayOutTemp,
        nightInTemp,
        nightOutTemp,
        dayWeatherType,
        nightWeatherType,
        weatherDescription,
      });
    } else {
      setTempGroup({});
      setElementKey(elementKey + 1);
    }
  };

  React.useEffect(() => {
    fetchRecord();
  }, [selectedDate]);

  return (
    <>
      <Dialog open={open} maxWidth="xs">
        <Box sx={{ background: "white", width: "1000px", maxWidth: "100%" }}>
          <Box
            p={2}
            pt={1}
            pb={0}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography
              variant="h6"
              sx={{ display: "flex", alignItems: "flex-end" }}
            >
              <Box
                sx={{
                  display: { xs: "none", sm: "flex" },
                  alignItems: "flex-end",
                }}
              >
                Daily Temperature Record
                <Box sx={{ mb: "2px", opacity: 0.7, fontSize: "16px", mx: 1 }}>
                  of
                </Box>
              </Box>

              <Box sx={{ color: "secondary.main" }}>{barn.name}</Box>
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box p={2}>
            {loading ? (
              <Box
                sx={{
                  background: "#fffb",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "150px",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Stack spacing={2} sx={{ position: "relative" }} key={elementKey}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date"
                    value={dayjs(selectedDate)}
                    onChange={(newValue) =>
                      setSelectedDate(
                        getIntakeRecordDateString(newValue?.toDate())
                      )
                    }
                    slotProps={{ textField: { size: "small" } }}
                  />
                </LocalizationProvider>
                <Stack direction="row" spacing={2}>
                  <TextField
                    value={tempGroup.dayInTemp}
                    onChange={(e) =>
                      setTempGroup({
                        ...tempGroup,
                        dayInTemp: Number(e.target.value) || undefined,
                      })
                    }
                    type="number"
                    size="small"
                    label="Day Inside"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">{"°C"}</InputAdornment>
                      ),
                    }}
                    sx={{ flex: 1, mr: "4px" }}
                    fullWidth
                  />
                  <TextField
                    fullWidth
                    value={tempGroup.nightInTemp}
                    onChange={(e) =>
                      setTempGroup({
                        ...tempGroup,
                        nightInTemp: Number(e.target.value) || undefined,
                      })
                    }
                    type="number"
                    size="small"
                    label="Night Inside"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">{"°C"}</InputAdornment>
                      ),
                    }}
                    sx={{ flex: 1, mr: "4px" }}
                  />
                </Stack>
                <Stack direction="row" spacing={2}>
                  <TextField
                    fullWidth
                    value={tempGroup.dayOutTemp}
                    onChange={(e) =>
                      setTempGroup({
                        ...tempGroup,
                        dayOutTemp: Number(e.target.value) || undefined,
                      })
                    }
                    type="number"
                    size="small"
                    label="Day Outside"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">{"°C"}</InputAdornment>
                      ),
                    }}
                    sx={{ flex: 1, mr: "4px" }}
                  />
                  <TextField
                    fullWidth
                    value={tempGroup.nightOutTemp}
                    onChange={(e) =>
                      setTempGroup({
                        ...tempGroup,
                        nightOutTemp: Number(e.target.value) || undefined,
                      })
                    }
                    type="number"
                    size="small"
                    label="Night Outside"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">{"°C"}</InputAdornment>
                      ),
                    }}
                    sx={{ flex: 1, mr: "4px" }}
                  />
                </Stack>
                <Stack direction="row" spacing={2}>
                  <WeatherAutocomplete
                    label="Day Weather"
                    value={tempGroup.dayWeatherType}
                    onChange={(changed) =>
                      setTempGroup({
                        ...tempGroup,
                        dayWeatherType: changed || undefined,
                      })
                    }
                    fullWidth
                  />
                  <WeatherAutocomplete
                    label="Night Weather"
                    value={tempGroup.nightWeatherType}
                    onChange={(changed) =>
                      setTempGroup({
                        ...tempGroup,
                        nightWeatherType: changed || undefined,
                      })
                    }
                    fullWidth
                  />
                </Stack>
                <TextField
                  multiline
                  rows={3}
                  label="Weather Description"
                  value={tempGroup.weatherDescription}
                  onChange={(e) =>
                    setTempGroup({
                      ...tempGroup,
                      weatherDescription: e.target.value || undefined,
                    })
                  }
                />
              </Stack>
            )}
            <Box
              mt={2}
              sx={{
                display: "flex",
                alignItems: "flex-end",
                flexWrap: "wrap",
                borderTop: "solid 1px #eee",
              }}
            >
              <Box sx={{ flex: 1 }} />
              <ProgressButton
                inProgress={processing}
                disabled={processing}
                onClick={() => handleSave()}
                title="Save"
                sx={{ mt: "12px" }}
              />
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
