/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from "@mui/material/Box";
import React from "react";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import { IFarm } from "../../../types/farm";
import FarmDetailForm from "./FarmDetailForm";

export default function FarmFormDialog({
  companyId,
  farm,
  open,
  onClose,
  onUpdated,
  onNewCreated,
}: {
  companyId?: string;
  farm?: IFarm;
  open: boolean;
  onClose: () => void;
  onUpdated?: (updateInfo: IFarm) => void;
  onNewCreated?: (created: IFarm) => void;
}): React.ReactElement {
  return (
    <>
      <Dialog open={open} maxWidth="xs">
        <Box sx={{ background: "white", width: "1000px", maxWidth: "100%" }}>
          <Box p={2} pb={0} sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">{farm ? "Farm Details" : "New Farm"}</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box p={2}>
            <FarmDetailForm
              companyId={companyId}
              farm={farm}
              onNewCreated={(created) => onNewCreated && onNewCreated(created)}
              onUpdated={(updateInfo) => onUpdated && onUpdated(updateInfo)}
            />
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
