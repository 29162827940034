/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ITemperatureGroup } from "../types/barn";
import { IPenIntakeRecord } from "../types/dailyIntake";
import backendApi from "./backendApi";
import { showSnackbar } from "./snackbar.service";

const routePrefix = "intake";

export interface IAddFeedPayload {
  penId: string;
  date: string;
  timestamp: Date;
  dietId: string;
  fedAmount: number;
  refusedAmount?: number;
  companyId: string;
}

export default {
  async saveMultipleDayIntakeData(
    companyId: string,
    payload: IPenIntakeRecord[]
  ): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/save-multiple`, {
        companyId,
        intakeData: payload,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "Intake records are successfully saved"
      );
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async saveDayIntakeData(
    companyId: string,
    payload: IPenIntakeRecord
  ): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/save`, {
        companyId,
        intakeData: payload,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "Intake record is successfully saved"
      );
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async getIntakeRecord(
    companyId: string,
    penId: string,
    date: string
  ): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/get-record`, {
        companyId,
        penId,
        date,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async getPenRecordsByDateRange(payload: {
    companyId: string;
    penId?: string;
    penIds?: string[];
    from: string;
    to: string;
  }): Promise<IPenIntakeRecord[]> {
    try {
      const { companyId, penId, penIds, from, to } = payload;
      const result = await backendApi()?.post(
        `${routePrefix}/get-pen-records-by-date-range`,
        {
          companyId,
          penId,
          penIds,
          from,
          to,
        }
      );
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
      }
      return [];
    }
  },
  async getCowRecordsByDateRange(
    companyId: string,
    cowId: string,
    from: string,
    to: string
  ): Promise<any> {
    try {
      const result = await backendApi()?.post(
        `${routePrefix}/get-cow-records-by-date-range`,
        {
          companyId,
          cowId,
          from,
          to,
        }
      );
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
};
