/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import Box from "@mui/material/Box";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { styled } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ICow, ICowHealthRecord } from "../../../types/cow";
import AddIconButton from "../Common/AddIconButton";
import { Button, Chip, Stack, TextField } from "@mui/material";
import dayjs from "dayjs";
import {
  fDate,
  fDateTime,
  getIntakeRecordDateString,
} from "../../../helpers/formatTime";
import ProgressButton from "../Common/ProgressButton";
import cowService from "../../../service/cow.service";
import { useAppContext } from "../../../contexts/useStorageContext";

const InfoTag = styled(Box)(() => ({
  background: "#dedede",
  padding: "2px 5px",
  borderRadius: "5px",
  marginRight: "5px",
  display: "flex",
  alignItems: "center",
  fontSize: "13px",
}));

export default function CowHealthRecordsPanel({
  cow,
  onUpdateCow,
}: {
  cow: ICow;
  onUpdateCow: (updated: ICow) => void;
}) {
  const initialHealthRecordCreation = {
    cow: cow,
    startDate: getIntakeRecordDateString(new Date()),
  };
  const { state } = useAppContext();
  const { currentCompany } = state;
  const [creationMode, setCreationMode] = React.useState(false);
  const [healthRecord, setHealthRecord] = React.useState<
    Partial<ICowHealthRecord>
  >(initialHealthRecordCreation);
  const [processing, setProcessing] = React.useState(false);

  const handleAdd = async () => {
    if (!currentCompany?._id || !cow.farm?._id) return;
    setProcessing(true);
    const result = await cowService.addHealthRecord(
      currentCompany?._id,
      cow.farm?._id,
      healthRecord as ICowHealthRecord
    );
    const createdRecord = result.data;
    onUpdateCow({
      ...cow,
      healthIssues: [createdRecord, ...(cow.healthIssues || [])],
    });
    setProcessing(false);
    setCreationMode(false);
  };

  React.useEffect(() => {
    if (creationMode) {
      setHealthRecord(initialHealthRecordCreation);
    }
  }, [creationMode]);

  return (
    <Box p={2} sx={{ minHeight: "340px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          borderBottom: "solid 1px #ddd",
          pb: "4px",
          width: "300px",
        }}
      >
        <AddIconButton onClick={() => setCreationMode(true)} />
        <Box>
          Health Issue Records
          <Chip
            label={cow.healthIssues?.length || 0}
            size="small"
            sx={{ height: "20px", ml: 1 }}
          />
        </Box>
        <div style={{ flex: 1 }} />
      </Box>
      {creationMode ? (
        <Stack spacing={2} sx={{ pt: 2 }}>
          <TextField
            fullWidth
            size="small"
            label="Health issue (in short)"
            value={healthRecord.healthIssue || ""}
            onChange={(e) =>
              setHealthRecord({ ...healthRecord, healthIssue: e.target.value })
            }
          />
          <TextField
            label="Detailed Description"
            fullWidth
            multiline
            minRows={3}
            value={healthRecord.description || ""}
            onChange={(e) =>
              setHealthRecord({ ...healthRecord, description: e.target.value })
            }
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={
                healthRecord?.startDate ? dayjs(healthRecord?.startDate) : null
              }
              onChange={(newValue) =>
                setHealthRecord({
                  ...healthRecord,
                  startDate:
                    getIntakeRecordDateString(newValue?.toDate()) || undefined,
                })
              }
              slotProps={{ textField: { size: "small" } }}
            />
            <DatePicker
              label="End Date"
              value={
                healthRecord?.endDate ? dayjs(healthRecord?.endDate) : null
              }
              onChange={(newValue) =>
                setHealthRecord({
                  ...healthRecord,
                  endDate:
                    getIntakeRecordDateString(newValue?.toDate()) || undefined,
                })
              }
              slotProps={{ textField: { size: "small" } }}
            />
          </LocalizationProvider>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={() => setCreationMode(false)}
              variant="outlined"
              size="small"
              color="inherit"
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <ProgressButton
              onClick={() => handleAdd()}
              inProgress={processing}
              disabled={!healthRecord.cow || !healthRecord.healthIssue}
              title="Add"
              size="small"
            />
          </Box>
        </Stack>
      ) : (
        <Box>
          {cow.healthIssues?.length ? (
            <Box>
              {cow.healthIssues.map((healthIssue, i) => (
                <Box key={i} py={1} sx={{ borderBottom: "solid 1px #ddd" }}>
                  <Box
                    sx={{
                      fontWeight: "500",
                      color: "#007ad0",
                      marginBottom: "3px",
                    }}
                  >
                    {healthIssue.healthIssue || ""}
                  </Box>
                  <Box
                    sx={{
                      fontSize: "14px",
                      opacity: "0.6",
                      marginBottom: "6px",
                    }}
                  >
                    {healthIssue.description || ""}
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {healthIssue.startDate ? (
                      <InfoTag>
                        <AccessTimeIcon sx={{ fontSize: "14px", mr: "2px" }} />
                        <div className="exact-date">
                          {fDate(healthIssue.startDate)}
                        </div>
                      </InfoTag>
                    ) : null}
                    {healthIssue.endDate ? (
                      <InfoTag>
                        <AccessTimeIcon sx={{ fontSize: "14px", mr: "2px" }} />
                        <div className="exact-date">
                          {fDate(healthIssue.endDate)}
                        </div>
                      </InfoTag>
                    ) : null}
                  </Box>
                </Box>
              ))}
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "300px",
              }}
            >
              No records...
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}
