/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import intakeService from "../../../../service/intake.service";
import { useAppContext } from "../../../../contexts/useStorageContext";
import { getIntakeRecordDateString } from "../../../../helpers/formatTime";
import WhitePanel from "../../Common/WhitePanel";
import { IPenIntakeRecord } from "../../../../types/dailyIntake";
import { sortBy, uniqBy } from "lodash";
import { IPen } from "../../../../types/pen";
import { GridEnrichedColDef } from "@mui/x-data-grid-pro";
import barnService from "../../../../service/barn.service";
import farmService from "../../../../service/farm.service";
import { IFarm } from "../../../../types/farm";
import { IBarn } from "../../../../types/barn";
import TrendArrow from "./TrendArrow";
import Box from "@mui/material/Box";
import RecordsMiniChart, { RecordsMiniChartContext } from "./RecordsMiniChart";
import MultipleFarmAutoComplete from "../../FarmAutocomplete";
import MultipleBarnAutoComplete from "../../../pages/farms/SwitchAndFilterPanel/BarnAutocomplete";
import PenAutoComplete from "../../../pages/farms/SwitchAndFilterPanel/PenAutocomplete";
import { Badge, Checkbox, FormControlLabel, TextField } from "@mui/material";
import LightTooltip from "../../Common/LightTooltip";
import TrendArrowTooltip from "./TrendArrowTooltip";
import CustomMuiDatagrid, { TableIds } from "../../Common/CustomMuiDatagrid";
import DashboardTableSettingsPopover from "./DashboardTableSettingsPopover";

interface PenIntakeOverviewRow {
  farm?: IFarm;
  barn?: IBarn;
  pen?: IPen;
  oneDayPercentage?: number;
  aWeekPercentage?: number;
  threeWeeksPercentage?: number;
  records?: IPenIntakeRecord[];
}

const TargetPeriodDays = 21;

const getClosestPastDate = (dates: string[], targetDate: string) => {
  let smallestDifference = 99999999999;
  let currentClosestDate = "";
  dates.map((dateString) => {
    const difference = Math.abs(
      new Date(targetDate).getTime() - new Date(dateString).getTime()
    );
    if (difference < 0) return;
    if (difference < smallestDifference) {
      smallestDifference = difference;
      currentClosestDate = dateString;
    }
  });
  return currentClosestDate;
};

const getFedAmountOfDate = (records: IPenIntakeRecord[], date: string) => {
  const targetRecord = records.find(
    (record) =>
      record.date ==
      getClosestPastDate(
        records.map((r) => r.date || ""),
        date
      )
  );
  return [targetRecord?.fedAmount || 0, targetRecord?.refusedAmount || 0];
};

const checkRowHasIssue = (row: PenIntakeOverviewRow, threshold?: number) => {
  return (
    threshold &&
    (Math.abs(row.oneDayPercentage || 0) > threshold ||
      Math.abs(row.aWeekPercentage || 0) > threshold ||
      Math.abs(row.threeWeeksPercentage || 0) > threshold)
  );
};

export default function PensIntakeTrendsOverviewTable() {
  const { state } = useAppContext();
  const { currentCompany } = state;
  const [intakeOverviewRows, setIntakeOverviewRows] = React.useState<
    PenIntakeOverviewRow[]
  >([]);
  const [loading, setLoading] = React.useState(false);
  const [allFarms, setAllFarms] = React.useState<IFarm[]>([]);
  const [allBarns, setAllBarns] = React.useState<IBarn[]>([]);
  const [allPens, setAllPens] = React.useState<IPen[]>([]);
  const [selectedFarms, setSelectedFarms] = React.useState<IFarm[]>([]);
  const [selectedBarns, setSelectedBarns] = React.useState<IBarn[]>([]);
  const [selectedPens, setSelectedPens] = React.useState<IPen[]>([]);
  const [percentageThreshold, setPercentageThreshold] = React.useState<
    number | undefined
  >(5);
  const [onlyHighlighted, setOnlyHighlighted] = React.useState(false);
  const [highlightedRowCount, setHighlightedRowCount] = React.useState(0);

  const columns: GridEnrichedColDef<PenIntakeOverviewRow, any, any>[] = [
    {
      field: "pen",
      headerName: "Pen",
      width: 150,
      editable: false,
      valueGetter: (params: any) => {
        return params.row.pen?.name;
      },
    },
    {
      field: "farm",
      headerName: "Farm",
      width: 150,
      editable: false,
      valueGetter: (params: any) => {
        return params.row.farm?.name;
      },
    },
    {
      field: "barn",
      headerName: "Barn",
      width: 150,
      editable: false,
      valueGetter: (params: any) => {
        return params.row.barn?.name;
      },
    },

    {
      field: "oneDayPercentage",
      headerName: "FT 1 Day",
      width: 150,
      editable: false,
      renderCell: (params: any) => {
        const row: PenIntakeOverviewRow = params.row;
        const todayDate = new Date();
        const today = getIntakeRecordDateString(todayDate);
        const yesterday = getIntakeRecordDateString(
          new Date(todayDate.getTime() - 86400000)
        );
        const fedAmountAvailableRecords =
          row.records?.filter((r) => r.date && r.fedAmount) || [];
        const todayFeed = getFedAmountOfDate(fedAmountAvailableRecords, today);

        const yesterdayFeed = getFedAmountOfDate(
          fedAmountAvailableRecords,
          yesterday
        );

        return (
          <LightTooltip
            title={
              <TrendArrowTooltip
                before={{
                  fed: yesterdayFeed[0],
                  refused: yesterdayFeed[1],
                  title: "1 Day Before",
                }}
                now={{
                  fed: todayFeed[0],
                  refused: todayFeed[1],
                  title: "Today",
                }}
              />
            }
            placement="bottom"
          >
            <Box>
              <TrendArrow value={params.value} unit="%" />
            </Box>
          </LightTooltip>
        );
      },
    },
    {
      field: "aWeekPercentage",
      headerName: "FT 1 Week",
      width: 150,
      editable: false,
      renderCell: (params: any) => {
        const row: PenIntakeOverviewRow = params.row;
        const todayDate = new Date();
        const today = getIntakeRecordDateString(todayDate);
        const aWeekAgo = getIntakeRecordDateString(
          new Date(todayDate.getTime() - 86400000 * 7)
        );
        const fedAmountAvailableRecords =
          row.records?.filter((r) => r.date && r.fedAmount) || [];
        const todayFeed = getFedAmountOfDate(fedAmountAvailableRecords, today);

        const aWeekAgoFeed = getFedAmountOfDate(
          fedAmountAvailableRecords,
          aWeekAgo
        );

        return (
          <LightTooltip
            title={
              <TrendArrowTooltip
                before={{
                  fed: aWeekAgoFeed[0],
                  refused: aWeekAgoFeed[1],
                  title: "A Week Before",
                }}
                now={{
                  fed: todayFeed[0],
                  refused: todayFeed[1],
                  title: "Today",
                }}
              />
            }
            placement="bottom"
          >
            <Box>
              <TrendArrow value={params.value} unit="%" />
            </Box>
          </LightTooltip>
        );
      },
    },
    {
      field: "threeWeeksPercentage",
      headerName: "FT 3 Weeks",
      width: 150,
      editable: false,
      renderCell: (params: any) => {
        const row: PenIntakeOverviewRow = params.row;
        const todayDate = new Date();
        const today = getIntakeRecordDateString(todayDate);
        const threeWeeksAgo = getIntakeRecordDateString(
          new Date(todayDate.getTime() - 86400000 * 21)
        );
        const fedAmountAvailableRecords =
          row.records?.filter((r) => r.date && r.fedAmount) || [];
        const todayFeed = getFedAmountOfDate(fedAmountAvailableRecords, today);

        const threeWeeksAgoFeed = getFedAmountOfDate(
          fedAmountAvailableRecords,
          threeWeeksAgo
        );

        return (
          <LightTooltip
            title={
              <TrendArrowTooltip
                before={{
                  fed: threeWeeksAgoFeed[0],
                  refused: threeWeeksAgoFeed[1],
                  title: "3 Weeks Before",
                }}
                now={{
                  fed: todayFeed[0],
                  refused: todayFeed[1],
                  title: "Today",
                }}
              />
            }
            placement="bottom"
          >
            <Box>
              <TrendArrow value={params.value} unit="%" />
            </Box>
          </LightTooltip>
        );
      },
    },
    {
      field: "expectedAsFedAmount",
      headerName: "Ration",
      width: 120,
      editable: false,
      valueGetter: (params: any) => params.row?.pen?.expectedAsFedAmount,
    },
    {
      field: "rfi",
      headerName: "RFI",
      width: 250,
      editable: false,
      renderCell: (params: any) => {
        return (
          <Box>
            <RecordsMiniChart
              context={RecordsMiniChartContext.RFI}
              records={params.row.records}
              id={params.row.pen?._id}
              expectedAmount={params.row?.pen?.expectedAsFedAmount}
            />
          </Box>
        );
      },
    },
    {
      field: "ph",
      headerName: "PH",
      width: 250,
      editable: false,
      renderCell: (params: any) => {
        return (
          <Box>
            <RecordsMiniChart
              context={RecordsMiniChartContext.PH}
              records={params.row.records}
              id={params.row.pen?._id}
              expectedAmount={params.row?.pen?.expectedAsFedAmount}
            />
          </Box>
        );
      },
    },
    {
      field: "looseFeedAmount",
      headerName: "Free Choice",
      width: 250,
      editable: false,
      renderCell: (params: any) => {
        return (
          <Box>
            <RecordsMiniChart
              context={RecordsMiniChartContext.LooseFeed}
              records={params.row.records}
              id={params.row.pen?._id}
              expectedAmount={params.row?.pen?.expectedAsFedAmount}
            />
          </Box>
        );
      },
    },
  ];

  const generateAnalysis = (
    rawRecords: IPenIntakeRecord[],
    farms: IFarm[],
    barns: IBarn[]
  ) => {
    const uniqPens: IPen[] = uniqBy(
      rawRecords.map((r) => r.pen as IPen),
      (pen) => pen?._id
    );
    setAllPens(uniqPens);
    const penOverviews: PenIntakeOverviewRow[] = [];
    uniqPens.map((pen) => {
      const intakeOverviewRow: PenIntakeOverviewRow = {
        pen: pen,
      };

      const penRecords = sortBy(
        rawRecords.filter((r) => r.pen?._id == pen?._id),
        "date"
      ).map((r) => ({
        ...r,
        fedAmount: Number((r.fedAmount || 0).toFixed(1)),
        refusedAmount: Number((r.refusedAmount || 0).toFixed(1)),
        cowAverageFedAmount: Number((r.cowAverageFedAmount || 0).toFixed(1)),
        cowAverageRefusedAmount: Number(
          (r.cowAverageRefusedAmount || 0).toFixed(1)
        ),
      }));
      intakeOverviewRow.records = penRecords;

      const dates = penRecords.map((r) => r.date);
      const todayDate = new Date();
      const today = getIntakeRecordDateString(todayDate);
      const yesterday = getIntakeRecordDateString(
        new Date(todayDate.getTime() - 86400000)
      );
      const aWeekAgo = getIntakeRecordDateString(
        new Date(todayDate.getTime() - 86400000 * 7)
      );
      const threeWeeksAgo = getIntakeRecordDateString(
        new Date(todayDate.getTime() - 86400000 * 21)
      );

      const fedAmountAvailableRecords = penRecords.filter(
        (r) => r.date && r.fedAmount
      );

      const todayFeed = getFedAmountOfDate(fedAmountAvailableRecords, today);
      const todayFedAmount = todayFeed[0] - todayFeed[1];

      const yesterdayFeed = getFedAmountOfDate(
        fedAmountAvailableRecords,
        yesterday
      );
      const yesterdayFedAmount = yesterdayFeed[0] - yesterdayFeed[1];

      const aWeekAgoFeed = getFedAmountOfDate(
        fedAmountAvailableRecords,
        aWeekAgo
      );
      const aWeekAgoFedAmount = aWeekAgoFeed[0] - aWeekAgoFeed[1];

      const threeWeeksAgoFeed = getFedAmountOfDate(
        fedAmountAvailableRecords,
        threeWeeksAgo
      );
      const threeWeeksAgoFedAmount =
        threeWeeksAgoFeed[0] - threeWeeksAgoFeed[1];

      const oneDayPercentage =
        !todayFedAmount || !yesterdayFedAmount
          ? 0
          : ((todayFedAmount - yesterdayFedAmount) * 100) / yesterdayFedAmount;
      const aWeekPercentage =
        !todayFedAmount || !aWeekAgoFedAmount
          ? 0
          : ((todayFedAmount - aWeekAgoFedAmount) * 100) / aWeekAgoFedAmount;
      const threeWeeksPercentage =
        !todayFedAmount || !threeWeeksAgoFedAmount
          ? 0
          : ((todayFedAmount - threeWeeksAgoFedAmount) * 100) /
            threeWeeksAgoFedAmount;
      intakeOverviewRow.oneDayPercentage = Number(oneDayPercentage.toFixed(1));
      intakeOverviewRow.aWeekPercentage = Number(aWeekPercentage.toFixed(1));
      intakeOverviewRow.threeWeeksPercentage = Number(
        threeWeeksPercentage.toFixed(1)
      );

      intakeOverviewRow.farm = farms.find((f) => f._id == pen?.farm);
      intakeOverviewRow.barn = barns.find((b) => b._id == pen?.barn);
      penOverviews.push(intakeOverviewRow);
    });
    setIntakeOverviewRows(penOverviews);
  };

  const getFilteredRows = () => {
    return intakeOverviewRows.filter((row) => {
      return (
        (!selectedFarms?.length ||
          selectedFarms.some((f) => f._id == row.farm?._id)) &&
        (!selectedBarns?.length ||
          selectedBarns.some((f) => f._id == row.barn?._id)) &&
        (!selectedPens?.length ||
          selectedPens.some((f) => f._id == row.pen?._id)) &&
        (!onlyHighlighted || checkRowHasIssue(row, percentageThreshold))
      );
    });
  };

  const fetchPensIntakeTrends = async () => {
    if (!currentCompany?._id) return;
    setLoading(true);
    const toDate = getIntakeRecordDateString(new Date());
    const fromDate = getIntakeRecordDateString(
      new Date(new Date().getTime() - TargetPeriodDays * 86400000)
    );
    const farms = await farmService.farchAllCompanyFarms(currentCompany._id);
    const barns = await barnService.fetchBarns("", currentCompany._id);
    setAllFarms(farms.data);
    setAllBarns(barns.data);
    const recordsResult = await intakeService.getPenRecordsByDateRange({
      companyId: currentCompany._id,
      to: toDate,
      from: fromDate,
    });
    generateAnalysis(recordsResult, farms.data || [], barns.data || []);
    setLoading(false);
  };

  React.useEffect(() => {
    fetchPensIntakeTrends();
  }, [currentCompany]);

  React.useEffect(() => {
    const count =
      intakeOverviewRows.filter((row) =>
        checkRowHasIssue(row, percentageThreshold)
      )?.length || 0;
    setHighlightedRowCount(count);
  }, [intakeOverviewRows, percentageThreshold]);

  return (
    <WhitePanel
      sx={{ "& .MuiDataGrid-cell ": { overflow: "visible !important" } }}
    >
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        <Box mr={1}>
          <MultipleFarmAutoComplete
            options={allFarms || []}
            value={selectedFarms}
            onChange={(selected) => setSelectedFarms(selected || [])}
          />
        </Box>
        <Box mr={1}>
          <MultipleBarnAutoComplete
            options={allBarns || []}
            value={selectedBarns}
            onChange={(selected) => setSelectedBarns(selected || [])}
          />
        </Box>
        <Box mr={1}>
          <PenAutoComplete
            options={allPens || []}
            value={selectedPens}
            onChange={(selected) => setSelectedPens(selected || [])}
          />
        </Box>
        <TextField
          label="Highlight Percentage"
          type="number"
          size="small"
          placeholder="Highlight Percentage"
          value={percentageThreshold}
          onChange={(e) =>
            setPercentageThreshold(Number(e.target.value) || undefined)
          }
          sx={{ mr: 2 }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={onlyHighlighted}
              onChange={(e, checked) => setOnlyHighlighted(checked)}
            />
          }
          label={
            <Badge badgeContent={highlightedRowCount} color="warning">
              {"Filter highlighted"}
            </Badge>
          }
        />
        <div style={{ flex: 1 }} />
        <DashboardTableSettingsPopover />
      </Box>
      <CustomMuiDatagrid
        tableId={TableIds.DashboardTable}
        fixedHeight={500}
        loading={loading}
        getRowId={(row) => row.pen?._id || ""}
        rows={getFilteredRows()}
        columns={columns}
        disableSelectionOnClick
        rowHeight={50}
      />
    </WhitePanel>
  );
}
