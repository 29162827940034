/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from "@mui/material/Box";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import React from "react";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { ICow } from "../../../types/cow";
import ProgressButton from "../Common/ProgressButton";
import BarnAutoComplete from "../barn/BarnAutoComplete";
import cowService from "../../../service/cow.service";
import PenAutoComplete from "../pen/PenAutoComplete";
import BreedAutoComplete from "./BreedAutoComplete";
import GeneticProductionPotentialSelect from "./GeneticProductionPotentialSelect";
import LactationStateSelect from "./LactationStateSelect";
import { IBarn } from "../../../types/barn";
import { IPen } from "../../../types/pen";
import { useAppContext } from "../../../contexts/useStorageContext";
import Chip from "@mui/material/Chip";
import GeneralPopoverWrapper from "../Common/GeneralPopoverWrapper";
import CowHealthRecordsPanel from "./CowHealthRecordsPanel";

export default function CowFormDialog({
  farmId,
  barn,
  pen,
  cow,
  open,
  onClose,
  onUpdated,
  onNewCreated,
}: {
  farmId: string;
  barn?: IBarn;
  pen?: IPen;
  cow?: ICow;
  open: boolean;
  onClose: () => void;
  onUpdated?: (updateInfo: ICow) => void;
  onNewCreated?: (created: ICow) => void;
}): React.ReactElement {
  const { state } = useAppContext();
  const { currentCompany } = state;
  const [editingCow, setEditingCow] = React.useState<ICow>(
    cow || { pen, barn }
  );
  const [processing, setProcessing] = React.useState(false);

  const handleCreate = async () => {
    if (
      !farmId ||
      !editingCow.name ||
      !editingCow.barn?._id ||
      !editingCow.pen?._id
    )
      return;
    setProcessing(true);
    const result = await cowService.addNewCow(farmId, editingCow);
    if (result?.data) {
      onNewCreated && onNewCreated(result.data);
    }
    setProcessing(false);
  };

  const handleUpdate = async () => {
    if (
      !farmId ||
      !editingCow.name ||
      !editingCow.barn?._id ||
      !editingCow.pen?._id
    )
      return;
    setProcessing(true);
    const result = await cowService.updateCow(
      currentCompany?._id || "",
      farmId,
      editingCow
    );
    if (result?.data) {
      onUpdated && onUpdated(editingCow);
    }
    setProcessing(false);
  };

  React.useEffect(() => {
    if (!cow) return;
    setEditingCow(cow);
  }, [cow]);

  return (
    <>
      <Dialog open={open} maxWidth="xs">
        <Box sx={{ background: "white", width: "1000px", maxWidth: "100%" }}>
          <Box
            p={2}
            pb={0}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography variant="h6">
              {cow ? "Cow Details" : "New Cow"}
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box p={2}>
            <Box>
              <Stack spacing={3}>
                <TextField
                  label="Cow Name"
                  value={editingCow.name || ""}
                  onChange={(e) =>
                    setEditingCow({ ...editingCow, name: e.target.value })
                  }
                  placeholder="Cow Name"
                  fullWidth
                  size="small"
                />
                <Stack direction="row" spacing={1}>
                  <Box sx={{ flex: 1 }}>
                    <BarnAutoComplete
                      label="Barn"
                      farmId={farmId}
                      value={editingCow.barn}
                      onChange={(b) =>
                        setEditingCow({ ...editingCow, barn: b || undefined })
                      }
                      fullWidth
                    />
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <PenAutoComplete
                      label="Pen"
                      farmId={farmId}
                      value={editingCow.pen}
                      onChange={(p) =>
                        setEditingCow({ ...editingCow, pen: p || undefined })
                      }
                      fullWidth
                    />
                  </Box>
                </Stack>

                <BreedAutoComplete
                  label="Breed"
                  value={editingCow.breed}
                  onChange={(b) =>
                    setEditingCow({ ...editingCow, breed: b || undefined })
                  }
                  fullWidth
                />
                <Stack direction="row" spacing={1}>
                  <GeneticProductionPotentialSelect
                    value={editingCow.productionPotential}
                    onChange={(changed) =>
                      setEditingCow({
                        ...editingCow,
                        productionPotential: changed || undefined,
                      })
                    }
                  />
                  <LactationStateSelect
                    value={editingCow.lactationState}
                    onChange={(changed) =>
                      setEditingCow({
                        ...editingCow,
                        lactationState: changed || undefined,
                      })
                    }
                  />
                </Stack>

                <Stack direction="row" spacing={1}>
                  <TextField
                    size="small"
                    label="# of Lactations"
                    type="number"
                    value={editingCow.numberOfLactations}
                    onChange={(e) =>
                      setEditingCow({
                        ...editingCow,
                        numberOfLactations: Number(e.target.value) || undefined,
                      })
                    }
                    fullWidth
                  />
                  <TextField
                    size="small"
                    label="Milk Production"
                    type="number"
                    value={editingCow.milkProduction}
                    onChange={(e) =>
                      setEditingCow({
                        ...editingCow,
                        milkProduction: Number(e.target.value) || undefined,
                      })
                    }
                    fullWidth
                  />
                </Stack>
                {/* <TextField
                  value={editingCow.healthIssues}
                  onChange={(e) => setEditingCow({ ...editingCow, healthIssues: e.target.value || undefined })}
                  label="Health issues..."
                  multiline
                  maxRows={4}
                /> */}
              </Stack>
              <Box mt={3} sx={{ display: "flex", alignItems: "center" }}>
                <GeneralPopoverWrapper
                  transformVertical="bottom"
                  transformHorizontal="left"
                  anchorHorizontal="right"
                  triggerElement={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        background: "#ebebeb",
                        padding: "4px 7px",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                    >
                      <HealthAndSafetyIcon color="primary" sx={{ mr: "4px" }} />
                      Health Records
                      <Chip
                        label={editingCow.healthIssues?.length || 0}
                        size="small"
                        sx={{ height: "20px", ml: 1 }}
                      />
                    </Box>
                  }
                  popoverContent={
                    <CowHealthRecordsPanel
                      onUpdateCow={(updated) => onUpdated && onUpdated(updated)}
                      cow={editingCow}
                    />
                  }
                />

                <Box sx={{ flex: 1 }} />
                {cow ? (
                  <ProgressButton
                    onClick={() => !processing && handleUpdate()}
                    disabled={
                      !editingCow.name ||
                      !editingCow.barn ||
                      !editingCow.pen ||
                      !editingCow.name ||
                      processing ||
                      JSON.stringify(cow || {}) ==
                        JSON.stringify(editingCow || {})
                    }
                    title="Save Changes"
                    inProgress={processing}
                    size="small"
                  />
                ) : (
                  <ProgressButton
                    disabled={
                      !editingCow.name ||
                      !editingCow.barn ||
                      !editingCow.pen ||
                      !editingCow.name ||
                      processing
                    }
                    onClick={() => !processing && handleCreate()}
                    title="Create Cow"
                    inProgress={processing}
                    size="small"
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
