import { ILoggedInUserInfo } from "./auth";
import { ICompany } from "./company";
import { ICompanySettings } from "./companySettings";
import { IFarm } from "./farm";
import { INotification } from "./notification";
import { IUser } from "./user";

export interface StateType {
  openSideDrawer: boolean;
  loggedInUserInfo?: ILoggedInUserInfo;
  currentCompany?: ICompany;
  currentFarm?: IFarm;
  myProfile?: IUser;
  myCompanyProfile?: ICompany;
  myCompanySettings?: ICompanySettings;
  notifications?: INotification[];
}

export const actionTypes = {
  setMyProfile: "setMyProfile",
  setMyCompanyProfile: "setMyCompanyProfile",
  setMyCompanySettings: "setMyCompanySettings",
  setOpenSideDrawer: "setOpenSideDrawer",
  setLoggedInUserInfo: "setLoggedInUserInfo",
  setCurrentCompany: "setCurrentCompany",
  setCurrentFarm: "setCurrentFarm",
  setAllState: "setAllState",
  setNotifications: "setNotifications",
};

export type ActionType =
  | { type: typeof actionTypes.setMyProfile; value: IUser | undefined }
  | {
      type: typeof actionTypes.setNotifications;
      value: INotification[] | undefined;
    }
  | {
      type: typeof actionTypes.setMyCompanyProfile;
      value: ICompany | undefined;
    }
  | {
      type: typeof actionTypes.setMyCompanySettings;
      value: ICompanySettings | undefined;
    }
  | { type: typeof actionTypes.setOpenSideDrawer; value: boolean }
  | {
      type: typeof actionTypes.setLoggedInUserInfo;
      value: ILoggedInUserInfo | undefined;
    }
  | { type: typeof actionTypes.setCurrentCompany; value: ICompany | undefined }
  | { type: typeof actionTypes.setCurrentFarm; value: IFarm | undefined }
  | { type: typeof actionTypes.setAllState; value: StateType };

export interface ContextType {
  state: StateType;
  dispatch?: React.Dispatch<ActionType>;
}
