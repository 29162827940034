/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import CloseIcon from "@mui/icons-material/Close";
import { useDropzone } from "react-dropzone";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import * as React from "react";
import "react-image-crop/dist/ReactCrop.css";
import ProgressButton from "./ProgressButton";
import { Button, Paper, TextField } from "@mui/material";
import assetsService from "../../../service/assets.service";

export interface AnyAssetFileUploaderProps {
  showDescriptionField?: boolean;
  descriptionFieldLabel?: string;
  descriptionFieldPlaceholder?: string;
  open: boolean;
  onClose: () => void;
  onSave: (url: string, description?: string) => void;
  title?: string;
  ratio?: number;
}

export default function AnyAssetFileUploader({
  open,
  onClose,
  onSave,
  title,
  showDescriptionField,
  descriptionFieldLabel,
  descriptionFieldPlaceholder,
}: AnyAssetFileUploaderProps) {
  const [file, setFile] = React.useState<{
    name: string;
    size: number;
    blob: Blob;
  }>();
  const [invalidFile, setInvalidFile] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);
  const [uploadingFileName, setUploadingFileName] = React.useState("");
  const [description, setDescription] = React.useState<string>("");

  const onFilesLoad = React.useCallback((acceptedFiles: any[]) => {
    try {
      acceptedFiles.forEach((file) => {
        const fileName = file?.name || "";
        const reader = new FileReader();
        setUploadingFileName(fileName);
        reader.onload = (e) => {
          try {
            setInvalidFile(false);
            if (!reader.result) {
              setInvalidFile(true);
              return;
            }
            const blob = new Blob([reader.result], { type: file.type });
            setFile({
              name: fileName,
              size: file.size,
              blob,
            });
          } catch (error) {
            setInvalidFile(true);
          }
        };
        reader.readAsArrayBuffer(file);
      });
    } catch (error) {
      setInvalidFile(true);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onFilesLoad,
    multiple: false,
  });

  const uploadFile = async () => {
    if (!file?.blob) return;
    setUploading(true);
    const URL = await assetsService.uploadFile(file.blob, uploadingFileName);
    if (URL) {
      onSave(URL, description);
    }
    setUploading(false);
  };

  return (
    <Dialog open={open} maxWidth={false}>
      <Box
        p={2}
        pb={3}
        sx={{ background: "white", maxWidth: "100%", minWidth: "450px" }}
      >
        <Box pb={1} sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6">{title || "Upload File"}</Typography>
          <IconButton onClick={onClose} sx={{ marginTop: "-10px" }}>
            <CloseIcon />
          </IconButton>
        </Box>
        {!file ? (
          <Box>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Box
                sx={{
                  border: "dashed 2px #ddd",
                  borderRadius: "5px",
                  height: "300px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  opacity: "0.8",
                  background: "#f3f3f3",
                }}
              >
                Click here to select local file
              </Box>
            </div>
          </Box>
        ) : (
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                maxWidth: "500px",
              }}
            >
              <Paper
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flex: 1,
                  padding: "10px 20px",
                  border: "solid 1px #eee",
                }}
              >
                <Box mr={2}>
                  <FilePresentIcon sx={{ fontSize: "50px", opacity: "0.6" }} />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Box sx={{ mb: "5px" }}>{file.name}</Box>
                  <Box
                    sx={{ fontSize: "14px", opacity: "0.8" }}
                  >{`Size: ${file.size}`}</Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <IconButton onClick={() => setFile(undefined)}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Paper>
            </Box>
            {showDescriptionField && (
              <Box>
                <TextField
                  fullWidth
                  multiline
                  minRows={3}
                  label={descriptionFieldLabel || "Description"}
                  placeholder={descriptionFieldPlaceholder}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  sx={{ mt: 3 }}
                />
              </Box>
            )}
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <Button
                variant="outlined"
                size="small"
                sx={{ mr: 1 }}
                onClick={() => setFile(undefined)}
              >
                Cancel this file
              </Button>
              <ProgressButton
                disabled={uploading || !file}
                inProgress={uploading}
                onClick={() => uploadFile()}
                title="Upload"
              />
            </Box>
          </Box>
        )}
      </Box>
    </Dialog>
  );
}
