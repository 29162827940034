/* eslint-disable @typescript-eslint/no-explicit-any */
// import Box from "@mui/material/Box";
import * as React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import WhitePanel from "../../components/Common/WhitePanel";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SimpleSearchInput from "../../components/Common/SimpleSearchInput";
import { Button, IconButton, ListItemIcon, ListItemText, MenuItem, MenuList } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { ICompany } from "../../../types/company";
import companyService from "../../../service/company.service";
import CompanyFormDialog from "./CompanyFormDialog";
import { IUserShallow } from "../../../types/auth";
import GeneralPopoverWrapper from "../../components/Common/GeneralPopoverWrapper";

export default function Index() {
  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);
  const [companies, setCompanies] = React.useState<ICompany[]>([]);
  const [companiesProcessing, setCompaniesProcessing] = React.useState(false);
  const [openCompanyFormDialog, setOpenCompanyFormDialog] = React.useState(false);
  const [targetCompany, setTargetCompany] = React.useState<ICompany>();
  const [searchTerm, setSearchTerm] = React.useState("");

  const columns = [
    {
      field: "name",
      headerName: "Company Name",
      width: 150,
      editable: true,
    },
    {
      field: "address",
      headerName: "Address",
      width: 250,
      editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      sortable: false,
      width: 160,
    },
    {
      field: "phone",
      headerName: "Phone",
      sortable: false,
      width: 160,
    },
    {
      field: "actions",
      headerName: "Action",
      width: 80,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
      renderCell: (params: any) => {
        return (
          <Box>
            <GeneralPopoverWrapper
              closeOnClickContent
              triggerElement={
                <IconButton size="small">
                  <MoreVertIcon />
                </IconButton>
              }
              popoverContent={
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      setTargetCompany(params.row);
                      setOpenCompanyFormDialog(true);
                    }}
                  >
                    <ListItemIcon>
                      <EditIcon />
                    </ListItemIcon>
                    <ListItemText>Edit Company Info</ListItemText>
                  </MenuItem>
                </MenuList>
              }
            />
          </Box>
        );
      },
    },
  ];

  const getFilteredCompanies = () => {
    return companies.filter(
      (c) =>
        !searchTerm ||
        c.name?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
        c.email?.toLowerCase()?.includes(searchTerm.toLowerCase())
    );
  };

  const fetchCompanies = async () => {
    setCompaniesProcessing(true);
    const result = await companyService.fetchAllCompanies();
    if (result?.data) {
      setCompanies(
        result.data?.map((item: { company: ICompany; users: IUserShallow[] }) => {
          return { ...item.company, users: item.users };
        })
      );
    }
    setCompaniesProcessing(false);
  };

  React.useEffect(() => {
    fetchCompanies();
  }, []);
  return (
    <>
      <Box mb={1}>
        <Box sx={{ display: "flex", alignItems: "center", fontSize: "24px", fontWeight: "500", opacity: 0.8 }}>
          Manage Companies
          <Box sx={{ ml: 1, fontSize: "24px", fontWeight: "500", opacity: "0.6" }}>{`(${companies.length})`}</Box>
        </Box>
      </Box>
      <WhitePanel>
        <Box pb={2} sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
          <Box>
            <SimpleSearchInput
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              size="small"
              placeholder="Search Company"
            />
          </Box>

          {selectedIds?.length ? (
            <Button startIcon={<DeleteIcon />} disableElevation variant="contained" sx={{ ml: 2 }} color="error">
              Delete
            </Button>
          ) : null}
          <Box sx={{ flex: 1 }} />

          <Button
            onClick={() => {
              setTargetCompany(undefined);
              setOpenCompanyFormDialog(true);
            }}
            startIcon={<AddCircleOutlineIcon />}
            disableElevation
            variant="contained"
            sx={{ ml: 2 }}
          >
            Add New Company
          </Button>
        </Box>
        <DataGridPro
          getRowId={(row) => row._id as string}
          loading={companiesProcessing}
          autoHeight
          selectionModel={selectedIds}
          onSelectionModelChange={(changedIds: any) => {
            setSelectedIds(changedIds);
          }}
          rows={getFilteredCompanies()}
          columns={columns}
          checkboxSelection
          disableSelectionOnClick
        />
      </WhitePanel>
      {openCompanyFormDialog && (
        <CompanyFormDialog
          company={targetCompany}
          open
          onClose={() => setOpenCompanyFormDialog(false)}
          onNewCreated={(created: ICompany) => {
            setCompanies([created, ...companies]);
            setOpenCompanyFormDialog(false);
          }}
          onUpdated={(updatedCompany) => {
            const tempCompanies = [...companies];
            const targetIndex = tempCompanies.findIndex((b) => b._id == updatedCompany._id);
            if (targetIndex > -1) {
              tempCompanies.splice(targetIndex, 1, updatedCompany);
              setCompanies(tempCompanies);
              setOpenCompanyFormDialog(false);
            }
          }}
        />
      )}
    </>
  );
}
