import { ICow } from "./cow";
import { IDiet } from "./diet";
import { IPen } from "./pen";

export interface ICowPHRecord {
  cow?: ICow;
  ph?: number;
}

export enum WeatherType {
  Sunny = "Sunny",
  Rainy = "Rainy",
  Cloudy = "Cloudy",
  Overcast = "Overcast",
  Snowy = "Snowy",
  Windy = "Windy",
  Stormy = "Stormy",
}

export interface IStallFeed {
  stallNumber: number;
  cowId: string;
  fedAmount: number;
  refusedAmount: number;
  startTime: Date;
  endTime: Date;
  durationSeconds: number;
}
export interface IFeed {
  timestamp?: Date;
  diet?: string;
  fedAmount?: number;
  refusedAmount?: number;
  stallFeeds?: IStallFeed[];
}

export interface IPushUp {
  timestamp?: Date;
}

export interface IPastureFeed {
  totalDurationMinutes?: number;
  startTime?: Date;
  endTime?: Date;
}

export interface IPenIntakeRecord {
  _id?: string;
  company?: string;
  pen?: IPen;
  date?: string;
  diet?: IDiet;
  fedAmount?: number;
  refusedAmount?: number;
  cowAverageFedAmount?: number;
  cowAverageRefusedAmount?: number;
  feeds?: IFeed[];
  pushUps?: IPushUp[];
  dayInTemp?: number;
  nightInTemp?: number;
  dayOutTemp?: number;
  nightOutTemp?: number;
  dayWeatherType?: WeatherType;
  nightWeatherType?: WeatherType;
  weatherDescription?: string;
  cowPhRecords?: ICowPHRecord[];
  averagePh?: number;
  dmPercentage?: number;
  looseFeedAmount?: number;
  looseFeedDiet?: IDiet;
  looseFeedDmPercentage?: number;
  note?: string;
  pastureFeed?: IPastureFeed;
}

export interface IPenLooseFeedRecord {
  _id?: string;
  pen?: IPen;
  company?: string;
  startDate?: string;
  endDate?: string;
  diet?: IDiet;
  totalAmount?: number;
  dmPercentage?: number;
}
