/* eslint-disable @typescript-eslint/no-explicit-any */
import { IDiet } from "../types/diet";
import backendApi from "./backendApi";
import { showSnackbar } from "./snackbar.service";

const routePrefix = "diet";

export default {
  async fetchDiets(companyId?: string): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/all`, {
        companyId,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async addNewDiet(diet: IDiet, companyId?: string): Promise<any> {
    if (!diet) return;
    try {
      const result = await backendApi()?.post(`${routePrefix}/create`, {
        companyId,
        diet,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "New diet successfully created"
      );
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },

  async updateDiet(
    companyId: string,
    dietId: string,
    diet: IDiet
  ): Promise<any> {
    if (!dietId) return;
    try {
      const result = await backendApi()?.post(`${routePrefix}/update`, {
        companyId,
        dietId,
        diet,
      });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "Successfully updated"
      );
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async deleteDiet(id: string): Promise<any> {
    if (!id) return;
    try {
      const result = await backendApi()?.post(`${routePrefix}/delete`, { id });
      if (result?.status != 200) {
        showSnackbar(
          "danger",
          "Error",
          result?.data.message || "API error response"
        );
        return null;
      }
      showSnackbar(
        "success",
        "Success",
        result?.data.message || "Successfully deleted"
      );
      return result;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
};
