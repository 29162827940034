import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import MenuIcon from "@mui/icons-material/Menu";
import EditIcon from "@mui/icons-material/Edit";
import MenuItem from "@mui/material/MenuItem";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LogoutIcon from "@mui/icons-material/Logout";
import { styled } from "@mui/material/styles";
import MenuList from "@mui/material/MenuList";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { NotificationIconButton } from "./NotificationIconButton";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../contexts/useStorageContext";
import { actionTypes } from "../../../types/state";
import companyService from "../../../service/company.service";
import UserFormDialog from "../../pages/users/UserFormDialog";
import { IUser } from "../../../types/user";
import userService from "../../../service/user.service";
import { UserRoles } from "../../../types/auth";
import { ICompany } from "../../../types/company";

const Navbar = styled(AppBar, {
  shouldForwardProp: (prop: string) => prop !== "openSideMenu",
})<{
  openSideMenu?: boolean;
  drawerwidth?: number;
}>(({ theme, openSideMenu, drawerwidth }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // marginLeft: `-${drawerWidth}px`,
  marginLeft: "0px",
  width: "100%",
  ...(openSideMenu && {
    width: `calc(100% - ${drawerwidth}px)`,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    // marginLeft: 0,
    marginLeft: `${drawerwidth}px`,
  }),
}));

export default function NavBar({ drawerWidth }: { drawerWidth?: number }) {
  const { dispatch, state } = useAppContext();
  const navigation = useNavigate();
  const { openSideDrawer, loggedInUserInfo, myCompanyProfile, currentCompany } =
    state;
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [openUserFormDialog, setOpenUserFormDialog] = React.useState(false);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const fetchMyProfile = async () => {
    const result = await userService.getMyProfile();
    dispatch!({ type: actionTypes.setMyProfile, value: result });
  };

  const fetchMyCompanyProfile = async () => {
    if (!loggedInUserInfo?.company) return;
    const result = await companyService.getMyCompany();
    if (result.data?._id) {
      dispatch!({ type: actionTypes.setMyCompanyProfile, value: result.data });
    }
    const settingsResult = await companyService.getMyCompanySettings();
    if (settingsResult.data?._id) {
      dispatch!({
        type: actionTypes.setMyCompanySettings,
        value: settingsResult.data,
      });
    }
  };

  const determineCurrentCompany = () => {
    setTimeout(async () => {
      if (currentCompany) return;
      if (loggedInUserInfo?.role == UserRoles.superadmin) {
        const result = await companyService.fetchCompanyShallowList();
        const companyList = result?.data || [];
        if (!companyList.length) return;
        const tempCurrentCompany = myCompanyProfile
          ? companyList.find((c: ICompany) => c._id == myCompanyProfile._id) ||
            companyList[0]
          : companyList[0];
        dispatch!({
          type: actionTypes.setCurrentCompany,
          value: tempCurrentCompany || undefined,
        });
      } else if (loggedInUserInfo?.company) {
        const result = await companyService.getMyCompany();
        if (!result.data) return;
        dispatch!({
          type: actionTypes.setCurrentCompany,
          value: result.data || undefined,
        });
      }
    }, 100);
  };

  React.useEffect(() => {
    fetchMyProfile();
    fetchMyCompanyProfile();
    determineCurrentCompany();
  }, []);

  return (
    <Navbar
      openSideMenu={openSideDrawer}
      drawerwidth={drawerWidth == undefined ? 270 : drawerWidth}
      position="static"
      elevation={0}
      sx={{
        bgcolor: "navbar.default",
        borderBottom: "solid 1px #e5e5e5",
        flex: "unset",
      }}
    >
      <Box className="shadow-sm">
        <Box px={2} sx={{}}>
          <Toolbar disableGutters sx={{ minHeight: { xl: "72px" } }}>
            <IconButton
              onClick={() =>
                dispatch!({
                  type: actionTypes.setOpenSideDrawer,
                  value: !openSideDrawer,
                })
              }
              className="sm:mr-3"
            >
              <MenuIcon
                sx={{
                  color: "button.black",
                  fontSize: { xs: "30px", lg: "35px" },
                }}
              />
            </IconButton>

            <Box sx={{ ml: { xs: 1, md: 2 } }}>
              <img
                src="app_logo.svg"
                style={{ maxHeight: "30px", width: "auto", marginTop: "4px" }}
              />
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                alignItems: "center",
                display: { xs: "flex" },
              }}
            ></Box>
            <Box sx={{ flexGrow: 0 }}>
              <NotificationIconButton />
              <IconButton onClick={handleOpenUserMenu}>
                <PermIdentityIcon
                  sx={{
                    color: "button.black",
                    fontSize: { xs: "30px", lg: "35px" },
                  }}
                />
              </IconButton>
              <IconButton sx={{ display: { xs: "none", sm: "inline-flex" } }}>
                <HelpOutlineIcon
                  sx={{
                    color: "button.black",
                    fontSize: { xs: "30px", lg: "35px" },
                  }}
                />
              </IconButton>

              <Popover
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <Box sx={{ minWidth: 250 }}>
                  <Box p={2} sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        width: "50px",
                        height: "50px",
                        background: "#f3f3f3",
                        borderRadius: "100px",
                        backgroundImage: `url(${
                          loggedInUserInfo?.avatar || ""
                        })`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                      }}
                    />
                    <Box sx={{ flex: 1, ml: 1 }}>
                      <Typography variant="h6">
                        {loggedInUserInfo?.fullname}
                      </Typography>
                      <Typography variant="body2">
                        {loggedInUserInfo?.email}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider />
                  <Box>
                    <MenuList>
                      <MenuItem
                        onClick={() => {
                          setOpenUserFormDialog(true);
                        }}
                      >
                        <ListItemIcon>
                          <EditIcon />
                        </ListItemIcon>
                        <ListItemText>Edit Profile</ListItemText>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          dispatch!({
                            type: actionTypes.setLoggedInUserInfo,
                            value: undefined,
                          });
                          navigation("/");
                        }}
                      >
                        <ListItemIcon>
                          <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText>Log out</ListItemText>
                      </MenuItem>
                    </MenuList>
                  </Box>
                </Box>
              </Popover>
            </Box>
          </Toolbar>
        </Box>
      </Box>
      {openUserFormDialog && (
        <UserFormDialog
          open
          user={{ ...loggedInUserInfo, company: myCompanyProfile } as IUser}
          onClose={() => setOpenUserFormDialog(false)}
          onUpdated={(updatedUser) => {
            dispatch!({
              type: actionTypes.setLoggedInUserInfo,
              value: { ...updatedUser, company: updatedUser?.company?._id },
            });
            setOpenUserFormDialog(false);
          }}
        />
      )}
    </Navbar>
  );
}
